import {Select, Label} from '@trussworks/react-uswds';
import {RolesGetData} from '../../../../../common/types/roles-get-res';
import metadata_client_account from '../../../../lib/metadata-client-account.json';
import {capitalizeFirstLetter} from '../../../../lib/utils';

type HandleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => void;

interface Props {
  role_fk: string;
  handleChange: HandleDropdownChange;
  roles: RolesGetData;
}

export default function RoleDropdown({role_fk, handleChange, roles}: Props) {
  return (
    <Label htmlFor="role_fk" className="mobile-lg:grid-col-6">
      <span className="text-primary text-semibold">{metadata_client_account.role.title}</span>
      <Select id="role_fk" name="role_fk" value={role_fk} onChange={handleChange}>
        {roles.map((role) => {
          return (
            // Capitalize the first letter. Seems to be some bug with capitalizing in Firefox
            <option key={role.pk} value={role.pk}>
              {capitalizeFirstLetter(role.name)}
            </option>
          );
        })}
      </Select>
    </Label>
  );
}

export function handleTypedDropdownChange<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  const handleDropdownChange: HandleDropdownChange = (event) => {
    const {value} = event.target;
    setFormData((formData) => ({...formData, role_fk: value}));
  };
  return handleDropdownChange;
}
