import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from '../lib/success-false-error';
import {SessionLogGetResBody} from '../../common/types/session-log-get-res';
import {AuthLogGetResBody} from '../../common/types/auth-log-get-res';
import {SessionsDelResBody} from '../../common/types/sessions-del-res';

async function fetchSessionLog() {
  const res = await axios.get<SessionLogGetResBody | ErrorResBody>('/api/session-log');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQuerySessionLog() {
  return useQuery({
    queryKey: ['session-log'],
    queryFn: () => fetchSessionLog(),
    retry: false,
    throwOnError: true,
  });
}

export async function deleteSession(session_id: string) {
  const res = await axios.delete<SessionsDelResBody | ErrorResBody>(`/api/sessions/${session_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationDeleteSession() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSession,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['session-log'], type: 'all'});
    },
  });
}

export type DateTimeFilter = {
  start?: string;
  end?: string;
};

async function fetchAuthLog(filters: DateTimeFilter) {
  const search_params = new URLSearchParams();
  for (const [key, value] of Object.entries(filters)) {
    if (value) search_params.set(key, value);
  }
  const search = search_params.toString();
  const res = await axios.get<AuthLogGetResBody | ErrorResBody>(
    '/api/auth-log' + (search ? `?${search}` : '')
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryAuthLog({start, end}: DateTimeFilter) {
  return useQuery({
    queryKey: ['auth-log', start, end],
    queryFn: () => fetchAuthLog({start, end}),
    retry: false,
    throwOnError: true,
    refetchOnWindowFocus: false,
  });
}
