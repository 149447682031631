import {RemirrorJSON} from 'remirror';
import RemirrorErrorBoundary from '../RemirrorErrorBoundary/RemirrorErrorBoundary';
import {
  Doc,
  Heading,
  RemirrorRenderer,
  TextHandler,
  createLinkHandler,
  MarkMap,
} from '@remirror/react';
import {FC} from 'react';
import {checkIfRemirrorStringAndGetJson} from '../../lib/remirror-utils';

type ColorHandler = FC<{
  // Colors supported by USWDS' basic palette: https://designsystem.digital.gov/utilities/color/#basic-palette
  color:
    | 'black'
    | 'white'
    | 'red'
    | 'orange'
    | 'gold'
    | 'yellow'
    | 'green'
    | 'mint'
    | 'cyan'
    | 'blue'
    | 'indigo'
    | 'violet'
    | 'magenta';
  children: React.ReactElement<HTMLElement>;
}>;

const colorHandler: ColorHandler = ({color, children}) => {
  return <span className={`text-${color}`}>{children}</span>;
};

// Because we are offering RTF formatting options not covered by Remirror Renderer's default custom renderer:
//  https://github.com/remirror/remirror/blob/v1.0.8/packages/remirror__react-renderer/src/renderer.tsx#L24C1-L46C2
// we must create our own type & mark map to pass in to the component

// Added properties from default:
// listItem
// Removed properties from default:
// callout, iframe, image, codeblock
const customTypeMap: MarkMap = {
  blockquote: 'blockquote',
  bulletList: 'ul',
  doc: Doc,
  heading: Heading,
  paragraph: 'p',
  horizontalRule: 'hr',
  hardBreak: 'br',
  orderedList: 'ol',
  text: TextHandler,
  listItem: 'li',
};

// Added properties from default:
// textColor
const customMarkMap: MarkMap = {
  italic: 'em',
  bold: 'strong',
  link: createLinkHandler(),
  underline: 'u',
  textColor: colorHandler,
};

interface Props {
  json: RemirrorJSON;
}

export function RemirrorRendererComponent({json}: Props) {
  return (
    <RemirrorRenderer
      json={json}
      typeMap={customTypeMap}
      markMap={customMarkMap}
      skipUnknownMarks
      skipUnknownTypes
    />
  );
}

interface WrapperProps {
  title: string;
  initial_query_data: string;
}

export function RemirrorRendererComponentWrapper({title, initial_query_data}: WrapperProps) {
  const remirror_json = checkIfRemirrorStringAndGetJson(initial_query_data);
  return (
    <>
      {remirror_json ? (
        <RemirrorErrorBoundary field_name={title}>
          <RemirrorRendererComponent json={remirror_json} />
        </RemirrorErrorBoundary>
      ) : (
        <p>{initial_query_data}</p>
      )}
    </>
  );
}
