import {Alert, DatePicker, TimePicker} from '@trussworks/react-uswds';
import {useEffect, useState} from 'react';
import {SetValidationMessages} from '../../../types/form-types';
import {
  DefaultDatetimeValue,
  addOrRemoveValidationMessage,
  getDefaultDatetime,
  parseFormDatetime,
  slashToDashDate,
  time_note,
} from '../../../lib/utils';
import InlineAlert from '../InlineAlert/InlineAlert';
import SectionDescription from '../SectionDescription/SectionDescription';

// Usage notes:
// - form_date format: MM/dd/yyyy
// - form_time format: HH:mm
// - iso_date format: yyyy-MM-dd

const reset_form_time = '00:00';

interface Props {
  id_name: string;
  metadata: {
    title: string;
    validation_message: string;
  };
  default_value?: DefaultDatetimeValue;
  handleChange: (id_name: string, value: string | null) => void;
  setValidationMessages: SetValidationMessages;
  children?: React.ReactNode;
  alert_msg?: string;
  is_required?: boolean;
}

export default function FormDatetimeInput({
  id_name,
  metadata,
  default_value,
  handleChange,
  setValidationMessages,
  children,
  alert_msg,
  is_required,
}: Props) {
  const default_datetime = getDefaultDatetime(default_value, reset_form_time);
  const [parsedDatetime, setParsedDatetime] = useState(default_datetime);
  const [unvalidatedDatetime, setUnvalidatedDatetime] = useState({
    form_date: default_datetime.form_date,
    form_time: default_datetime.form_time,
  });
  const [isValid, setIsValid] = useState(true);

  // Validate that datetime exists if required and is valid if exists
  useEffect(() => {
    const is_user_input_valid =
      (!unvalidatedDatetime.form_date ||
        parsedDatetime.form_date == unvalidatedDatetime.form_date) &&
      (!unvalidatedDatetime.form_time || parsedDatetime.form_time == unvalidatedDatetime.form_time);
    const is_valid = (!is_required || !!parsedDatetime.iso_datetime) && is_user_input_valid;
    addOrRemoveValidationMessage({
      is_condition_met: !is_valid,
      err_message: metadata.validation_message,
      setValidationMessages,
    });
    setIsValid(is_valid);
  }, [metadata, parsedDatetime, unvalidatedDatetime, is_required, setValidationMessages]);

  const handleChangeDate = (value: string | undefined) => {
    const form_date = value ?? '';
    // Reset time if date is cleared
    const form_time = value ? parsedDatetime.form_time : reset_form_time;
    const new_parsed_state = parseFormDatetime(form_date, form_time);
    setUnvalidatedDatetime({form_date, form_time});
    setParsedDatetime(new_parsed_state);
    handleChange(id_name, new_parsed_state.iso_datetime);
  };

  const handleChangeTime = (value: string | undefined) => {
    const form_date = parsedDatetime.form_date;
    const form_time = value ?? '';
    const new_parsed_state = parseFormDatetime(form_date, form_time);
    setUnvalidatedDatetime({form_date, form_time});
    setParsedDatetime(new_parsed_state);
    handleChange(id_name, new_parsed_state.iso_datetime);
  };

  // DatePicker expects ISO format for the default value but returns MM/dd/yyyy format from the onChange handler.
  // Note that default_datetime.iso_datetime is UTC, so it's not appropriate to use the ISO date from that string.
  const default_iso_date =
    (default_datetime.form_date && slashToDashDate(default_datetime.form_date)) || undefined;

  return (
    <section className="margin-top-3">
      <div className="grid-col margin-top-1" data-testid="dt-datetime-container">
        <p className="margin-0 text-primary text-semibold">
          {metadata.title}
          {is_required && ' *'}
        </p>
        <SectionDescription>{time_note}</SectionDescription>
        {!!alert_msg && (
          <Alert headingLevel="h2" type="warning" slim noIcon className="margin-y-1">
            {alert_msg}
          </Alert>
        )}
        {children}
        {!isValid && <InlineAlert>{metadata.validation_message}</InlineAlert>}
        <div className="grid-row flex-column desktop:flex-row">
          <div>
            <DatePicker
              id={`${id_name}_date`}
              name={`${id_name}_date`}
              onChange={handleChangeDate}
              defaultValue={default_iso_date}
              required={is_required}
            />
            <SectionDescription>(MM/DD/YYYY)</SectionDescription>
          </div>
          {!!parsedDatetime.form_date && (
            <div className="grid-row flex-align-center flex-no-wrap">
              <div className="margin-top-neg-2 desktop:margin-top-neg-5">
                <TimePicker
                  id={`${id_name}_time`}
                  name={`${id_name}_time`}
                  label=""
                  step={1}
                  onChange={handleChangeTime}
                  defaultValue={default_datetime.form_time}
                />
              </div>
              <p className="margin-0 margin-left-1 desktop:margin-top-neg-2">Eastern time</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
