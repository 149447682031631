import Spinner from '../Spinner/Spinner';

interface Props {
  message?: string;
}

export default function PageLoading({message = 'Loading'}: Props) {
  return message ? (
    <div className="grid-row grid-gap-2 margin-top-2">
      <span className="grid-col text-right">
        <Spinner />
      </span>
      <span className="grid-col display-flex flex-column">
        <span className="margin-y-auto font-sans-md">{message}</span>
      </span>
    </div>
  ) : (
    <div className="width-100 text-center margin-top-2">
      <Spinner />
    </div>
  );
}
