interface Props {
  children: React.ReactNode;
}
export default function PrevInfoLayout({children}: Props) {
  return (
    <div className="margin-y-105 padding-y-1 padding-x-2 border border-dashed text-base">
      {children}
    </div>
  );
}
