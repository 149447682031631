import {Suspense, lazy} from 'react';
import {RemirrorEditorProps} from './RemirrorEditor';

const RemirrorEditor = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "remirror-editor" */
      './RemirrorEditor'
    )
);

export default function RemirrorEditorLazy(props: RemirrorEditorProps) {
  return (
    <Suspense fallback={<div>Loading editor...</div>}>
      <RemirrorEditor {...props} />
    </Suspense>
  );
}
