import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import CreateEditMeeting from '../CreateEditMeeting';
import {Link} from 'react-router-dom';
import {useQueryMtgFileCategories, useQueryStatuses} from '../../../services/meeting-queries';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';

export default function CreateMeetingWrapper() {
  const statuses = useQueryStatuses();
  const file_categories = useQueryMtgFileCategories();

  // This shouldn't render since we're using err boundary in both queries
  if (statuses.isError || file_categories.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Create new meeting</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {statuses.isPending || file_categories.isPending ? (
        <PageLoading />
      ) : (
        <CreateEditMeeting statuses_data={statuses.data} file_categories={file_categories.data} />
      )}
    </>
  );
}
