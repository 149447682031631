import {useEffect, useState} from 'react';
import {Label, Textarea} from '@trussworks/react-uswds';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import metadata_client_broadcast from '../../../lib/metadata-client-broadcast.json';
import metadata_client_meeting from '../../../lib/metadata-client-meeting.json';
import metadata_client_registration from '../../../lib/metadata-client-registration.json';
import {
  FormType,
  HandleRtfInputChange,
  MetadataValue,
  SemiUncontrolled,
  SetValidationMessages,
} from '../../../types/form-types';
import styles from './FormTextArea.module.scss';
import {
  addOrRemoveValidationMessage,
  convertCharsToKB,
  getLengthValidationMsg,
} from '../../../lib/utils';
import RemirrorEditorLazy from '../RemirrorEditor/RemirrorEditorLazy';
import {useDebouncedCallback} from 'use-debounce';
import SectionDescription from '../SectionDescription/SectionDescription';
import SectionTitle from '../SectionTitle/SectionTitle';

export type HandleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

type Props = {
  id_name: string;
  input: string;
  setValidationMessages: SetValidationMessages;
  custom_jsx?: React.ReactNode;
  is_required?: boolean;
  is_disabled?: boolean;
  is_validate_length?: boolean;
  is_invalid?: boolean;
  include_desc?: boolean;
  include_hint?: boolean;
  form_type: FormType;
} & (
  | {
      handleChange: HandleRtfInputChange;
      is_rtf: true;
      use_semi_uncontrolled?: never; // RTF is mutually exclusive from the default form
      debounce_change_ms?: never;
    }
  | ({
      handleChange: HandleTextAreaChange;
      is_rtf?: never;
    } & SemiUncontrolled)
);

/**
 * @param use_semi_uncontrolled Use the form component as a hybrid between controlled and uncontrolled form.
 * In semi-uncontrolled mode, the default value is initially set by React,
 * and though the displayed input is controlled by the DOM, the change is still tracked in React's state via onChange.
 */
export default function FormTextArea({
  id_name,
  input,
  handleChange,
  setValidationMessages,
  custom_jsx,
  is_required,
  is_disabled,
  is_validate_length,
  is_invalid,
  include_desc,
  include_hint,
  form_type,
  is_rtf,
  use_semi_uncontrolled,
  debounce_change_ms,
}: Props) {
  const [isValid, setIsValid] = useState(true);
  // Tip: if debounce_change_ms is falsy, then this callback doesn't get used
  const handleChangeDebounced = useDebouncedCallback(handleChange, debounce_change_ms);

  let metadata_obj: MetadataValue;
  if (form_type == 'meeting') {
    metadata_obj = metadata_client_meeting[id_name as keyof typeof metadata_client_meeting];
  } else if (form_type == 'account') {
    metadata_obj = metadata_client_account[id_name as keyof typeof metadata_client_account];
  } else if (form_type == 'broadcast') {
    metadata_obj = metadata_client_broadcast[id_name as keyof typeof metadata_client_broadcast];
  } else {
    metadata_obj =
      metadata_client_registration[id_name as keyof typeof metadata_client_registration];
  }

  let _validation_message!: string;
  if (is_required) {
    if (!('validation_message' in metadata_obj)) {
      throw new Error(
        `Please add "validation_message" in metadata-client-account.json for required properties in ${id_name}`
      );
    } else {
      _validation_message = metadata_obj.validation_message;
    }
  }

  let _max_length!: number;
  if (is_validate_length) {
    if (!('max_length' in metadata_obj)) {
      throw new Error(
        `Please add "max_length" in metadata-client-account.json for required properties in ${id_name}`
      );
    } else {
      _max_length = metadata_obj.max_length;
    }
  }

  let description!: string;
  if (include_desc) {
    if (!('description' in metadata_obj)) {
      throw new Error(
        `Please add "description" in metadata-client for required properties in ${id_name}`
      );
    } else {
      description = metadata_obj.description;
    }
  }

  let hint!: string;
  if (include_hint) {
    if (!('hint' in metadata_obj)) {
      throw new Error(`Please add "hint" in metadata-client for required properties in ${id_name}`);
    } else {
      hint = metadata_obj.hint;
    }
  }

  useEffect(() => {
    let _is_valid = !is_invalid;

    if (is_required) {
      const does_input_exist = !!input;
      _is_valid = does_input_exist;
      addOrRemoveValidationMessage({
        is_condition_met: !does_input_exist,
        err_message: _validation_message,
        setValidationMessages,
      });
    }

    if (is_validate_length) {
      const is_too_long = input.length > _max_length;
      _is_valid = _is_valid && !is_too_long;
      addOrRemoveValidationMessage({
        is_condition_met: is_too_long,
        err_message: getLengthValidationMsg(metadata_obj.title, _max_length, is_rtf),
        setValidationMessages,
      });
    }

    setIsValid(_is_valid);
  }, [
    _max_length,
    _validation_message,
    input,
    is_invalid,
    is_required,
    is_rtf,
    is_validate_length,
    metadata_obj.title,
    setValidationMessages,
  ]);

  const control_props =
    !is_rtf &&
    (use_semi_uncontrolled
      ? {defaultValue: input, onChange: debounce_change_ms ? handleChangeDebounced : handleChange}
      : {value: input, onChange: handleChange});

  let max_kb!: number;
  let remaining_kb!: number;
  if (is_rtf) {
    max_kb = convertCharsToKB(_max_length);
    const used_kb = convertCharsToKB(input.length);

    if (max_kb > used_kb) remaining_kb = parseFloat((max_kb - used_kb).toFixed(3));
    else remaining_kb = 0;
  }

  return (
    <>
      {is_rtf ? (
        // Remirror uses a contentEditable `div`, and `div`s aren't able to be labelled using an HTML component
        <section className="margin-top-3">
          <SectionTitle>
            {metadata_obj.title}
            {is_required && ' *'}
          </SectionTitle>
        </section>
      ) : (
        <Label htmlFor={id_name} className="maxw-none width-full">
          <span className="text-primary text-semibold">
            {metadata_obj.title}
            {is_required && ' *'}
          </span>
        </Label>
      )}
      {include_desc && <SectionDescription>{description}</SectionDescription>}
      {custom_jsx}
      {is_rtf ? (
        <>
          <RemirrorEditorLazy
            title={metadata_obj.title}
            id={id_name}
            initial_query_value={input}
            handleChange={handleChange}
            is_valid={isValid}
          />
          <p
            className={`${
              isValid ? 'text-base' : 'text-red'
            } text-italic font-sans-xs margin-bottom-2px margin-top-0`}
          >
            {remaining_kb}/{max_kb} KB remaining (number of characters and use of rich text affect
            this)
          </p>
        </>
      ) : (
        <Textarea
          id={id_name}
          name={id_name}
          {...control_props}
          className={styles.text_area}
          disabled={is_disabled}
          required={is_required}
          error={!isValid}
        />
      )}
      {hint && <SectionDescription>({hint})</SectionDescription>}
    </>
  );
}

export function handleTypedTextAreaChange<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  const handleTextAreaChange: HandleTextAreaChange = (event) => {
    const {name, value} = event.target;
    setFormData((formData) => ({...formData, [name]: value}));
  };
  return handleTextAreaChange;
}
