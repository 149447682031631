import filenamify from '../lib/filenamify';

const supported_file_extensions = [
  '.doc',
  '.docx',
  '.mp4',
  '.pdf',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
] as const;

export type SupportedFileExtension = (typeof supported_file_extensions)[number];

export function isSupportedFileExtension(ext: unknown): ext is SupportedFileExtension {
  return typeof ext == 'string' && (supported_file_extensions as readonly string[]).includes(ext);
}

// https://en.wikipedia.org/wiki/List_of_file_signatures
export type HeaderSignatureDict = {
  [key in SupportedFileExtension]: {offset: number; signature: Uint8Array};
};
export const header_signature_dict: HeaderSignatureDict = {
  '.doc': {offset: 0, signature: new Uint8Array([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1])},
  '.docx': {offset: 0, signature: new Uint8Array([0x50, 0x4b, 0x03, 0x04])},
  '.mp4': {offset: 4, signature: new Uint8Array([0x66, 0x74, 0x79, 0x70])},
  '.pdf': {offset: 0, signature: new Uint8Array([0x25, 0x50, 0x44, 0x46, 0x2d])},
  '.ppt': {offset: 0, signature: new Uint8Array([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1])},
  '.pptx': {offset: 0, signature: new Uint8Array([0x50, 0x4b, 0x03, 0x04])},
  '.xls': {offset: 0, signature: new Uint8Array([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1])},
  '.xlsx': {offset: 0, signature: new Uint8Array([0x50, 0x4b, 0x03, 0x04])},
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export type MimeTypeDict = {[key in SupportedFileExtension]: string};
export const mime_type_dict: MimeTypeDict = {
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.mp4': 'video/mp4',
  '.pdf': 'application/pdf',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const allowed_mtg_file_extensions: SupportedFileExtension[] = [
  '.doc',
  '.docx',
  '.mp4',
  '.pdf',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
];

export const max_mtg_file_bytes = 1073741824; // 1 GiB

type SanitizationSuccess = {
  file_name: string;
  file_ext: SupportedFileExtension;
  error_code?: never;
};
type SanitizationError = {
  file_name?: never;
  file_ext?: never;
  error_code: 'no_valid_chars' | 'name_or_ext_missing' | 'unsupported_ext';
};

export function sanitizeMeetingFileName(
  file_name: string
): SanitizationSuccess | SanitizationError {
  const safe_file_name = filenamify(file_name, {replacement: '_', maxLength: 100});
  if (!safe_file_name) {
    return {error_code: 'no_valid_chars'};
  }

  if (!/.+\..+/.test(safe_file_name)) {
    return {error_code: 'name_or_ext_missing'};
  }

  const extension = safe_file_name.slice(safe_file_name.lastIndexOf('.'));
  if (!(allowed_mtg_file_extensions as string[]).includes(extension.toLowerCase())) {
    return {
      error_code: 'unsupported_ext',
    };
  }

  return {
    file_name: safe_file_name,
    file_ext: extension.toLowerCase() as SupportedFileExtension,
  };
}
