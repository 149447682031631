import {Button} from '@trussworks/react-uswds';
import {EmailsGetDataEmail} from '../../../../common/types/emails-get-res';
import {EmailReattemptPostData} from '../../../../common/types/email-reattempt-post-res';
import {UseMutationResult} from '@tanstack/react-query';
import {scrollToTop} from '../../../lib/utils';
import {Link} from 'react-router-dom';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';

interface Props {
  pk: string;
  child_email_fk: EmailsGetDataEmail['child_email_fk'];
  // Passing mutation instead of mutation.mutate causes extra unnecessary renders
  // However, this component needs mutation.isPending and mutation.variables
  // At that point, might as well pass in mutation as a whole
  mutation: UseMutationResult<EmailReattemptPostData, unknown, string>;
}

export default function ReattemptButton({pk, child_email_fk, mutation}: Props) {
  // If child_email_fk exists, this email has been reattempted
  const has_reattempted = !!child_email_fk;
  const is_curr_btn_loading = mutation.isPending && mutation.variables == pk;

  function handleReattempt(pk: string) {
    mutation.mutate(pk, {
      onSuccess: () => scrollToTop(),
    });
  }

  return (
    <>
      {has_reattempted ? (
        <Link
          to={`/email-queue/${child_email_fk}`}
          className="usa-button usa-button--outline margin-right-0 text-no-wrap"
        >
          View reattempt
        </Link>
      ) : (
        <Button
          id={`reattempt-email-btn-${pk}`}
          type="button"
          className="usa-button usa-button--outline margin-0"
          onClick={() => handleReattempt(pk)}
          disabled={is_curr_btn_loading}
        >
          {is_curr_btn_loading ? <TextLoading /> : 'Reattempt'}
        </Button>
      )}
    </>
  );
}
