import {Alert} from '@trussworks/react-uswds';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  cta?: ReactNode;
};

export default function SuccessAlert({children, cta}: Props) {
  return (
    <Alert type="success" headingLevel="h2" className="margin-bottom-2" slim cta={cta}>
      {children}
    </Alert>
  );
}
