import {Alert, DatePicker, TimePicker} from '@trussworks/react-uswds';
import {useCallback, useEffect, useState} from 'react';
import {SetValidationMessages} from '../../../types/form-types';
import {
  DefaultDatetimeValue,
  ParsedDateTime,
  addOrRemoveValidationMessage,
  end_time_after_start_validation,
  getDefaultDatetime,
  parseFormDatetime,
  slashToDashDate,
  time_note,
} from '../../../lib/utils';
import InlineAlert from '../InlineAlert/InlineAlert';
import SectionDescription from '../SectionDescription/SectionDescription';
import SectionTitle from '../SectionTitle/SectionTitle';

// Usage notes:
// - form_date format: MM/dd/yyyy
// - form_time format: HH:mm
// - iso_date format: yyyy-MM-dd

const reset_form_time = '00:00';

interface Metadata {
  title: string;
  validation_message: string;
}

interface UnvalidatedDatetime {
  form_date: string;
  form_time: string;
}

interface Props {
  id_name_1: string;
  id_name_2: string;
  metadata_1: Metadata;
  metadata_2: Metadata;
  default_value_1?: DefaultDatetimeValue;
  default_value_2?: DefaultDatetimeValue;
  handleChange: (id_name: string, value: string | null) => void;
  setValidationMessages: SetValidationMessages;
  range_title: string;
  children?: React.ReactNode;
  enforce_order?: boolean;
  alert_msg?: string;
  is_required?: boolean;
}

export default function FormDatetimeRange({
  id_name_1,
  id_name_2,
  metadata_1,
  metadata_2,
  default_value_1,
  default_value_2,
  handleChange,
  setValidationMessages,
  range_title,
  children,
  enforce_order,
  alert_msg,
  is_required,
}: Props) {
  const default_datetimes = [
    getDefaultDatetime(default_value_1, reset_form_time),
    getDefaultDatetime(default_value_2, reset_form_time),
  ];
  const [parsedDatetimes, setParsedDatetimes] = useState(default_datetimes);
  const [unvalidatedDatetimes, setUnvalidatedDatetimes] = useState<UnvalidatedDatetime[]>(
    default_datetimes.map((default_datetime) => ({
      form_date: default_datetime.form_date,
      form_time: default_datetime.form_time,
    }))
  );
  const [isValids, setIsValids] = useState([true, true]);
  // When `enforce_order == true`, this state is the result of test: datetime2 > datetime1
  const [isOrderValid, setIsOrderValid] = useState(true);

  const datasets = [0, 1].map((idx) => ({
    id_name: [id_name_1, id_name_2][idx],
    metadata: [metadata_1, metadata_2][idx],
    default_datetime: default_datetimes[idx],
    parsed_datetime: parsedDatetimes[idx],
    unvalidated_datetime: unvalidatedDatetimes[idx],
    is_valid: isValids[idx],
  }));

  const validationEffect = useCallback(
    (
      metadata: Metadata,
      parsedDatetime: ParsedDateTime,
      unvalidatedDatetime: UnvalidatedDatetime,
      updateValidation: (is_valid: boolean) => void
    ) => {
      const is_user_input_valid =
        (!unvalidatedDatetime.form_date ||
          parsedDatetime.form_date == unvalidatedDatetime.form_date) &&
        (!unvalidatedDatetime.form_time ||
          parsedDatetime.form_time == unvalidatedDatetime.form_time);
      const is_valid = (!is_required || !!parsedDatetime.iso_datetime) && is_user_input_valid;
      addOrRemoveValidationMessage({
        is_condition_met: !is_valid,
        err_message: metadata.validation_message,
        setValidationMessages,
      });
      updateValidation(is_valid);
    },
    [is_required, setValidationMessages]
  );

  const updateValidation = useCallback(
    (idx: number) => (is_valid: boolean) => {
      setIsValids((prev_arr) => {
        const new_arr = [...prev_arr];
        new_arr[idx] = is_valid;
        return new_arr;
      });
    },
    []
  );

  // Validate each datetime exists if required and is valid if exists
  {
    const {metadata, parsed_datetime, unvalidated_datetime} = datasets[0];
    useEffect(
      () => validationEffect(metadata, parsed_datetime, unvalidated_datetime, updateValidation(0)),
      [metadata, parsed_datetime, unvalidated_datetime, validationEffect, updateValidation]
    );
  }
  {
    const {metadata, parsed_datetime, unvalidated_datetime} = datasets[1];
    useEffect(
      () => validationEffect(metadata, parsed_datetime, unvalidated_datetime, updateValidation(1)),
      [metadata, parsed_datetime, unvalidated_datetime, validationEffect, updateValidation]
    );
  }

  // Validate that times are in order if enforced
  useEffect(() => {
    if (!enforce_order) return;

    const iso_datetime_1 = parsedDatetimes[0].iso_datetime;
    const iso_datetime_2 = parsedDatetimes[1].iso_datetime;
    const is_valid =
      !iso_datetime_1 || !iso_datetime_2 || new Date(iso_datetime_2) > new Date(iso_datetime_1);
    addOrRemoveValidationMessage({
      is_condition_met: !is_valid,
      err_message: end_time_after_start_validation,
      setValidationMessages,
    });
    setIsOrderValid(is_valid);
  }, [enforce_order, parsedDatetimes, setValidationMessages]);

  const handleChangeDate = (idx: number) => (value: string | undefined) => {
    const form_date = value ?? '';
    // Reset time if date is cleared
    const form_time = value ? parsedDatetimes[idx].form_time : reset_form_time;
    setUnvalidatedDatetimes((prev_arr) => {
      const new_arr = [...prev_arr];
      new_arr[idx] = {form_date, form_time};
      return new_arr;
    });
    const new_parsed_state = parseFormDatetime(form_date, form_time);
    setParsedDatetimes((prev_arr) => {
      const new_arr = [...prev_arr];
      new_arr[idx] = new_parsed_state;
      return new_arr;
    });
    handleChange(datasets[idx].id_name, new_parsed_state.iso_datetime);
  };

  const handleChangeTime = (idx: number) => (value: string | undefined) => {
    const form_date = parsedDatetimes[idx].form_date;
    const form_time = value ?? '';
    setUnvalidatedDatetimes((prev_arr) => {
      const new_arr = [...prev_arr];
      new_arr[idx] = {form_date, form_time};
      return new_arr;
    });
    const new_parsed_state = parseFormDatetime(form_date, form_time);
    setParsedDatetimes((prev_arr) => {
      const new_arr = [...prev_arr];
      new_arr[idx] = new_parsed_state;
      return new_arr;
    });
    handleChange(datasets[idx].id_name, new_parsed_state.iso_datetime);
  };

  return (
    <section className="margin-top-3">
      <SectionTitle>
        {range_title}
        {is_required && ' *'}
      </SectionTitle>
      <SectionDescription>{time_note}</SectionDescription>
      {!isOrderValid && (
        <Alert headingLevel="h2" type="warning" slim noIcon className="margin-top-1">
          {end_time_after_start_validation}
        </Alert>
      )}
      {!!alert_msg && (
        <Alert headingLevel="h2" type="warning" slim noIcon className="margin-top-1">
          {alert_msg}
        </Alert>
      )}
      {children}
      {datasets.map((dataset, idx) => {
        const {id_name, metadata, default_datetime, parsed_datetime, is_valid} = dataset;
        // DatePicker expects ISO format for the default value but returns MM/dd/yyyy format from the onChange handler.
        // Note that default_datetime.iso_datetime is UTC, so it's not appropriate to use the ISO date from that string.
        const default_iso_date =
          (default_datetime.form_date && slashToDashDate(default_datetime.form_date)) || undefined;

        return (
          <div
            className={`grid-col ${idx == 1 ? 'margin-top-2' : 'margin-top-1'}`}
            key={id_name}
            data-testid="dt-datetime-container"
          >
            <p className="margin-0">{metadata.title}</p>
            {!is_valid && <InlineAlert>{metadata.validation_message}</InlineAlert>}
            <div className="grid-row flex-column desktop:flex-row">
              <div>
                <DatePicker
                  id={`${id_name}_date`}
                  name={`${id_name}_date`}
                  onChange={handleChangeDate(idx)}
                  defaultValue={default_iso_date}
                  required={is_required}
                />
                <SectionDescription>(MM/DD/YYYY)</SectionDescription>
              </div>
              {!!parsed_datetime.form_date && (
                <div className="grid-row flex-align-center flex-no-wrap">
                  <div className="margin-top-neg-2 desktop:margin-top-neg-5">
                    <TimePicker
                      id={`${id_name}_time`}
                      name={`${id_name}_time`}
                      label=""
                      step={1}
                      onChange={handleChangeTime(idx)}
                      defaultValue={default_datetime.form_time}
                    />
                  </div>
                  <p className="margin-0 margin-left-1 desktop:margin-top-neg-2">Eastern time</p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
}
