import {Link} from 'react-router-dom';
import {EmailsGetByIdData} from '../../../common/types/emails-get-by-id-res';
import EmailPills from '../../components/FormComponents/MeetingFormComponents/EmailsInput/EmailPills/EmailPills';
import DisplayBox from '../../components/MeetingComponents/DisplayBox/DisplayBox';
import styles from './ViewEmail.module.scss';
import {Alert, Tag} from '@trussworks/react-uswds';
import {
  email_reattempt_err,
  email_reattempt_success,
  email_status_background_dict,
  email_type_dict,
  fromUTCtoET,
} from '../../lib/utils';
import TagAlt from '../../components/TagAlt/TagAlt';
import {useMutationReattemptEmail} from '../../services/email-queries';
import ReattemptButton from '../../components/MeetingComponents/ReattemptButton/ReattemptButton';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

interface Props {
  pk: string;
  query_data: EmailsGetByIdData;
}

const email_fields_titles = {
  from_email: 'From',
  to_emails: 'To',
  reply_to_email: 'Reply to',
  cc_emails: 'CC emails',
  bcc_emails: 'BCC emails',
  accepted_emails: 'Accepted emails',
  rejected_emails: 'Rejected emails',
};

export default function ViewEmail({pk, query_data}: Props) {
  const mutation = useMutationReattemptEmail();

  return (
    <section>
      {mutation.isSuccess && (
        <SuccessAlert>
          <span>
            {email_reattempt_success} Please{' '}
            <Link
              className="usa-button usa-button--unstyled"
              to={`/email-queue/${mutation.data.child_email_fk}`}
            >
              see details
            </Link>{' '}
            to to verify if the reattempted email&apos;s status is SENT or UNSENT.
          </span>
        </SuccessAlert>
      )}
      {mutation.isError && (
        <FormGenericError message={email_reattempt_err} error={mutation.error} />
      )}
      <Tag
        className={`${email_status_background_dict[query_data.status]} text-semibold font-sans-2xs`}
      >
        {query_data.status}
      </Tag>
      <div className="display-inline-block margin-left-1">
        <TagAlt
          display_value={email_type_dict[query_data.type].name}
          color={email_type_dict[query_data.type].tag_color}
        />
      </div>
      {!!query_data.parent_email_fk && (
        <Alert type="info" headingLevel="h2" className="margin-top-205" noIcon>
          This email was reattempted from another email.{' '}
          <Link to={`/email-queue/${query_data.parent_email_fk}`}>See details</Link>
        </Alert>
      )}
      <table
        className={`usa-table usa-table--stacked-header bg-white width-full margin-bottom-4 ${styles.table}`}
      >
        <tbody>
          {Object.entries(email_fields_titles).map((field_info, idx) => {
            const field_key = field_info[0] as keyof typeof email_fields_titles;
            const field_val = query_data[field_key];
            return (
              <tr key={idx}>
                <td>
                  <span className="text-semibold">{field_info[1]}</span>
                </td>
                {field_val == null || !field_val.length ? (
                  <td className="text-base text-italic">N/A</td>
                ) : (
                  <td className="table_email_cell">
                    <EmailPills emails={Array.isArray(field_val) ? field_val : [field_val]} />
                  </td>
                )}
              </tr>
            );
          })}
          {query_data.type == 'mtg_broadcast' && (
            <tr>
              <td>
                <span className="text-semibold">Meeting</span>
              </td>
              <td>
                {query_data.mtg ? (
                  <Link to={`/meetings/${query_data.mtg.pk}`}>{query_data.mtg.title}</Link>
                ) : (
                  <>Not available</>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td>
              <span className="text-semibold">Reattempt?</span>
            </td>
            {query_data.status == 'unsent' ? (
              <td>
                <ReattemptButton
                  pk={pk}
                  child_email_fk={query_data.child_email_fk}
                  mutation={mutation}
                />
              </td>
            ) : (
              <td className="text-base text-italic">
                N/A. Only &quot;Unsent&quot; emails can be reattemped.
              </td>
            )}
          </tr>
        </tbody>
      </table>
      <DisplayBox title="Email subject">
        <p>{query_data.email_subject}</p>
      </DisplayBox>
      <DisplayBox title="Email body">
        {query_data.email_body_text ? (
          <p className={styles.email_body_text}>{query_data.email_body_text}</p>
        ) : (
          <p className="text-base text-italic">N/A</p>
        )}
      </DisplayBox>
      {/* //TODO: Display the HTML email body  */}
      {/* https://www.pivotaltracker.com/story/show/186429046 */}
      <table className={`usa-table usa-table--stacked-header bg-white width-full ${styles.table}`}>
        <tbody>
          <tr>
            <td>
              <span className="text-semibold">Sent time</span>
            </td>
            <td>{fromUTCtoET(query_data.create_datetime, {use_long_date: true})}</td>
          </tr>
          <tr>
            <td>
              <span className="text-semibold">Message ID</span>
            </td>
            {query_data.message_id ? (
              <td>{query_data.message_id}</td>
            ) : (
              <td className="text-base text-italic">
                N/A. Message IDs only exists if the email was successfully sent.
              </td>
            )}
          </tr>
          <tr>
            <td>
              <span className="text-semibold">Rejected response</span>
            </td>
            {query_data.rejected_response ? (
              <td>{query_data.rejected_response}</td>
            ) : (
              <td className="text-base text-italic">
                N/A. Rejected response only exists if the email was unsuccessful.
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </section>
  );
}
