import {MeetingsGetByIdData} from '../../../../../common/types/meetings-get-by-id-res';
import PrevInfoLayout from '../../../../layouts/PrevInfoLayout/PrevInfoLayout';
import {MeetingForm} from '../../../../types/meeting-types';
import {MtgStartEndDate} from '../../../MeetingComponents/MtgStartEndDate/MtgStartEndDate';
import metadata_client_meeting from '../../../../lib/metadata-client-meeting.json';
import {broadcast_email_message, truncateSeconds} from '../../../../lib/utils';
import InlineAlert from '../../InlineAlert/InlineAlert';

type Props = {
  query_data: MeetingsGetByIdData;
  formData: MeetingForm;
  id_name: 'start_datetime' | 'in_person_reg_end_datetime' | 'virtual_reg_end_datetime';
  id_name_2?: 'end_datetime';
  is_initial_unlisted?: boolean;
};

export default function PrevTimeInfo({
  query_data,
  formData,
  id_name,
  id_name_2,
  is_initial_unlisted,
}: Props) {
  const query_start_str = query_data[id_name];
  const form_start_str = formData[id_name];
  const query_start = (query_start_str && truncateSeconds(query_start_str)) || null;
  const form_start = (form_start_str && truncateSeconds(form_start_str)) || null;

  const query_end_str = id_name_2 ? query_data[id_name_2] : null;
  const form_end_str = id_name_2 ? formData[id_name_2] : null;
  const query_end = (query_end_str && truncateSeconds(query_end_str)) || null;
  const form_end = (form_end_str && truncateSeconds(form_end_str)) || null;

  if (id_name_2) {
    if (query_start != form_start || query_end != form_end) {
      return (
        <>
          {!is_initial_unlisted &&
            (query_data.start_datetime != formData.start_datetime ||
              query_data.end_datetime != formData.end_datetime) && (
              <InlineAlert is_broadcast>{broadcast_email_message}</InlineAlert>
            )}
          <PrevInfoLayout>
            <MtgStartEndDate
              start_date={query_start}
              end_date={query_end}
              custom_start_string={`Previous ${metadata_client_meeting[id_name].title}:`}
              custom_end_string={`Previous ${metadata_client_meeting[id_name_2].title}:`}
            />
            <div className="text-primary">
              <MtgStartEndDate
                start_date={form_start}
                end_date={form_end}
                custom_start_string={`New ${metadata_client_meeting[id_name].title}:`}
                custom_end_string={`New ${metadata_client_meeting[id_name_2].title}:`}
              />
            </div>
          </PrevInfoLayout>
        </>
      );
    }
  } else if (query_start != form_start) {
    return (
      <PrevInfoLayout>
        <MtgStartEndDate
          start_date={query_start}
          custom_start_string={`Previous ${metadata_client_meeting[id_name].title}:`}
        />
        <div className="text-primary">
          <MtgStartEndDate
            start_date={form_start}
            custom_start_string={`New ${metadata_client_meeting[id_name].title}:`}
          />
        </div>
      </PrevInfoLayout>
    );
  }

  return <></>;
}
