interface Props {
  children: React.ReactNode;
  is_disabled?: boolean;
}

export default function SectionTitle({children, is_disabled}: Props) {
  return (
    <p className={`text-semibold margin-0 ${is_disabled ? 'text-base' : 'text-primary'}`}>
      {children}
    </p>
  );
}
