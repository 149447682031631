import {Link, useParams} from 'react-router-dom';
import {
  useQueryMeetingByID,
  useQueryMtgFileCategories,
  useQueryStatuses,
} from '../../../services/meeting-queries';
import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import CreateEditMeeting from '../CreateEditMeeting';
import {useEffect, useState} from 'react';

export default function EditMeetingWrapper() {
  const {id} = useParams();
  if (!id) throw new Error('id not passed');
  const meeting_query = useQueryMeetingByID(id);
  const status_query = useQueryStatuses();
  const file_categories_query = useQueryMtgFileCategories();

  // Make it possible to reload query data into the form. The <CreateEditMeeting> component needs to be unmounted and
  // remounted so that all states set by setState() will reinitialize using updated query data.
  const [reloadTrigger, setReloadTrigger] = useState(false);
  useEffect(() => setReloadTrigger(false), [reloadTrigger]);

  // This shouldn't render since we're using err boundary in all queries
  if (meeting_query.isError || status_query.isError || file_categories_query.isError)
    return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>{meeting_query.isPending ? 'Loading meeting...' : meeting_query.data.title}</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {meeting_query.isPending ||
      status_query.isPending ||
      file_categories_query.isPending ||
      reloadTrigger ? (
        <PageLoading message="Loading meeting" />
      ) : (
        <CreateEditMeeting
          query_data={meeting_query.data}
          statuses_data={status_query.data}
          file_categories={file_categories_query.data}
          pk={id}
          is_edit_mtg
          reloadMeeting={() => setReloadTrigger(true)}
        />
      )}
    </>
  );
}
