import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../../layouts/GenericModalLayout/GenericModalLayout';
import {HandleInputChange} from '../../../../types/form-types';

type HandleDeleteConfirmation = (confirmed: boolean) => void;

interface Props {
  isModalOpen: boolean;
  handleDeleteConfirmation: HandleDeleteConfirmation;
  is_my_acc?: true;
}

export default function ConfirmAccountDeletionModal({
  isModalOpen,
  handleDeleteConfirmation,
  is_my_acc,
}: Props) {
  return (
    <GenericModalLayout
      isOpen={isModalOpen}
      closeModal={() => handleDeleteConfirmation(false)}
      modal_heading="Confirm account deletion"
    >
      <div className="usa-prose">
        Are you sure you want to delete {is_my_acc ? 'your' : 'this'} account? If so, click yes
        below and then &quot;Save changes&quot;. If {is_my_acc ? 'your' : 'this'} account has any
        registrations for future meetings, those will also be removed.
      </div>
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <Button
            type="button"
            className="usa-button--secondary"
            onClick={() => handleDeleteConfirmation(true)}
          >
            Mark for deletion
          </Button>
          <Button type="button" onClick={() => handleDeleteConfirmation(false)} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </GenericModalLayout>
  );
}

export function handleTypedDeleteConfirmation<T>(
  setFormData: React.Dispatch<React.SetStateAction<T>>,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const handleDeleteConfirmation: HandleDeleteConfirmation = (confirmed) => {
    setIsModalOpen(false);
    setFormData((formData) => ({...formData, is_removed: confirmed}));
  };
  return handleDeleteConfirmation;
}

export function handleTypedDeleteCheckbox(
  handleCheckbox: HandleInputChange,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const handleDeleteCheckbox = function (event: React.ChangeEvent<HTMLInputElement>) {
    const checkbox = event.currentTarget;
    if (checkbox.checked) {
      setIsModalOpen(true);
    } else {
      handleCheckbox(event);
    }
  };
  return handleDeleteCheckbox;
}
