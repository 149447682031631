import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useQueryMeetingByID} from '../../../services/meeting-queries';
import {useQueryMyAccount} from '../../../services/account-queries';
import {useQueryRegistrant} from '../../../services/registrant-queries';
import Registration from '../Registration';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import {useEffect, useState} from 'react';

export default function RegistrationWrapper() {
  const {id} = useParams();
  if (!id) throw new Error('id not passed');
  const meeting_query = useQueryMeetingByID(id);
  const account_query = useQueryMyAccount();
  const registrant_query = useQueryRegistrant(id);
  const [canRedirect, setCanRedirect] = useState(true);
  const navigate = useNavigate();

  // If user is already registered for this meeting, send them to /edit-registration. This is a one-shot check. We do
  // not want this wrapper hijacking navigation when a new registration is created.
  useEffect(() => {
    if (!canRedirect || registrant_query.isPending) return;
    if (registrant_query.isSuccess) {
      navigate(`/meetings/${id}/edit-registration`, {replace: true});
    }
    setCanRedirect(false);
  }, [
    canRedirect,
    setCanRedirect,
    registrant_query.isSuccess,
    registrant_query.isPending,
    id,
    navigate,
  ]);

  // This shouldn't render since we're using err boundary in the queries
  if (meeting_query.isError || account_query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <span>
            {meeting_query.isPending ? (
              'Loading meeting...'
            ) : (
              <Link to={`/meetings/${id}`} className="usa-breadcrumb__link">
                {meeting_query.data.title}
              </Link>
            )}
          </span>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Register</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {meeting_query.isPending ||
      account_query.isPending ||
      (canRedirect && registrant_query.isPending) ? (
        <PageLoading message="Loading registration" />
      ) : (
        <Registration
          meeting_id={id}
          meeting_data={meeting_query.data}
          account_data={account_query.data}
        />
      )}
    </>
  );
}
