export function getHostEnv(
  hostname: string
):
  | 'phmsa-prod'
  | 'phmsa-stage'
  | 'phmsa-uat'
  | 'csnw-stable'
  | 'csnw-uat'
  | 'csnw-ci'
  | 'dev'
  | 'unknown' {
  if (hostname.endsWith('.gov')) {
    if (hostname.includes('-dev.')) {
      return 'phmsa-uat';
    } else if (hostname.includes('-stage.')) {
      return 'phmsa-stage';
    } else {
      return 'phmsa-prod';
    }
  } else if (hostname.endsWith('.cornerstonenw.com')) {
    if (hostname.includes('-stable.')) {
      return 'csnw-stable';
    } else if (hostname.includes('-uat.')) {
      return 'csnw-uat';
    } else if (hostname.includes('-ci-')) {
      return 'csnw-ci';
    }
  } else if (['localhost', '127.0.0.1', '::1', '[::1]'].includes(hostname)) {
    return 'dev';
  }

  return 'unknown';
}

export function getServerOrigin(server_base_url: string | undefined, server_port?: string) {
  if (!server_base_url || !/^https?:/i.test(server_base_url)) {
    return '';
  }

  // URL.origin removes trailing colon or unnecessary port and lowercases the string
  return new URL(`${server_base_url}:${server_port ?? ''}`).origin;
}
