import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {EmailsGetResBody} from '../../common/types/emails-get-res';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from '../lib/success-false-error';
import {EmailReattemptPostResBody} from '../../common/types/email-reattempt-post-res';
import {EmailsGetByIdResBody} from '../../common/types/emails-get-by-id-res';

async function fetchEmail(email_id: string) {
  const res = await axios.get<EmailsGetByIdResBody | ErrorResBody>(`/api/emails/${email_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryEmailById(email_id: string) {
  return useQuery({
    queryKey: ['emails', email_id],
    queryFn: () => fetchEmail(email_id),
    throwOnError: true,
  });
}

async function fetchEmails() {
  const res = await axios.get<EmailsGetResBody | ErrorResBody>('/api/emails');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryEmails() {
  return useQuery({
    queryKey: ['emails'],
    queryFn: () => fetchEmails(),
    throwOnError: true,
  });
}

async function reattemptEmail(email_id: string) {
  const res = await axios.post<EmailReattemptPostResBody | ErrorResBody>(
    `/api/emails/${email_id}/reattempt`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationReattemptEmail() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (email_id: string) => reattemptEmail(email_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['emails'], type: 'all'});
    },
  });
}
