import {Link, Navigate, useLocation} from 'react-router-dom';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {LogoutConfirmationState} from '../../types/auth-types';
import logingovlogo from '../../images/login-gov-logo.svg';
import {logout_success} from '../../lib/utils';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

export default function LoginGovLogoutConfirmation() {
  usePageTitle('Logout confirmation');
  const location = useLocation();
  const state = location.state as LogoutConfirmationState | null;

  // Handle direct navigation to this route
  if (!state) return <Navigate to={'/'} />;

  return (
    <>
      <SuccessAlert>{logout_success}</SuccessAlert>
      <p>You may proceed to sign out at Login.gov:</p>
      <Link to={state.logout_url} className="usa-button usa-button--outline box-shadow-3">
        Sign out at <img src={logingovlogo} className="height-105" alt="Login.gov" />
      </Link>
      <ul className="usa-list">
        <li>This is important when using a public computer.</li>
        <li>
          If you do not sign out of Login.gov, other users may be able to access your account
          without providing credentials.
        </li>
      </ul>
    </>
  );
}
