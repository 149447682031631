import {useEffect} from 'react';
import {app_name} from '../../../common/lib/shared-vars';

/**
 * Set document page title
 * @param title Page title
 */
export const usePageTitle = function (title: string) {
  useEffect(() => {
    document.title = title ? `${title} - ${app_name}` : app_name;

    // Apply default title when component unmounts in case a new page component is introduced that
    // doesn't yet call this hook. Then at least the base name of the app will display.
    return () => {
      document.title = app_name;
    };
  }, [title]);
};
