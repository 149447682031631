import {Dispatch, SetStateAction} from 'react';
import {MeetingsGetByIdData} from '../../../../../common/types/meetings-get-by-id-res';
import {RegistrationCRResponse, RegistrationForm} from '../../../../types/registration-types';
import {SetValidationMessages} from '../../../../types/form-types';
import CustomRegistrationRadio from './CustomRegistrationRadio';
import CustomRegistrationTextarea from './CustomRegistrationTextarea';
import {RegistrantGetResCRResponse} from '../../../../../common/types/registrant-get-res';
import {RegistrantsGetByUserIdResCRResponse} from '../../../../../common/types/registrants-get-by-user-id-res';
import {RegFormVariant} from '../../../../lib/utils';
import SectionTitle from '../../SectionTitle/SectionTitle';

type CRQuestions = MeetingsGetByIdData['cr_questions'];
type CRQuestion = CRQuestions[0];

type Props = {
  cr_questions: CRQuestions;
  formData: RegistrationForm;
  setFormData: Dispatch<SetStateAction<RegistrationForm>>;
  setValidationMessages: SetValidationMessages;
} & (
  | {
      form_variant: RegFormVariant.create;
      recorded_cr_responses?: never;
      disable_all?: never;
    }
  | {
      form_variant: Exclude<RegFormVariant, RegFormVariant.create>;
      recorded_cr_responses: RegistrantsGetByUserIdResCRResponse[] | RegistrantGetResCRResponse[];
      disable_all: boolean;
    }
);

export default function CustomRegistrationQuestions({
  cr_questions,
  form_variant,
  recorded_cr_responses,
  disable_all,
  formData,
  setFormData,
  setValidationMessages,
}: Props) {
  const updateCRFormData = (crq_id: string, responses: RegistrationCRResponse['responses']) => {
    setFormData((formData) => {
      // structuredClone() isn't available in target ES2017
      const updatedData = {
        ...formData,
        cr_responses: formData.cr_responses.map((crr) => ({
          ...crr,
          responses: [...crr.responses],
        })),
      };
      const crr = updatedData.cr_responses.find((crr) => crr.cr_question_fk == crq_id);
      if (!crr) throw new Error(`CRQ (${crq_id}) is missing in CR responses`);
      crr.responses = [...responses];
      return updatedData;
    });
  };

  if (!cr_questions.length) return <></>;

  const crq_crr_zip = cr_questions.map<[CRQuestion, RegistrationCRResponse]>((crq) => {
    const crr = formData.cr_responses.find((crr) => crr.cr_question_fk == crq.pk);
    if (!crr) throw new Error(`CRQ (${crq.pk}) is missing in CR responses`);
    return [crq, crr];
  });

  return (
    <section className="margin-top-3">
      <SectionTitle>Additional questions</SectionTitle>
      {crq_crr_zip.map(([crq, crr]) =>
        crq.type == 'radio' ? (
          <CustomRegistrationRadio
            key={crq.pk}
            crq={crq}
            form_variant={form_variant}
            form_response={crr.responses[0]}
            recorded_response={
              form_variant != RegFormVariant.create
                ? recorded_cr_responses.find((crr) => crr.cr_question_fk == crq.pk)?.responses[0]
                : undefined
            }
            is_disabled={disable_all}
            updateCRFormData={updateCRFormData}
            setValidationMessages={setValidationMessages}
          />
        ) : (
          <CustomRegistrationTextarea
            key={crq.pk}
            crq={crq}
            form_response={crr.responses[0] ?? ''}
            is_disabled={disable_all}
            updateCRFormData={updateCRFormData}
            setValidationMessages={setValidationMessages}
          />
        )
      )}
    </section>
  );
}
