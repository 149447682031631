import {Navigate, useSearchParams} from 'react-router-dom';
import LoginError from '../LoginError/LoginError';
import {MeetingsListAlertState} from '../../types/meeting-types';

export default function LoginGovLoginError() {
  const [searchParams] = useSearchParams();

  if (searchParams.get('access_denied') == 'true')
    return <Navigate to={'/'} state={{is_login_gov_err: true} as MeetingsListAlertState} />;

  return <LoginError is_disabled_user={searchParams.get('disabled') == 'true'} is_login_gov />;
}
