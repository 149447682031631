import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useQueryOthersAccount, useQueryRoles} from '../../../services/account-queries';
import ViewOthersAccount from '../ViewOthersAccount';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import {useEffect} from 'react';
import {usePrivilegesContext} from '../../../lib/PrivilegesContext';

export default function ViewOthersAccountWrapper() {
  const {id} = useParams();
  if (!id) throw new Error('id not passed');
  const account_query = useQueryOthersAccount(id);
  const roles_query = useQueryRoles();
  const navigate = useNavigate();
  const privileges = usePrivilegesContext();

  const is_my_account = id == privileges.pk;
  useEffect(() => {
    if (is_my_account) navigate('/my-account');
  }, [is_my_account, navigate]);

  // This shouldn't render since we're using err boundary in the query
  if (account_query.isError || roles_query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to="/manage-accounts" className="usa-breadcrumb__link">
            Manage accounts
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>
            {account_query.isPending || is_my_account
              ? 'Loading account...'
              : !account_query.data.is_verified
              ? 'Unverified User'
              : `${account_query.data.first_name ? account_query.data.first_name : ''} ${
                  account_query.data.last_name ? account_query.data.last_name : ''
                }`}
          </span>
        </Breadcrumb>
      </BreadcrumbBar>
      {account_query.isPending || roles_query.isPending || is_my_account ? (
        <PageLoading message="Loading account" />
      ) : (
        <ViewOthersAccount query_data={account_query.data} roles_data={roles_query.data} />
      )}
    </>
  );
}
