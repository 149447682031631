import Spinner from '../Spinner/Spinner';

interface Props {
  message?: string;
}

export default function TextLoading({message = 'Loading'}: Props) {
  return message ? (
    <span className="display-flex flex-align-center flex-justify-center">
      <span className="margin-right-1">
        <Spinner size="small" />
      </span>
      <span>{message}</span>
    </span>
  ) : (
    <Spinner size="small" />
  );
}
