import {Link, useParams} from 'react-router-dom';
import {useQueryEmailById} from '../../../services/email-queries';
import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import ViewEmail from '../ViewEmail';
import {fromUTCtoET} from '../../../lib/utils';

export default function ViewEmailWrapper() {
  const {id} = useParams();
  if (!id) throw new Error('id not passed');
  const email_query = useQueryEmailById(id);

  // This shouldn't render since we're using err boundary in the query
  if (email_query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to="/email-queue" className="usa-breadcrumb__link">
            Email queue
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>
            {email_query.isPending
              ? 'Loading email...'
              : fromUTCtoET(email_query.data.create_datetime, {use_long_date: true}) +
                ' | ' +
                email_query.data.email_subject}
          </span>
        </Breadcrumb>
      </BreadcrumbBar>
      {email_query.isPending ? (
        <PageLoading message="Loading email" />
      ) : (
        <ViewEmail pk={id} query_data={email_query.data} />
      )}
    </>
  );
}
