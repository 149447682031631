import {RemirrorJSON} from 'remirror';
import {RemirrorRendererComponent} from '../../RemirrorRendererComponent/RemirrorRendererComponent';
import RemirrorErrorBoundary from '../../RemirrorErrorBoundary/RemirrorErrorBoundary';
import {Alert} from '@trussworks/react-uswds';
import styles from './RemirrorAlert.module.scss';

interface Props {
  json: RemirrorJSON;
  title: string;
}
export default function RemirrorAlert({json, title}: Props) {
  return (
    <RemirrorErrorBoundary field_name={title}>
      <div className={styles.remirror_wrapper}>
        {/* Alert's `validation` boolean causes component to render without an internal `p` element if true */}
        <Alert type="info" headingLevel="h2" noIcon className="margin-bottom-3" validation>
          <RemirrorRendererComponent json={json} />
        </Alert>
      </div>
    </RemirrorErrorBoundary>
  );
}
