import {Tooltip} from 'react-tooltip';
import styles from './FormSubmit.module.scss';
import {ValidationMessages} from '../../../types/form-types';
import {forwardRef} from 'react';

interface Props {
  validationMessages: ValidationMessages;
  is_disabled?: boolean;
  is_full_width?: boolean;
  children: React.ReactNode;
  use_outline_btn?: boolean;
}

export default forwardRef(function FormSubmit(
  {validationMessages, is_disabled, children, is_full_width, use_outline_btn}: Props,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <div className="width-full mobile-lg:width-auto">
      {/* Intentionally not using <Button> because trussworks' component does not support forward refs */}
      <button
        id="submit-btn"
        type="submit"
        className={`usa-button ${use_outline_btn ? 'usa-button--outline' : ''} ${styles.save_btn}${
          is_full_width ? ' width-full' : ''
        } margin-right-0`}
        aria-disabled={!!validationMessages.length || is_disabled}
        data-testid="button"
        ref={ref}
      >
        {children}
      </button>
      {!!validationMessages.length && (
        <Tooltip anchorSelect="#submit-btn" place="bottom">
          <ul className="usa-list usa-list--unstyled">
            {validationMessages.map((msg, idx) => (
              <li key={idx}>{msg}</li>
            ))}
          </ul>
        </Tooltip>
      )}
    </div>
  );
});
