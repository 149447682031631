import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from '../lib/success-false-error';
import {ClientConfigGetResBody} from '../../common/types/client-config-get-res';

async function fetchClientConfig() {
  const res = await axios.get<ClientConfigGetResBody | ErrorResBody>('/api/client-config');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

interface QueryClientConfigOptions {
  is_enabled?: boolean;
}
export function useQueryClientConfig({is_enabled = true}: QueryClientConfigOptions = {}) {
  return useQuery({
    queryKey: ['client-config'],
    queryFn: () => fetchClientConfig(),
    enabled: is_enabled,
  });
}
