import {useEffect} from 'react';
import {useMutationLogout} from '../../../services/auth-queries';
import Confirmation from '../Confirmation';
import LoginError from '../../LoginError/LoginError';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';

export default function ConfirmationWrapper() {
  // Destructure logout mutation source: https://github.com/TanStack/query/discussions/986#discussioncomment-2514209
  const {mutate, isSuccess, isError} = useMutationLogout();

  useEffect(() => {
    mutate();
  }, [mutate]);

  if (isError) return <LoginError />;
  if (isSuccess) return <Confirmation />;
  return <PageLoading />;
}
