import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../../../layouts/GenericModalLayout/GenericModalLayout';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  handleUncheck: () => void;
  modal_heading: string;
  confirm_btn_text: string;
  children: React.ReactNode;
}

export default function ConfirmFormatChangeModal({
  isModalOpen,
  closeModal,
  handleUncheck,
  modal_heading,
  confirm_btn_text,
  children,
}: Props) {
  return (
    <GenericModalLayout isOpen={isModalOpen} closeModal={closeModal} modal_heading={modal_heading}>
      <div className="usa-prose">{children}</div>
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <Button
            type="button"
            className="usa-button--secondary"
            onClick={() => {
              handleUncheck();
              closeModal();
            }}
          >
            {confirm_btn_text}
          </Button>
          <Button type="button" onClick={closeModal} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </GenericModalLayout>
  );
}
