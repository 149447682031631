import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {MeetingsGetByIdResBody} from '../../common/types/meetings-get-by-id-res';
import {MeetingsGetResBody} from '../../common/types/meetings-get-res';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from '../lib/success-false-error';
import {StatusesGetResBody} from '../../common/types/statuses-get-res';
import {MeetingsPostPutReqBody} from '../../common/types/meetings-post-put-req';
import {MeetingsPostPutResBody} from '../../common/types/meetings-post-put-res';
import {MeetingForm, MtgFormFile, MtgFormCRQuestion} from '../types/meeting-types';
import {MeetingsDelResBody} from '../../common/types/meetings-del-res';
import {formatMeetingFormData} from '../lib/meeting-utils';
import {MtgFileCategoriesGetResBody} from '../../common/types/meeting-file-categories-get-res';
import {getMtgPostPutReqCRQuestion, getMtgPostPutReqFile} from '../lib/utils';
import {MtgFilePostResBody} from '../../common/types/meeting-file-post-res';

type MeetingId = string;

async function fetchMeeting(meeting_id: MeetingId) {
  const res = await axios.get<MeetingsGetByIdResBody | ErrorResBody>(`/api/meetings/${meeting_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryMeetingByID(meeting_id: MeetingId) {
  return useQuery({
    queryKey: ['meetings', meeting_id],
    queryFn: () => fetchMeeting(meeting_id),
    retry: false,
    throwOnError: true,
  });
}

async function fetchMeetings() {
  const res = await axios.get<MeetingsGetResBody | ErrorResBody>(`/api/meetings`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryMeetings() {
  return useQuery({
    queryKey: ['meetings', 'list'],
    queryFn: fetchMeetings,
  });
}

async function fetchStatuses() {
  const res = await axios.get<StatusesGetResBody | ErrorResBody>('/api/statuses');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryStatuses() {
  return useQuery({
    queryKey: ['statuses'],
    queryFn: () => fetchStatuses(),
    throwOnError: true,
  });
}

export async function createMeeting(req_body: MeetingsPostPutReqBody) {
  const res = await axios.post<MeetingsPostPutResBody | ErrorResBody>('/api/meetings', req_body);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationCreateMeeting() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      mtg_form,
      form_crqs,
      form_files,
    }: {
      mtg_form: MeetingForm;
      form_crqs: MtgFormCRQuestion[];
      form_files: MtgFormFile[];
    }) => {
      const formatted_body: MeetingsPostPutReqBody = {
        ...formatMeetingFormData(mtg_form),
        cr_questions: form_crqs.map(getMtgPostPutReqCRQuestion),
        files: form_files.map(getMtgPostPutReqFile),
      };
      return createMeeting(formatted_body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['meetings', 'list'], type: 'all'});
    },
  });
}

export async function editMeeting(meeting_id: MeetingId, req_body: MeetingsPostPutReqBody) {
  const res = await axios.put<MeetingsPostPutResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationEditMeeting() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      mtg_form,
      meeting_id,
      form_crqs,
      form_files,
    }: {
      mtg_form: MeetingForm;
      meeting_id: MeetingId;
      form_crqs: MtgFormCRQuestion[];
      form_files: MtgFormFile[];
    }) => {
      const formatted_body: MeetingsPostPutReqBody = {
        ...formatMeetingFormData(mtg_form),
        cr_questions: form_crqs.map(getMtgPostPutReqCRQuestion),
        files: form_files.map(getMtgPostPutReqFile),
      };
      return editMeeting(meeting_id, formatted_body);
    },
    onSuccess: async (data, {meeting_id}) => {
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['meetings', 'list'], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['meetings', meeting_id], type: 'all'}),
      ]);
    },
  });
}

export async function deleteMeeting(meeting_id: MeetingId) {
  const res = await axios.delete<MeetingsDelResBody | ErrorResBody>(`/api/meetings/${meeting_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationDeleteMeeting() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (meeting_id: MeetingId) => deleteMeeting(meeting_id),
    onSuccess: async (data, meeting_id) => {
      // Remove the cached query but do not attempt refetch or it will fail and could trigger throwOnError in query
      queryClient.removeQueries({queryKey: ['meetings', meeting_id]});

      await queryClient.invalidateQueries({queryKey: ['meetings', 'list'], type: 'all'});
    },
  });
}

export async function getMtgFileCategories() {
  const res = await axios.get<MtgFileCategoriesGetResBody | ErrorResBody>(
    '/api/meeting-file-categories'
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryMtgFileCategories() {
  return useQuery({
    queryKey: ['mtg_file_categories'],
    queryFn: () => getMtgFileCategories(),
    throwOnError: true,
  });
}

export async function uploadMeetingFile(mtg_id: string, mtg_file_id: string, file: File) {
  // This is a multipart/form-data endpoint
  const form_data = new FormData();
  form_data.append('pk', mtg_file_id);
  form_data.append('file', file);

  const res = await axios.post<MtgFilePostResBody | ErrorResBody>(
    `/api/meetings/${mtg_id}/file`,
    form_data
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationUploadMeetingFile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({mtg_id, mtg_file_id, file}: {mtg_id: string; mtg_file_id: string; file: File}) =>
      uploadMeetingFile(mtg_id, mtg_file_id, file),
    onSuccess: async (data, variables) => {
      const {mtg_id} = variables;
      await queryClient.invalidateQueries({queryKey: ['meetings', mtg_id], type: 'all'});
    },
  });
}
