import {Alert, Button, Icon} from '@trussworks/react-uswds';
import {PivAssociationTypes} from '../../../../types/auth-types';
import {useRef} from 'react';
import {useClickOutside} from '../../../../lib/hooks/useClickOutside';
import {
  piv_already_associated,
  piv_associate_success,
  piv_association_err,
} from '../../../../lib/utils';
import ErrorAlert from '../../../../components/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../../../components/SuccessAlert/SuccessAlert';

interface Props {
  association_type: PivAssociationTypes;
  setPivAssociationState: React.Dispatch<React.SetStateAction<PivAssociationTypes>>;
}

export default function PivAssociationBanner({association_type, setPivAssociationState}: Props) {
  const alertRef = useRef<HTMLDivElement>(null);
  const close_btn = (
    <Button
      type="button"
      unstyled
      onClick={() => setPivAssociationState(null)}
      className="margin-right-2"
      data-testid="banner_close_btn"
    >
      <Icon.Close className="text-black" />
    </Button>
  );

  useClickOutside({ref: alertRef, onClickOutside: () => setPivAssociationState(null)});
  return (
    <div className="margin-top-0" ref={alertRef}>
      {association_type == 'new' ? (
        <SuccessAlert cta={close_btn}>{piv_associate_success}</SuccessAlert>
      ) : association_type == 'exists' ? (
        <Alert type="info" headingLevel="h2" slim cta={close_btn}>
          {piv_already_associated}
        </Alert>
      ) : association_type == 'error' ? (
        <ErrorAlert cta={close_btn}>{piv_association_err}</ErrorAlert>
      ) : (
        <></>
      )}
    </div>
  );
}
