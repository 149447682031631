import {useEffect, useState} from 'react';
import {CRQuestionTextArea} from '../../../../../common/types/meetings-get-by-id-res';
import {Label, Textarea} from '@trussworks/react-uswds';
import {RegistrationCRResponse} from '../../../../types/registration-types';
import {addOrRemoveValidationMessage} from '../../../../lib/utils';
import {SetValidationMessages} from '../../../../types/form-types';

// Max length from api-docs\schemas\registrant-put-post-req.schema.json
const max_length = 1000;

interface Props {
  crq: CRQuestionTextArea;
  form_response: string;
  is_disabled?: boolean;
  updateCRFormData: (crq_id: string, responses: RegistrationCRResponse['responses']) => void;
  setValidationMessages: SetValidationMessages;
}

export default function CustomRegistrationTextarea({
  crq,
  form_response,
  is_disabled,
  updateCRFormData,
  setValidationMessages,
}: Props) {
  const [isValid, setIsValid] = useState(true);
  const id_name = `crq-${crq.pk}`;
  const question_num = crq.order + 1;

  useEffect(() => {
    const {is_required} = crq;
    let is_valid = true;

    const required_question_unanswered = is_required && !form_response.trim();
    is_valid &&= !required_question_unanswered;
    addOrRemoveValidationMessage({
      is_condition_met: required_question_unanswered,
      err_message: `A response is required for additional question ${question_num}`,
      setValidationMessages,
    });

    const is_too_long = form_response.trim().length > max_length;
    is_valid &&= !is_too_long;
    addOrRemoveValidationMessage({
      is_condition_met: is_too_long,
      err_message: `Response for additional question ${question_num} cannot be more than ${max_length} characters`,
      setValidationMessages,
    });

    setIsValid(is_valid);
  }, [crq, question_num, form_response, setValidationMessages]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = event.target;
    updateCRFormData(crq.pk, [value]);
  };

  return (
    <div>
      <Label
        htmlFor={id_name}
        className={`maxw-none width-full word-break-word ${crq.order == 0 ? 'margin-top-05' : ''}`}
      >
        {`${question_num}. ${crq.title}`}
        {crq.is_required && ' *'}
      </Label>
      <Textarea
        id={id_name}
        name={id_name}
        value={form_response}
        onChange={handleChange}
        required={crq.is_required}
        disabled={is_disabled}
        error={!isValid}
      />
    </div>
  );
}
