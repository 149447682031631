import {Tag} from '@trussworks/react-uswds';
import {tag_color_dict} from '../../lib/utils';

interface Props {
  display_value: string;
  color: keyof typeof tag_color_dict;
}

export default function TagAlt({display_value, color}: Props) {
  return (
    <Tag className={`bg-white border-1px text-semibold ${tag_color_dict[color]}`}>
      {display_value}
    </Tag>
  );
}
