import {HTMLAttributes, forwardRef} from 'react';
import {DnDItem} from '../DnDContextProvider';

type DisplayItemProps = {
  item: DnDItem;
} & HTMLAttributes<HTMLDivElement>;

const DisplayItem = forwardRef<HTMLDivElement, DisplayItemProps>(function DisplayItem(
  {item, ...props},
  ref
) {
  return (
    <div ref={ref} {...props} data-testid="dnd-item">
      {item.content}
    </div>
  );
});

export default DisplayItem;
