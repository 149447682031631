import {RefObject, useEffect} from 'react';

/**
 * This Hook can be used for detecting clicks outside the Opened Menu
 */

interface Props {
  ref: RefObject<HTMLElement>;
  onClickOutside: () => void;
}

// Taken from https://stackoverflow.com/a/63359693
export function useClickOutside({ref, onClickOutside}: Props) {
  useEffect(() => {
    function handleClickOutside({target}: MouseEvent) {
      // https://stackoverflow.com/a/71193834
      if (ref.current && !ref.current.contains(target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
