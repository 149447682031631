// Avoid importing functions from remirror/prosemirror outside of the lazy loaded remirror editor. Even
// `import {isRemirrorJSON} from '@remirror/core-utils'` causes significant inflation of the main bundle
// since these utilities are used outside of the lazy loaded component. Importing types is ok since they
// do not contribute to compiled JS.
import {RemirrorJSON} from 'remirror';

// eslint-disable-next-line max-len
// Reference: https://github.com/remirror/remirror/blob/dd4d6ff7e1b1bc8907d65b78a69ef1e09037c182/packages/remirror__core-utils/src/core-utils.ts#L975-L982
function isRemirrorJSON(value: unknown): value is RemirrorJSON {
  return (
    typeof value == 'object' &&
    !!value &&
    !Array.isArray(value) &&
    'type' in value &&
    'content' in value &&
    value.type == 'doc' &&
    Array.isArray(value.content)
  );
}

// This function is used when we want to know if data is JSON or not & handle that JSON if it is
// to avoid running `JSON.parse()` twice
export function checkIfRemirrorStringAndGetJson(value: string) {
  // Check if value is a string or a JSON string, and handle appropriately:
  // https://stackoverflow.com/a/20392392
  try {
    // Json.parse() returns value type `any` which triggers ESlint "unsafe any" rule
    // The problem is: Json.parse() is unsafe by design, and that's being leveraged in this try/catch
    // Giving the variable parsed the type `unknown` satisfies Eslint and maintains this functionality
    const parsed: unknown = JSON.parse(value);
    if (isRemirrorJSON(parsed)) {
      return parsed;
    }
  } catch {
    // Handling of non-RemirrorJSON value is done in this function's caller
  }
}

export function getRemirrorContent(value: string) {
  const json = checkIfRemirrorStringAndGetJson(value);
  return json || value;
}
