import {DateTime} from 'luxon';
import {RegFormVariant, eastern_tz} from '../../../lib/utils';
import {MeetingsGetByIdData} from '../../../../common/types/meetings-get-by-id-res';

interface Props {
  meeting_title: MeetingsGetByIdData['title'];
  meeting_start_datetime: MeetingsGetByIdData['start_datetime'];
  form_variant: RegFormVariant;
  /** Required for RegFormVariant.edit_others */
  other_registrant_name?: string;
}

export default function RegistrationHeading({
  meeting_title,
  meeting_start_datetime,
  form_variant,
  other_registrant_name,
}: Props) {
  const start_date_str = meeting_start_datetime
    ? DateTime.fromISO(meeting_start_datetime).setZone(eastern_tz).toFormat('DDD')
    : null;

  return (
    <h2 className="line-height-serif-3 font-heading-lg">
      {form_variant == RegFormVariant.create && 'You are registering for'}
      {form_variant == RegFormVariant.edit && 'Edit your registration for'}
      {form_variant == RegFormVariant.edit_others && (
        <>
          <span>You are viewing </span>
          <span className="text-primary">{`${other_registrant_name || 'Missing Name'}'s `}</span>
          <span>registration for</span>
        </>
      )}
      <span className="text-primary">{` ${meeting_title}`}</span>
      {start_date_str && (
        <>
          <span> on </span>
          <span className="text-primary">{start_date_str}</span>
        </>
      )}
      <span>.</span>
    </h2>
  );
}
