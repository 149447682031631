import {useEffect, useMemo, useRef, useState} from 'react';
import {Alert, Grid} from '@trussworks/react-uswds';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {EditOthersRegistrationForm, RegistrationForm} from '../../types/registration-types';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import FormEmailInput from '../../components/FormComponents/FormEmailInput/FormEmailInput';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {
  RegFormVariant,
  addOrRemoveValidationMessage,
  handleTypedInputChange,
  handleTypedPhoneChange,
  meeting_registration_closed_msg,
  meeting_registration_format_validation_msg_dict,
} from '../../lib/utils';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import RegistrationFormatRadio, {
  Format,
  RegAllowed,
} from '../../components/FormComponents/RegistrationFormComponents/MeetingRegFormatRadio';
import {MeetingsGetByIdData} from '../../../common/types/meetings-get-by-id-res';
import {RegistrantGetResData} from '../../../common/types/registrant-get-res';
import {MeetingRegData, getMeetingRegSummary} from '../../lib/meeting-utils';
import RegistrationHeading from '../../components/MeetingComponents/RegistrationHeading/RegistrationHeading';
import {
  useMutationEditOthersRegistration,
  useMutationEditRegistrant,
} from '../../services/registrant-queries';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import {useNavigate} from 'react-router-dom';
import {ViewMeetingAlertState} from '../../types/meeting-types';
// eslint-disable-next-line max-len
import RemoveRegistrationButton from '../../components/FormComponents/RegistrationFormComponents/RemoveRegistrationButton/RemoveRegistrationButton';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import AdditionalInformation from '../../components/AccountComponents/AdditionalInformation/AdditionalInformation';
import {RegistrantsGetByUserIdData} from '../../../common/types/registrants-get-by-user-id-res';
import FormCheckbox, {
  handleTypedCheckbox,
} from '../../components/FormComponents/FormCheckbox/FormCheckbox';
// eslint-disable-next-line max-len
import CustomRegistrationQuestions from '../../components/FormComponents/RegistrationFormComponents/CustomRegistrationQuestions/CustomRegistrationQuestions';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';

function getRegAllowed(
  meeting_reg_data: MeetingRegData,
  registrant_data: {is_in_person_reg: boolean; is_virtual_reg: boolean}
): RegAllowed {
  const {is_in_person, is_virtual} = meeting_reg_data;
  const {
    is_open_for_reg,
    is_in_person_at_capacity,
    is_in_person_reg_ended,
    is_virtual_at_capacity,
    is_virtual_reg_ended,
  } = getMeetingRegSummary(meeting_reg_data);

  // Do not take capacity into account when a user is already registered for the format.
  const is_in_person_reg_allowed =
    is_open_for_reg &&
    is_in_person &&
    !is_in_person_reg_ended &&
    (registrant_data.is_in_person_reg || !is_in_person_at_capacity);
  const is_virtual_reg_allowed =
    is_open_for_reg &&
    is_virtual &&
    !is_virtual_reg_ended &&
    (registrant_data.is_virtual_reg || !is_virtual_at_capacity);
  const is_reg_allowed = is_in_person_reg_allowed || is_virtual_reg_allowed;

  return {
    is_reg_allowed,
    is_in_person_reg_allowed,
    is_virtual_reg_allowed,
  };
}

type Props = {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
} & (
  | {form_variant: RegFormVariant.edit; user_id?: never; registrant_data: RegistrantGetResData}
  | {
      form_variant: RegFormVariant.edit_others;
      user_id: string;
      registrant_data: RegistrantsGetByUserIdData;
    }
);

export default function EditRegistration({
  meeting_id,
  user_id,
  meeting_data,
  registrant_data,
  form_variant,
}: Props) {
  usePageTitle(
    form_variant == RegFormVariant.edit_others
      ? `Registration details for ${meeting_data.title || 'Untitled meeting'}`
      : `Edit registration for ${meeting_data.title || 'Untitled meeting'}`
  );

  const reg_allowed = useMemo(
    () => getRegAllowed(meeting_data, registrant_data),
    [meeting_data, registrant_data]
  );

  const initial_data: RegistrationForm = {
    is_in_person_reg: registrant_data.is_in_person_reg,
    is_virtual_reg: registrant_data.is_virtual_reg,
    first_name: registrant_data.first_name,
    last_name: registrant_data.last_name,
    org: registrant_data.org ?? '',
    job_title: registrant_data.job_title ?? '',
    phone: registrant_data.phone ?? '',
    comments: registrant_data.comments ?? '',
    cr_responses: meeting_data.cr_questions.map((crq) => {
      let responses: Array<string | null>;
      const recorded_cr_response = registrant_data.cr_responses.find(
        (crr) => crr.cr_question_fk == crq.pk
      );
      if (!recorded_cr_response) {
        responses = crq.type == 'radio' ? [null] : [''];
      } else if (crq.type == 'radio') {
        const recorded_response = recorded_cr_response.responses[0];
        responses = [crq.cr_options.find((cro) => cro.pk == recorded_response)?.pk ?? null];
      } else {
        responses = [...recorded_cr_response.responses];
      }
      return {
        cr_question_fk: crq.pk,
        responses,
      };
    }),
    did_attend: registrant_data.did_attend,
  };

  const privileges_data = usePrivilegesContext();
  const [formData, setFormData] = useState<RegistrationForm>(initial_data);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const editOwnRegMutation = useMutationEditRegistrant();
  const editOthersRegMutation = useMutationEditOthersRegistration();
  const navigate = useNavigate();

  const handleInputChange = handleTypedInputChange(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);
  const handleCheckbox = handleTypedCheckbox(setFormData);

  const [initialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  const full_name = `${registrant_data.first_name} ${registrant_data.last_name}`;

  useEffect(() => {
    addOrRemoveValidationMessage({
      is_condition_met: !reg_allowed.is_reg_allowed,
      err_message: meeting_registration_closed_msg,
      setValidationMessages,
    });
  }, [reg_allowed.is_reg_allowed]);

  const is_submit_disabled =
    (form_variant == RegFormVariant.edit && !!validationMessages.length) ||
    editOwnRegMutation.isPending ||
    editOthersRegMutation.isPending ||
    // On mutation success, we navigate to another page. Leave the submit button disabled.
    editOwnRegMutation.isSuccess ||
    editOthersRegMutation.isSuccess;
  const is_discard_disabled =
    editOwnRegMutation.isPending ||
    editOthersRegMutation.isPending ||
    // On mutation success, we navigate to another page. Leave the discard feature disabled.
    editOwnRegMutation.isSuccess ||
    editOthersRegMutation.isSuccess;
  const is_remove_disabled =
    !reg_allowed.is_reg_allowed ||
    editOwnRegMutation.isPending ||
    editOthersRegMutation.isPending ||
    // On mutation success, we navigate to another page. Leave the remove button disabled.
    editOwnRegMutation.isSuccess ||
    editOthersRegMutation.isSuccess;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    if (form_variant == RegFormVariant.edit_others) {
      editOthersRegMutation.mutate(
        {
          form: {did_attend: formData.did_attend} as EditOthersRegistrationForm,
          meeting_id: meeting_id,
          user_id: user_id,
        },
        {
          onSuccess: () =>
            navigate(`/meetings/${meeting_id}#registrants`, {
              state: {
                is_successful_edit_others_reg: true,
                registrant_name: full_name,
              } as ViewMeetingAlertState,
            }),
        }
      );
    } else {
      editOwnRegMutation.mutate(
        {form: formData, meeting_id: meeting_id},
        {
          onSuccess: () =>
            navigate(`/meetings/${meeting_id}`, {
              state: {is_successful_edit_reg: true} as ViewMeetingAlertState,
            }),
        }
      );
    }
  };

  const selected_format = useMemo(() => {
    if (formData.is_in_person_reg) return Format.in_person;
    if (formData.is_virtual_reg) return Format.virtual;
  }, [formData.is_in_person_reg, formData.is_virtual_reg]);

  const meeting_formats = useMemo(() => {
    const formats: Format[] = [];
    if (meeting_data.is_in_person) formats.push(Format.in_person);
    if (meeting_data.is_virtual) formats.push(Format.virtual);
    return formats;
  }, [meeting_data.is_in_person, meeting_data.is_virtual]);

  const is_format_invalid = useMemo(() => {
    const invalid_format_messages = Object.values(meeting_registration_format_validation_msg_dict);
    return validationMessages.some((validationMessage) =>
      invalid_format_messages.includes(validationMessage)
    );
  }, [validationMessages]);

  return (
    <section className="grid-container-tablet margin-bottom-10 padding-x-0">
      {editOwnRegMutation.isError && <FormGenericError error={editOwnRegMutation.error} />}
      {editOthersRegMutation.isError && <FormGenericError error={editOthersRegMutation.error} />}
      {!reg_allowed.is_reg_allowed && (
        <Alert type="info" headingLevel="h2" className="margin-bottom-2" slim>
          {meeting_registration_closed_msg}
        </Alert>
      )}
      <RegistrationHeading
        meeting_title={meeting_data.title}
        meeting_start_datetime={meeting_data.start_datetime}
        form_variant={form_variant}
        other_registrant_name={form_variant == RegFormVariant.edit_others ? full_name : undefined}
      />
      <FormLayout handleSubmit={handleSubmit}>
        <Grid className="grid-row grid-gap">
          <div
            className={`mobile-lg:grid-col-6 ${
              form_variant == RegFormVariant.edit_others ? 'margin-top-2' : 'margin-top-1'
            }`}
          >
            <RegistrationFormatRadio
              setFormData={setFormData}
              setValidationMessages={setValidationMessages}
              meeting_formats={meeting_formats}
              reg_allowed={reg_allowed}
              registrant_data={registrant_data}
              selected_format={selected_format}
              form_variant={form_variant}
            />
          </div>
          {form_variant == RegFormVariant.edit_others && (
            <div className="mobile-lg:grid-col-6">
              <div className="padding-2 bg-internal">
                <FormCheckbox
                  id_name="did_attend"
                  is_checked={formData.did_attend ?? false}
                  handleChange={handleCheckbox}
                  is_disabled={!privileges_data.can_edit_registrants}
                  form_type="registration"
                />
              </div>
            </div>
          )}
        </Grid>
        <section className="margin-top-4 mobile-lg:margin-top-4 bg-base-lightest padding-2">
          <Grid className="grid-row grid-gap margin-top-neg-3">
            <div className="mobile-lg:grid-col-6">
              <FormEmailInput
                id_name="email"
                input={registrant_data.email}
                handleChange={() => undefined}
                setValidationMessages={setValidationMessages}
                is_disabled
                form_type="account"
                is_required
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                id_name="first_name"
                input={formData.first_name}
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_required
                is_validate_length
                is_disabled={
                  form_variant == RegFormVariant.edit_others ||
                  !reg_allowed.is_reg_allowed ||
                  is_format_invalid
                }
                form_type="account"
              />
            </div>
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                id_name="last_name"
                input={formData.last_name}
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_required
                is_validate_length
                is_disabled={
                  form_variant == RegFormVariant.edit_others ||
                  !reg_allowed.is_reg_allowed ||
                  is_format_invalid
                }
                form_type="account"
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                input={formData.job_title}
                id_name="job_title"
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_validate_length
                is_disabled={
                  form_variant == RegFormVariant.edit_others ||
                  !reg_allowed.is_reg_allowed ||
                  is_format_invalid
                }
                form_type="account"
              />
            </div>
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                input={formData.org}
                id_name="org"
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_validate_length
                is_disabled={
                  form_variant == RegFormVariant.edit_others ||
                  !reg_allowed.is_reg_allowed ||
                  is_format_invalid
                }
                form_type="account"
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormPhoneInputLazy
                phone={formData.phone}
                handleChange={handlePhoneChange}
                setValidationMessages={setValidationMessages}
                is_disabled={
                  form_variant == RegFormVariant.edit_others ||
                  !reg_allowed.is_reg_allowed ||
                  is_format_invalid
                }
              />
            </div>
          </Grid>
          <p className="text-italic font-sans-xs margin-bottom-0">
            Changes made using this form only update your registration for the indicated meeting.
            They will not affect your account or other registrations.
          </p>
        </section>
        <FormTextArea
          input={formData.comments}
          id_name="comments"
          handleChange={handleTextAreaChange}
          setValidationMessages={setValidationMessages}
          is_validate_length
          is_disabled={
            form_variant == RegFormVariant.edit_others ||
            !reg_allowed.is_reg_allowed ||
            is_format_invalid
          }
          form_type="registration"
        />
        {!!meeting_data.cr_questions.length && (
          <CustomRegistrationQuestions
            cr_questions={meeting_data.cr_questions}
            form_variant={form_variant}
            recorded_cr_responses={registrant_data.cr_responses}
            disable_all={
              form_variant == RegFormVariant.edit_others ||
              !reg_allowed.is_reg_allowed ||
              is_format_invalid
            }
            formData={formData}
            setFormData={setFormData}
            setValidationMessages={setValidationMessages}
          />
        )}
        {(form_variant == RegFormVariant.edit || privileges_data.can_edit_registrants) && (
          <BottomContainer>
            <div className="grid-container-tablet padding-x-0 grid-row flex-justify-end">
              {form_variant == RegFormVariant.edit && (
                <div className="flex-grow">
                  <RemoveRegistrationButton
                    meeting_id={meeting_id}
                    meeting_title={meeting_data.title}
                    disabled={is_remove_disabled}
                    disabled_reason={
                      !reg_allowed.is_reg_allowed ? meeting_registration_closed_msg : undefined
                    }
                  />
                </div>
              )}
              <div>
                <FormSubmit
                  validationMessages={
                    form_variant == RegFormVariant.edit_others ? [] : validationMessages
                  }
                  is_disabled={is_submit_disabled}
                  ref={submit_btn_ref}
                >
                  Save changes
                </FormSubmit>
              </div>
            </div>
          </BottomContainer>
        )}
        <DiscardChanges
          isFormDirty={isFormDirty}
          disable_nav_blocker={is_discard_disabled}
          visibility="none"
        />
      </FormLayout>
      {privileges_data.can_view_registrants && (
        <section className="margin-top-4">
          <AdditionalInformation query_data={registrant_data} info_type="registrant" />
        </section>
      )}
    </section>
  );
}
