import {createContext, useContext} from 'react';
import {useQueryPrivileges} from '../services/account-queries';
import {AccountPrivilegesGetData} from '../../common/types/account-privileges-get-res';

interface Props {
  children: React.ReactNode;
}

const PrivilegesContext = createContext<AccountPrivilegesGetData | undefined>(undefined);

export default function PrivilegesProvider({children}: Props) {
  const query = useQueryPrivileges({});

  return <PrivilegesContext.Provider value={query.data}>{children}</PrivilegesContext.Provider>;
}

export function usePrivilegesContext() {
  const privileges = useContext(PrivilegesContext);
  if (!privileges) {
    // If this err is hit, you've tried to use this context
    // without guarding the parent component with a api/privilege call first.
    // Used an ERR_CODE in case somehow this err sneaks into user-facing UI
    throw new Error('ERR_CODE: 1');
  }
  return privileges;
}
