import React, {Component, ErrorInfo, ReactNode} from 'react';
import {ModalSwitchTypes} from '../../../types/auth-types';

interface Props {
  children?: ReactNode;
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
}

interface State {
  hasError: boolean;
}

class AuthErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (g_is_dev) console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      this.props.setCurrentModal('error');
    }

    return this.props.children;
  }
}

export default AuthErrorBoundary;
