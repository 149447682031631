import {Button, ButtonGroup, Checkbox, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import {useState} from 'react';
import {MeetingsGetByIdData} from '../../../../common/types/meetings-get-by-id-res';
import {useMutationDeleteMeeting} from '../../../services/meeting-queries';
import {useNavigate} from 'react-router-dom';
import {MeetingsListAlertState} from '../../../types/meeting-types';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';
import {mtg_delete_err} from '../../../lib/utils';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';

interface Props {
  title: MeetingsGetByIdData['title'];
  pk: string;
}

export default function DeleteMeetingModal({title, pk}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWarnChecked, setIsWarnChecked] = useState(false);
  const navigate = useNavigate();
  const mutation = useMutationDeleteMeeting();

  function handleClose() {
    mutation.reset();
    setIsModalOpen(false);
    setIsWarnChecked(false);
  }

  function handleClick() {
    mutation.mutate(pk, {
      onSuccess: () => navigate('/', {state: {is_mtg_deleted: true} as MeetingsListAlertState}),
    });
  }

  return (
    <>
      <Button type="button" className="usa-button--secondary" onClick={() => setIsModalOpen(true)}>
        Delete meeting
      </Button>
      <GenericModalLayout
        isOpen={isModalOpen}
        closeModal={handleClose}
        modal_heading={`Delete ${title}`}
      >
        <div className="usa-prose">
          Are you sure you want to delete this meeting? If there are any registrants on this
          meeting, consider sending an email broadcast before the meeting is deleted.{' '}
          <span className="text-secondary">
            A broadcast email for this meeting cannot be sent after the meeting is deleted.
          </span>
          <Checkbox
            id="confirm-broadcast-warning"
            name="confirm-broadcast-warning"
            label="I understand that broadcast email should be sent prior to meeting deletion."
            className="margin-y-3"
            checked={isWarnChecked}
            onChange={(event) => setIsWarnChecked(event.currentTarget.checked)}
          />
        </div>
        {mutation.isError && <ErrorAlert>{mtg_delete_err}</ErrorAlert>}
        <ModalFooter>
          <ButtonGroup
            type="default"
            className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
          >
            <Button
              type="button"
              className="usa-button--secondary"
              disabled={!isWarnChecked || mutation.isPending}
              onClick={handleClick}
            >
              {mutation.isPending ? <TextLoading /> : 'Delete meeting'}
            </Button>
            <Button type="button" onClick={handleClose} disabled={mutation.isPending} unstyled>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </GenericModalLayout>
    </>
  );
}
