import {useEffect, useRef, useState} from 'react';
import {
  FilesInputDict,
  MeetingEditPk,
  MeetingEditQuery,
  MeetingForm,
  MtgFormCRQuestion,
  MtgFormCRQuestionRadio,
  MtgFormCRQuestionTextArea,
  MtgFormFile,
} from '../../types/meeting-types';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import {
  RichTextFields,
  debounce_delay_ms,
  rich_text_fields,
  handleTypedInputChange,
  handleTypedRtfChange,
  time_title,
  isInMeetingFormKeys,
} from '../../lib/utils';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import MeetingFormat from '../../components/FormComponents/MeetingFormComponents/MeetingFormat/MeetingFormat';
import FormDatetimeRange from '../../components/FormComponents/FormDatetimeRange/FormDatetimeRange';
import MeetingStatus from '../../components/FormComponents/MeetingFormComponents/MeetingStatus/MeetingStatus';
import {StatusesGetData} from '../../../common/types/statuses-get-res';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {useMutationCreateMeeting, useMutationEditMeeting} from '../../services/meeting-queries';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import {useNavigate} from 'react-router-dom';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import EmailsInput from '../../components/FormComponents/MeetingFormComponents/EmailsInput/EmailsInput';
import InlineAlert from '../../components/FormComponents/InlineAlert/InlineAlert';
import metadata_client_meeting from '../../lib/metadata-client-meeting.json';
import PrevTimeInfo from '../../components/FormComponents/MeetingFormComponents/PrevTimeInfo/PrevTimeInfo';
import AdditionalInformation from '../../components/AccountComponents/AdditionalInformation/AdditionalInformation';
import styles from './CreateEditMeeting.module.scss';
import CustomRegQuestions from '../../components/MeetingComponents/CustomRegQuestions/CustomRegQuestions';
import MeetingFiles from '../../components/MeetingComponents/MeetingFiles/MeetingFiles';
import {MtgFileCategoriesGetResCategory} from '../../../common/types/meeting-file-categories-get-res';
import {v4 as uuidV4} from 'uuid';
import {MeetingsPostPutData} from '../../../common/types/meetings-post-put-res';
import UploadFiles from '../../components/MeetingComponents/UploadFiles/UploadFiles';
import SectionTitle from '../../components/FormComponents/SectionTitle/SectionTitle';
import isEqual from 'lodash/isEqual';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';

type Props = {
  statuses_data: StatusesGetData;
  file_categories: MtgFileCategoriesGetResCategory[];
} & MeetingEditQuery &
  MeetingEditPk;

export default function CreateEditMeeting({
  query_data,
  statuses_data,
  file_categories,
  is_edit_mtg,
  pk,
  reloadMeeting,
}: Props) {
  usePageTitle(is_edit_mtg ? query_data.title : 'Create new meeting');

  const initial_status = statuses_data.find(
    (status) => status.name == (is_edit_mtg ? query_data.status.name : 'draft')
  );
  if (!initial_status) throw new Error('Queried status is invalid');

  const [formData, setFormData] = useState<MeetingForm>(() => {
    return {
      title: query_data?.title || '',
      status_fk: initial_status.pk,
      confirmation_email_content: query_data?.confirmation_email_content || '',
      cc_emails: query_data?.cc_emails ? query_data.cc_emails.join(', ') : '',
      banner: query_data?.banner || '',
      summary: query_data?.summary || '',
      addtl_info: query_data?.addtl_info || '',
      docket_number: query_data?.docket_number || '',
      agenda: query_data?.agenda || '',
      results: query_data?.results || '',
      req_info: query_data?.req_info || '',
      editor_notes: query_data?.editor_notes || '',
      start_datetime: query_data?.start_datetime || '',
      end_datetime: query_data?.end_datetime || '',
      is_in_person: query_data?.is_in_person || false,
      in_person_address: query_data?.in_person_address || '',
      in_person_info: query_data?.in_person_info || '',
      in_person_capacity: query_data?.in_person_capacity
        ? query_data.in_person_capacity.toString()
        : '',
      in_person_reg_end_datetime: query_data?.in_person_reg_end_datetime || '',
      is_virtual: query_data?.is_virtual || false,
      virtual_link: query_data?.virtual_link || '',
      virtual_info: query_data?.virtual_info || '',
      virtual_capacity: query_data?.virtual_capacity ? query_data.virtual_capacity.toString() : '',
      virtual_reg_end_datetime: query_data?.virtual_reg_end_datetime || '',
    };
  });
  const [crqs, setCrqs] = useState<MtgFormCRQuestion[]>(() => {
    let output: MtgFormCRQuestion[] = [];
    if (is_edit_mtg) {
      output = query_data.cr_questions.map((crq) => {
        if (crq.type == 'textarea') {
          const crq_textarea: MtgFormCRQuestionTextArea = {
            ...crq,
            is_removed: false,
            local_id: uuidV4(),
          };
          return crq_textarea;
        } else {
          const crq_radio: MtgFormCRQuestionRadio = {
            ...crq,
            cr_options: crq.cr_options.map((cro) => ({
              ...cro,
              is_removed: false,
              local_id: uuidV4(),
            })),
            is_removed: false,
            local_id: uuidV4(),
          };
          return crq_radio;
        }
      });
    }
    return output;
  });
  const [files, setFiles] = useState<MtgFormFile[]>(() => {
    let output: MtgFormFile[] = [];
    if (is_edit_mtg) {
      output = query_data.files.map<MtgFormFile>((file) => ({
        pk: file.pk,
        category_pk: file.category?.pk ?? '',
        file_name: null,
        title: file.title,
        date: file.date,
        order: file.order,
        summary: file.summary ?? '',
        editor_notes: file.editor_notes ?? '',
        is_hidden: file.is_hidden ?? false,
        is_removed: false,
        upload_id: uuidV4(),
        local_id: uuidV4(),
        original_file_name: null,
        signature_mismatch: false,
        uploaded_file_name: file.file_name,
      }));
    }
    return output;
  });

  const [initialForm] = useState({formData, crqs, files});
  const isFormDirty = () => !isEqual(initialForm, {formData, crqs, files});

  // Maintain a dictionary that relates upload_id to file <input>
  const file_inputs_ref = useRef<FilesInputDict>({});

  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const editMutation = useMutationEditMeeting();
  const createMutation = useMutationCreateMeeting();
  const [uploadFilesData, setUploadFilesData] = useState<MeetingsPostPutData | null>(null);
  const navigate = useNavigate();

  const handleInputChange = handleTypedInputChange(setFormData);
  const handleRtfInputChange = handleTypedRtfChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);
  const handleDatetimeRangeChange = (key: string, value: string | null) => {
    if (!isInMeetingFormKeys(['start_datetime', 'end_datetime'], key)) return;
    setFormData((prev) => {
      const new_state = {...prev};
      new_state[key] = value;
      return new_state;
    });
  };

  const curr_status = statuses_data.find((status) => status.pk == formData.status_fk);
  // This error should not be reached because the statuses are selected via radio buttons
  if (!curr_status) throw new Error(`Selected Status ${formData.status_fk} is not found.`);

  const is_submit_disabled =
    !!validationMessages.length ||
    createMutation.isPending ||
    editMutation.isPending ||
    // On mutation success, file uploads begin (if any). Leave the submit button disabled.
    createMutation.isSuccess ||
    editMutation.isSuccess;
  const is_discard_disabled =
    createMutation.isPending ||
    editMutation.isPending ||
    // On mutation success, file uploads begin (if any). Leave the discard button disabled.
    createMutation.isSuccess ||
    editMutation.isSuccess;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    if (is_edit_mtg) {
      editMutation.mutate(
        {mtg_form: formData, meeting_id: pk, form_crqs: crqs, form_files: files},
        {onSuccess: setUploadFilesData}
      );
    } else {
      createMutation.mutate(
        {mtg_form: formData, form_crqs: crqs, form_files: files},
        {onSuccess: setUploadFilesData}
      );
    }
  };

  useEffect(() => {
    if (curr_status.name == 'postponed') {
      setFormData((prevForm) => ({...prevForm, start_datetime: null, end_datetime: null}));
    }
  }, [curr_status.name, setFormData]);

  return (
    <div className="margin-bottom-15 mobile-lg:margin-bottom-10">
      {createMutation.isError && <FormGenericError error={createMutation.error} />}
      {editMutation.isError && <FormGenericError error={editMutation.error} />}
      <FormLayout handleSubmit={handleSubmit}>
        <div className="margin-top-neg-3">
          <FormTextInput
            id_name="title"
            input={formData.title}
            handleChange={handleInputChange}
            setValidationMessages={setValidationMessages}
            is_required
            is_validate_length
            form_type="meeting"
            use_semi_uncontrolled
            debounce_change_ms={debounce_delay_ms}
          />
        </div>
        <MeetingStatus
          mtg_title={formData.title}
          statuses_data={statuses_data}
          selected_status_fk={formData.status_fk}
          is_initial_unlisted={is_edit_mtg && initial_status.is_unlisted}
          setFormData={setFormData}
          setValidationMessages={setValidationMessages}
          is_edit_mtg={!!is_edit_mtg}
        />
        {curr_status.name == 'postponed' ? (
          <section className="margin-top-3">
            <SectionTitle>{time_title}</SectionTitle>
            <InlineAlert>
              Meeting start and end dates are not available for Postponed meetings. To create a
              meeting with start and end dates, select a different meeting status.
            </InlineAlert>
          </section>
        ) : (
          <FormDatetimeRange
            range_title={time_title}
            id_name_1="start_datetime"
            id_name_2="end_datetime"
            metadata_1={metadata_client_meeting.start_datetime}
            metadata_2={metadata_client_meeting.end_datetime}
            handleChange={handleDatetimeRangeChange}
            setValidationMessages={setValidationMessages}
            default_value_1={
              formData.start_datetime ? {iso_datetime: formData.start_datetime} : undefined
            }
            default_value_2={
              formData.end_datetime ? {iso_datetime: formData.end_datetime} : undefined
            }
            enforce_order
            is_required={curr_status.is_date_required}
          >
            {is_edit_mtg && (
              <PrevTimeInfo
                query_data={query_data}
                formData={formData}
                id_name="start_datetime"
                id_name_2="end_datetime"
                is_initial_unlisted={initial_status.is_unlisted}
              />
            )}
          </FormDatetimeRange>
        )}
        {is_edit_mtg ? (
          <MeetingFormat
            query_data={query_data}
            formData={formData}
            setFormData={setFormData}
            setValidationMessages={setValidationMessages}
            is_date_required={curr_status.is_date_required}
            is_format_required={curr_status.is_format_required}
            is_edit_mtg
            is_initial_unlisted={initial_status.is_unlisted}
          />
        ) : (
          <MeetingFormat
            formData={formData}
            setFormData={setFormData}
            setValidationMessages={setValidationMessages}
            is_date_required={curr_status.is_date_required}
            is_format_required={curr_status.is_format_required}
          />
        )}
        <FormTextArea
          id_name="banner"
          input={formData.banner}
          handleChange={handleRtfInputChange}
          setValidationMessages={setValidationMessages}
          is_validate_length
          include_desc
          form_type="meeting"
          is_rtf
        />
        <CustomRegQuestions
          crqs={crqs}
          setCrqs={setCrqs}
          setValidationMessages={setValidationMessages}
          is_edit_mtg={is_edit_mtg}
        />
        {rich_text_fields.map((field) => {
          return (
            <FormTextArea
              key={field}
              id_name={field}
              input={formData[field as keyof RichTextFields]}
              handleChange={handleRtfInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="meeting"
              is_rtf
            />
          );
        })}
        <MeetingFiles
          files={files}
          setFiles={setFiles}
          categories={file_categories}
          file_inputs_ref={file_inputs_ref}
          setValidationMessages={setValidationMessages}
          is_edit_mtg={is_edit_mtg}
        />
        {is_edit_mtg ? (
          <UploadFiles
            is_edit_mtg
            reloadMeeting={reloadMeeting}
            form_files={files}
            mtg_data={uploadFilesData}
            file_inputs_dict={file_inputs_ref.current}
          />
        ) : (
          <UploadFiles
            form_files={files}
            mtg_data={uploadFilesData}
            file_inputs_dict={file_inputs_ref.current}
          />
        )}
        <section className="margin-top-4 mobile-lg:margin-top-5 bg-internal padding-2">
          <div className="margin-top-neg-3">
            <FormTextInput
              id_name="req_info"
              input={formData.req_info}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              include_desc
              form_type="meeting"
              use_semi_uncontrolled
              debounce_change_ms={debounce_delay_ms}
            />
          </div>
          <EmailsInput
            id_name="cc_emails"
            input={formData.cc_emails}
            handleChange={handleTextAreaChange}
            setValidationMessages={setValidationMessages}
            form_type="meeting"
            use_semi_uncontrolled
            debounce_change_ms={debounce_delay_ms}
          />
          <FormTextArea
            id_name="confirmation_email_content"
            input={formData.confirmation_email_content}
            handleChange={handleTextAreaChange}
            setValidationMessages={setValidationMessages}
            is_validate_length
            include_desc
            form_type="meeting"
            use_semi_uncontrolled
            debounce_change_ms={debounce_delay_ms}
          />
          <FormTextArea
            id_name="editor_notes"
            input={formData.editor_notes}
            handleChange={handleTextAreaChange}
            setValidationMessages={setValidationMessages}
            is_validate_length
            include_desc
            form_type="meeting"
            use_semi_uncontrolled
            debounce_change_ms={debounce_delay_ms}
          />
        </section>
        <BottomContainer>
          <div className={`grid-row flex-justify-end ${styles.buttons_container}`}>
            <DiscardChanges
              onConfirm={() => navigate(is_edit_mtg ? `/meetings/${pk}` : '/')}
              isFormDirty={isFormDirty}
              disable_nav_blocker={is_discard_disabled}
              aria-disabled={is_discard_disabled}
              className="usa-button usa-button--discard-changes margin-top-0-important margin-right-2"
            />
            <div className="width-full mobile-lg:width-auto margin-bottom-105 mobile-lg:margin-y-0">
              <FormSubmit
                validationMessages={validationMessages}
                is_disabled={is_submit_disabled}
                ref={submit_btn_ref}
              >
                {is_edit_mtg ? 'Save changes' : 'Create new meeting'}
              </FormSubmit>
            </div>
          </div>
        </BottomContainer>
      </FormLayout>
      {is_edit_mtg && (
        <section className="margin-top-4">
          <AdditionalInformation query_data={{pk: pk, ...query_data}} info_type="meeting" />
        </section>
      )}
    </div>
  );
}
