import {Fieldset, Form} from '@trussworks/react-uswds';
import {HandleSubmit} from '../../types/form-types';

interface Props {
  handleSubmit: HandleSubmit;
  children: React.ReactNode;
  className?: string;
}

export default function FormLayout({handleSubmit, children, className}: Props) {
  return (
    <Form
      onSubmit={handleSubmit}
      className={`maxw-none${className ? ` ${className}` : ''}`}
      noValidate
    >
      <Fieldset className="width-full">{children}</Fieldset>
    </Form>
  );
}
