import {ModalHeading} from '@trussworks/react-uswds';
import Modal from 'react-modal';

// Binds modal to appElement for accessibility purposes: https://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement(document.getElementById('root') as HTMLElement);

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  modal_heading: string;
  children: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
}

export default function GenericModalLayout({
  isOpen,
  closeModal,
  modal_heading,
  children,
  shouldCloseOnOverlayClick,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel={modal_heading}
      overlayClassName="usa-modal-overlay display-flex flex-justify-center flex-align-center z-top"
      className="bg-white maxw-mobile-lg padding-4 radius-lg overflow-y-auto maxhw-modal"
    >
      <ModalHeading className="word-break-word">{modal_heading}</ModalHeading>
      {children}
    </Modal>
  );
}
