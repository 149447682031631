import {useNavigate} from 'react-router-dom';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import {logAndGetErrMessage} from '../../../lib/utils';
import {useQueryMyAccount, useQueryPrivileges} from '../../../services/account-queries';
import LoginError from '../../LoginError/LoginError';
import NotFound from '../../NotFound/NotFound';
import ConfirmAccount from '../ConfirmAccount';
import {useEffect, useState} from 'react';
// When date_string is in the component, it gets affected by the re-render
const date_string = Date.now().toString();

export default function ConfirmAccountWrapper() {
  // Passing in date_string as key for both of these queries to uniquely identify the query and trigger
  // re-running of these queries in other components if keys don't match
  const account_query = useQueryMyAccount(date_string);
  const privileges_query = useQueryPrivileges({key: date_string});
  const [canRedirect, setCanRedirect] = useState(true);
  const navigate = useNavigate();

  // If user is already confirmed, send them to /my-account. This is a one-shot check. We do not want this wrapper
  // hijacking navigation once inside <ConfirmAccount>.
  useEffect(() => {
    if (!canRedirect || privileges_query.isPending) return;
    if (privileges_query.isSuccess && privileges_query.data.is_verified) {
      navigate('/my-account');
    }
    setCanRedirect(false);
  }, [canRedirect, setCanRedirect, privileges_query, navigate]);

  if (account_query.isPending || privileges_query.isPending) {
    return <PageLoading />;
  }

  if (privileges_query.isError) {
    return <NotFound />;
  }

  if (account_query.isError) {
    logAndGetErrMessage(account_query.error);
    return <LoginError />;
  }

  if (canRedirect && privileges_query.data.is_verified) {
    return <PageLoading />;
  }

  return <ConfirmAccount query_data={account_query.data} />;
}
