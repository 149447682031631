import {useEffect, useState} from 'react';
import {CRQuestionRadio} from '../../../../../common/types/meetings-get-by-id-res';
import {Alert, Radio} from '@trussworks/react-uswds';
import {RegistrationCRResponse} from '../../../../types/registration-types';
import {SetValidationMessages} from '../../../../types/form-types';
import {RegFormVariant, addOrRemoveValidationMessage} from '../../../../lib/utils';
import InlineAlert from '../../InlineAlert/InlineAlert';

interface Props {
  crq: CRQuestionRadio;
  form_variant: RegFormVariant;
  form_response: string | null;
  recorded_response?: string | null;
  is_disabled?: boolean;
  updateCRFormData: (crq_id: string, responses: RegistrationCRResponse['responses']) => void;
  setValidationMessages: SetValidationMessages;
}

export default function CustomRegistrationRadio({
  crq,
  form_variant,
  form_response,
  recorded_response,
  is_disabled,
  updateCRFormData,
  setValidationMessages,
}: Props) {
  const [invalidReason, setInvalidReason] = useState('');

  // Only show one of alert or inline validation message below the question title. The alert displays a notice that
  // available options have changed for a question (i.e. an organizer removed an option) and it would be redundant to
  // also display a validation notice that the recorded response is not valid. The alert is dismissed as soon as a valid
  // option is selected for the question and validation messages take over (if applicable). It is possible to submit the
  // form with an alert visible for an optional question; the response will be updated to `null`.
  const is_recorded_response_removed =
    !!recorded_response && !crq.cr_options.find((cro) => cro.pk == recorded_response);
  const [preferAlert, setPreferAlert] = useState(is_recorded_response_removed);

  const question_num = crq.order + 1;

  useEffect(() => {
    const {is_required, cr_options} = crq;

    // Only one inline reason displays at a time.
    let invalid_reason = '';

    const required_question_unanswered = is_required && !form_response;
    invalid_reason ||= required_question_unanswered
      ? 'This is a required question. Please select an option.'
      : '';
    addOrRemoveValidationMessage({
      is_condition_met: required_question_unanswered,
      err_message: `A response is required for additional question ${question_num}`,
      setValidationMessages,
    });

    const selected_cro = cr_options.find((cro) => cro.pk == form_response);
    const invalid_option_selected =
      !!form_response &&
      (!selected_cro ||
        (selected_cro.is_disabled &&
          (form_variant == RegFormVariant.create || form_response != recorded_response)));
    invalid_reason ||= invalid_option_selected ? 'Please select a valid option.' : '';
    addOrRemoveValidationMessage({
      is_condition_met: invalid_option_selected,
      err_message: `Please select a valid response for additional question ${question_num}`,
      setValidationMessages,
    });

    if (!!form_response && !invalid_option_selected) {
      setPreferAlert(false);
    }
    setInvalidReason(invalid_reason);
  }, [crq, question_num, form_variant, form_response, recorded_response, setValidationMessages]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = event.currentTarget;
    updateCRFormData(crq.pk, [checked ? value : null]);
  };

  return (
    <div>
      <p className={`margin-bottom-0 word-break-word ${crq.order == 0 ? 'margin-top-05' : ''}`}>
        {`${question_num}. ${crq.title}`}
        {crq.is_required && ' *'}
      </p>
      {preferAlert && (
        <Alert type="warning" headingLevel="h4" className="margin-top-05" slim noIcon>
          {crq.is_required
            ? 'Available options have changed, please select a new response.'
            : 'Available options have changed, you may select a new response.'}
        </Alert>
      )}
      {!preferAlert && invalidReason && <InlineAlert>{invalidReason}</InlineAlert>}
      {crq.cr_options.map((cro) => (
        <div key={cro.pk} className="margin-left-2">
          {
            // If an option has been disabled by organizers, it should still be enabled for registrants that selected
            // it during registration. It's a similar story for the "disabled note".
            // If a question is optional, allow unselecting the response.
          }
          <Radio
            type={crq.is_required ? 'radio' : 'checkbox'}
            id={`cro-${cro.pk}`}
            name={`crq-${crq.pk}`}
            value={cro.pk}
            label={
              cro.is_disabled && recorded_response != cro.pk && cro.disabled_note
                ? `(${cro.disabled_note}) ${cro.title}`
                : cro.title
            }
            checked={cro.pk == form_response}
            onChange={handleChange}
            className="display-inline-block word-break-word"
            disabled={is_disabled || (cro.is_disabled && recorded_response != cro.pk)}
          />
        </div>
      ))}
    </div>
  );
}
