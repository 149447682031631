import {useState, useEffect, RefObject} from 'react';
import {ModalToggleButton, Modal, ModalRef} from '@trussworks/react-uswds';
import {ModalSwitchTypes, UserNavTypes} from '../../types/auth-types';
import AuthIndex from './AuthIndex/AuthIndex';
import Auth from './Auth/Auth';
import EmailSent from './EmailSent/EmailSent';
import AuthError from './AuthError/AuthError';
import AuthErrorBoundary from './AuthErrorBoundary/AuthErrorBoundary';
import styles from './AuthModal.module.scss';
import LegalNotice from './LegalNotice/LegalNotice';

interface Props {
  authModalRef: RefObject<ModalRef>;
}

let active_document_listener: ((event: MouseEvent) => void) | null = null;

export default function AuthModal({authModalRef}: Props) {
  const [currentModal, setCurrentModal] = useState<ModalSwitchTypes>(null);
  const [recipientEmail, setRecipientEmail] = useState('');
  const [userNav, setUserNav] = useState<UserNavTypes>(null);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    // In development, this component mounts twice. Ensure only one listener is attached to the body.
    if (active_document_listener) {
      document.removeEventListener('click', active_document_listener);
    }

    const document_listener = function (event: MouseEvent) {
      const modal_opener =
        event.target instanceof HTMLElement
          ? event.target.closest<HTMLElement>('.open_auth_modal')
          : null;
      if (!modal_opener) {
        return;
      }

      // Reset the state to the initial Auth component whenever the modal open button is clicked.
      setCurrentModal(null);
      setRedirect(modal_opener.dataset.redirect || '');

      // Replace state in history with blank state when modal is opened
      window.history.replaceState({}, document.title);

      // Adding this class to the body ensures that the modal doesn't close when user clicks outside
      // by disabling any clicks outside of the modal.
      const {body} = document;
      body.classList.add('usa-js-no-click');
    };

    // Apply listener to the document so that dynamically added buttons are handled.
    document.addEventListener('click', document_listener);
    active_document_listener = document_listener;
  }, []);

  const renderSwitch = (state: ModalSwitchTypes) => {
    switch (state) {
      case 'auth':
        return (
          <Auth
            setCurrentModal={setCurrentModal}
            userNav={userNav}
            setRecipientEmail={setRecipientEmail}
            redirect={redirect}
          />
        );
      case 'auth-index':
        return (
          <AuthIndex
            setCurrentModal={setCurrentModal}
            setUserNav={setUserNav}
            redirect={redirect}
          />
        );
      case 'email-sent':
        return <EmailSent setCurrentModal={setCurrentModal} recipientEmail={recipientEmail} />;
      case 'error':
        return <AuthError setCurrentModal={setCurrentModal} />;
      case 'piv-error':
        return <AuthError setCurrentModal={setCurrentModal} is_piv />;
      case 'legal-notice-error':
        return <AuthError setCurrentModal={setCurrentModal} is_legal_notice />;
      default:
        return <LegalNotice authModalRef={authModalRef} setCurrentModal={setCurrentModal} />;
    }
  };

  return (
    <>
      <ModalToggleButton
        id="nav-auth-toggle-button"
        modalRef={authModalRef}
        aria-controls="authentication-modal"
        className="open_auth_modal usa-button--secondary margin-0 width-full"
        opener
      >
        Sign in
      </ModalToggleButton>
      <AuthErrorBoundary setCurrentModal={setCurrentModal}>
        <Modal
          ref={authModalRef}
          aria-labelledby="nav-auth-toggle-button"
          aria-describedby="auth-desc"
          id="authentication-modal"
          className={`${styles.modal} padding-bottom-3`}
        >
          <p id="auth-desc" className="sr-only">
            Sign in or create an account.
          </p>
          {renderSwitch(currentModal)}
        </Modal>
      </AuthErrorBoundary>
    </>
  );
}
