import {Link} from 'react-router-dom';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {visit_homepage_title} from '../../lib/utils';

export default function NotFound() {
  usePageTitle('Not found');
  return (
    <div>
      <h2>Page not found</h2>

      <p className="usa-intro">
        We can&#39;t find the page you&#39;re looking for. It might have been removed, changed its
        name, or is otherwise unavailable.
      </p>

      <Link to="/" className="usa-button margin-top-1">
        {visit_homepage_title}
      </Link>
    </div>
  );
}
