import {Radio} from '@trussworks/react-uswds';
import metadata from '../../../lib/metadata-client-registration.json';
import {RegistrationForm} from '../../../types/registration-types';
import {SetValidationMessages} from '../../../types/form-types';
import InlineAlert from '../InlineAlert/InlineAlert';
import {useEffect, useMemo} from 'react';
import {
  RegFormVariant,
  addOrRemoveValidationMessage,
  meeting_registration_format_validation_msg_dict as validation_msg_dict,
} from '../../../lib/utils';
import SectionTitle from '../SectionTitle/SectionTitle';

export enum Format {
  in_person = 'in_person',
  virtual = 'virtual',
}

export type RegAllowed = {
  is_reg_allowed: boolean;
  is_in_person_reg_allowed: boolean;
  is_virtual_reg_allowed: boolean;
};

function validate(
  meeting_formats: Format[],
  reg_allowed: RegAllowed,
  registrant_data: {is_in_person_reg: boolean; is_virtual_reg: boolean} | undefined,
  selected_format: Format | undefined,
  form_variant: RegFormVariant
) {
  const summary = {
    is_valid: true,
    err_message: '',
  };

  const {is_reg_allowed, is_in_person_reg_allowed, is_virtual_reg_allowed} = reg_allowed;

  if (
    !is_reg_allowed &&
    (form_variant == RegFormVariant.create || form_variant == RegFormVariant.edit)
  ) {
    // No need for validation because a "registration is closed" banner appears and all form components are disabled.
    summary.is_valid = true;
  } else if (!selected_format) {
    summary.is_valid = false;
    summary.err_message = validation_msg_dict.select_attendance_plan;
  } else if (
    registrant_data?.is_in_person_reg &&
    !meeting_formats.includes(Format.in_person) &&
    selected_format == Format.in_person
  ) {
    summary.is_valid = false;
    summary.err_message =
      form_variant == RegFormVariant.edit_others
        ? validation_msg_dict.others_format_changed_to_virtual
        : validation_msg_dict.format_changed_to_virtual;
  } else if (
    registrant_data?.is_virtual_reg &&
    !meeting_formats.includes(Format.virtual) &&
    selected_format == Format.virtual
  ) {
    summary.is_valid = false;
    summary.err_message =
      form_variant == RegFormVariant.edit_others
        ? validation_msg_dict.others_format_changed_to_in_person
        : validation_msg_dict.format_changed_to_in_person;
  } else if (
    form_variant == RegFormVariant.edit &&
    registrant_data?.is_in_person_reg &&
    selected_format == Format.in_person &&
    !is_in_person_reg_allowed
  ) {
    summary.is_valid = false;
    summary.err_message = validation_msg_dict.reg_ended_in_person;
  } else if (
    form_variant == RegFormVariant.edit &&
    registrant_data?.is_virtual_reg &&
    selected_format == Format.virtual &&
    !is_virtual_reg_allowed
  ) {
    summary.is_valid = false;
    summary.err_message = validation_msg_dict.reg_ended_virtual;
  }

  return summary;
}

interface Props {
  setFormData: React.Dispatch<React.SetStateAction<RegistrationForm>>;
  setValidationMessages: SetValidationMessages;
  meeting_formats: Format[];
  reg_allowed: RegAllowed;
  registrant_data?: {is_in_person_reg: boolean; is_virtual_reg: boolean};
  selected_format?: Format;
  form_variant: RegFormVariant;
}

export default function MeetingRegFormatRadio({
  setFormData,
  setValidationMessages,
  meeting_formats,
  reg_allowed,
  registrant_data,
  selected_format,
  form_variant,
}: Props) {
  const format_validation = useMemo(
    () => validate(meeting_formats, reg_allowed, registrant_data, selected_format, form_variant),
    [meeting_formats, reg_allowed, registrant_data, selected_format, form_variant]
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const format = event.target.id;

    setFormData((prevForm) => {
      return {
        ...prevForm,
        is_in_person_reg: format == Format.in_person.valueOf(),
        is_virtual_reg: format == Format.virtual.valueOf(),
      };
    });
  }

  useEffect(() => {
    const err_messages = Object.values(validation_msg_dict);
    for (const err_message of err_messages) {
      addOrRemoveValidationMessage({
        is_condition_met:
          !format_validation.is_valid && format_validation.err_message == err_message,
        err_message: err_message,
        setValidationMessages,
      });
    }
  }, [format_validation, setValidationMessages]);

  const show_in_person_radio =
    reg_allowed.is_in_person_reg_allowed || registrant_data?.is_in_person_reg;
  const show_virtual_radio = reg_allowed.is_virtual_reg_allowed || registrant_data?.is_virtual_reg;

  return (
    <section>
      <SectionTitle>{`${metadata.format.title} *`}</SectionTitle>
      {!format_validation.is_valid && <InlineAlert>{format_validation.err_message}</InlineAlert>}
      <section className="grid-row margin-top-1">
        {show_in_person_radio && (
          <Radio
            id={Format.in_person}
            name="format"
            label="In-person"
            className={show_virtual_radio ? 'margin-right-2' : ''}
            checked={selected_format == Format.in_person}
            disabled={form_variant == RegFormVariant.edit_others || !reg_allowed.is_reg_allowed}
            onChange={handleChange}
            tile
          />
        )}
        {show_virtual_radio && (
          <Radio
            id={Format.virtual}
            name="format"
            label="Virtual"
            checked={selected_format == Format.virtual}
            disabled={form_variant == RegFormVariant.edit_others || !reg_allowed.is_reg_allowed}
            onChange={handleChange}
            tile
          />
        )}
      </section>
    </section>
  );
}
