import {Link} from 'react-router-dom';
import SupportEmailLink from '../../components/SupportEmailLink/SupportEmailLink';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {visit_homepage_title} from '../../lib/utils';

// UnknownError is meant for generic defense against render errors. It is intended to be used as the errorElement when a
// more specific element like NotFound or LoginError is not appropriate. Notably, the RootLayout will still attempt to
// render (e.g. for the navbar) and will only fall back to the RootErrorBoundary if an error occurs in RootLayout.

export default function UnknownError() {
  usePageTitle('Error');
  return (
    <div>
      <h2>An error was encountered</h2>
      <>
        <p className="usa-intro">
          An unexpected error was encountered on the page you were trying to reach.
        </p>
        <p>
          Please try again or reach out to <SupportEmailLink /> if the issue persists.
        </p>
      </>
      <Link to="/" className="usa-button margin-top-1">
        {visit_homepage_title}
      </Link>
    </div>
  );
}
