import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {Grid, Alert} from '@trussworks/react-uswds';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import {SharedForm} from '../../types/account-types';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import {useRef, useState} from 'react';
import {handleTypedInputChange, handleTypedPhoneChange} from '../../lib/utils';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import {useMutationConfirmAccount} from '../../services/account-queries';
import {AccountGetPutData} from '../../../common/types/account-get-put-res';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';

interface Props {
  query_data: AccountGetPutData;
}

export default function ConfirmAccount({query_data}: Props) {
  usePageTitle('Confirm account');
  const [formData, setFormData] = useState<SharedForm>({
    first_name: query_data.first_name || '',
    last_name: query_data.last_name || '',
    job_title: query_data.job_title || '',
    org: query_data.org || '',
    phone: query_data.phone || '',
  });
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const mutation = useMutationConfirmAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let redirect = searchParams.get('redirect') || '/';
  if (!/^\//.test(redirect)) {
    redirect = '/';
  }

  const is_submit_disabled =
    !!validationMessages.length ||
    mutation.isPending ||
    // On mutation success, we navigate to another page. Leave the submit button disabled.
    mutation.isSuccess;
  const is_discard_disabled =
    mutation.isPending ||
    // On mutation success, we navigate to another page. Leave the submit button disabled.
    mutation.isSuccess;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    mutation.mutate(formData, {
      onSuccess: () => navigate(redirect),
    });
  };

  const handleInputChange = handleTypedInputChange(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);

  return (
    <section className="grid-container-tablet margin-bottom-10 padding-x-0">
      {!!location.state && (
        <Alert headingLevel="h2" type="warning" slim noIcon>
          {location.state}
        </Alert>
      )}
      <h2>Complete account creation</h2>
      <p className="margin-y-1">Fill out personal information to complete your account creation.</p>
      <p className="margin-y-1 text-italic">
        You are creating an account with{' '}
        <span className="text-bold word-break-all">{query_data.email}</span>
      </p>
      <FormLayout handleSubmit={handleSubmit} className="margin-top-2">
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="first_name"
              input={formData.first_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="last_name"
              input={formData.last_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.job_title}
              id_name="job_title"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.org}
              id_name="org"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormPhoneInputLazy
              phone={formData.phone}
              handleChange={handlePhoneChange}
              setValidationMessages={setValidationMessages}
            />
          </div>
        </Grid>
        <div className="margin-top-3 mobile-lg:margin-top-4">
          <FormSubmit
            validationMessages={validationMessages}
            is_disabled={is_submit_disabled}
            is_full_width
            ref={submit_btn_ref}
          >
            Complete account creation
          </FormSubmit>
        </div>
        <DiscardChanges
          isFormDirty={() => true}
          disable_nav_blocker={is_discard_disabled}
          variant="confirm_acct"
          visibility="none"
        />
      </FormLayout>
    </section>
  );
}
