import {Link} from 'react-router-dom';
import styles from './MeetingsMenu.module.scss';
import {HandleSubMenu, IsSetMobileNavExpanded} from '../Navbar';
import {Icon} from '@trussworks/react-uswds';
import {getHostEnv} from '../../../../../common/utils/env-utils';

interface Props {
  name: string;
  isSubMenuOpen: boolean;
  handleSubMenu: HandleSubMenu;
  setIsMobileNavExpanded: IsSetMobileNavExpanded;
}

export default function MeetingsMenu({
  name,
  isSubMenuOpen,
  handleSubMenu,
  setIsMobileNavExpanded,
}: Props) {
  const handleMenuClick = () => {
    setIsMobileNavExpanded(false);
    handleSubMenu();
  };

  const host_env = getHostEnv(window.location.hostname);
  const archive_href = ['phmsa-prod', 'phmsa-stage', 'phmsa-uat'].includes(host_env)
    ? '/archive/Home.mtg.html'
    : 'https://primis-meetings.phmsa.dot.gov/archive/Home.mtg.html';

  return (
    <div className="nav__submenu_wrapper">
      <button
        className={`${styles.btn_wrapper} nav__hide_mobile ${
          isSubMenuOpen ? 'open' : ''
        } font-sans-md`}
        aria-expanded="false"
        aria-controls={name}
        onClick={handleSubMenu}
      >
        Meetings
        {isSubMenuOpen ? (
          <Icon.ArrowDropUp className="text-white" />
        ) : (
          <Icon.ArrowDropDown className="text-white" />
        )}
      </button>
      <ul
        id={name}
        className={`usa-nav__submenu nav__dropdown_menu ${styles.submenu} ${
          isSubMenuOpen ? 'open' : ''
        }`}
      >
        <li className="usa-nav__submenu-item nav__dropdown_menu_item">
          <a href={archive_href} onClick={handleMenuClick}>
            All archived meetings
          </a>
        </li>
        <li className="usa-nav__submenu-item nav__dropdown_menu_item">
          <Link to={'/'} onClick={handleMenuClick}>
            Current meetings
          </Link>
        </li>
      </ul>
    </div>
  );
}
