import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import {Tooltip} from 'react-tooltip';
import GenericModalLayout from '../../../../layouts/GenericModalLayout/GenericModalLayout';
import {useState} from 'react';
import {useMutationDeleteRegistrant} from '../../../../services/registrant-queries';
import {useNavigate} from 'react-router-dom';
import {ViewMeetingAlertState} from '../../../../types/meeting-types';
import TextLoading from '../../../LoadingComponents/TextLoading/TextLoading';
import styles from './RemoveRegistrationButton.module.scss';
import {reg_remove_err} from '../../../../lib/utils';
import ErrorAlert from '../../../ErrorAlert/ErrorAlert';

interface Props {
  meeting_title: string;
  meeting_id: string;
  disabled?: boolean;
  disabled_reason?: string;
}

export default function RemoveRegistrationButton({
  meeting_title,
  meeting_id,
  disabled,
  disabled_reason,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const mutation = useMutationDeleteRegistrant();

  function handleRemoveClick() {
    if (disabled) return;
    setIsModalOpen(true);
  }

  function handleDenied() {
    mutation.reset();
    setIsModalOpen(false);
  }

  function handleConfirmed() {
    mutation.mutate(meeting_id, {
      onSuccess: () =>
        navigate(`/meetings/${meeting_id}`, {
          state: {is_reg_deleted: true} as ViewMeetingAlertState,
        }),
    });
  }

  return (
    <>
      <div className="display-inline-block">
        <Button
          id="delete-reg-btn"
          type="button"
          className={`usa-button--secondary text-no-wrap ${styles['remove_registration_button']}`}
          onClick={handleRemoveClick}
          aria-disabled={disabled}
        >
          <span className="remove_text_full">Remove registration</span>
          <span className="remove_text_short">Remove</span>
        </Button>
        {disabled && disabled_reason && (
          <Tooltip anchorSelect="#delete-reg-btn" place="bottom">
            {disabled_reason}
          </Tooltip>
        )}
      </div>

      <GenericModalLayout
        isOpen={isModalOpen}
        closeModal={handleDenied}
        modal_heading="Remove Registration"
      >
        <div className="usa-prose">
          Are you sure you want to remove your registration for {meeting_title}?
        </div>
        {mutation.isError && <ErrorAlert>{reg_remove_err}</ErrorAlert>}
        <ModalFooter>
          <ButtonGroup
            type="default"
            className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
          >
            <Button
              type="button"
              className="usa-button--secondary"
              disabled={disabled || mutation.isPending}
              onClick={handleConfirmed}
            >
              {mutation.isPending ? <TextLoading /> : 'Remove registration'}
            </Button>
            <Button
              type="button"
              onClick={handleDenied}
              disabled={disabled || mutation.isPending}
              unstyled
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </GenericModalLayout>
    </>
  );
}
