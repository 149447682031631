import {
  Button,
  Checkbox,
  DatePicker,
  Label,
  Select,
  SummaryBox,
  SummaryBoxContent,
  SummaryBoxHeading,
  TextInput,
  Textarea,
} from '@trussworks/react-uswds';
import {
  addOrRemoveValidationMessage,
  debounce_delay_ms,
  getMtgFileValidationMsg,
  getUniqueFileId,
  meeting_file_message_dict,
  mtg_file_editor_notes_max_len,
  mtg_file_summary_max_len,
  mtg_file_title_max_len,
  removeValidationMessage,
  slashToDashDate,
} from '../../../lib/utils';
import styles from './MeetingFile.module.scss';
import React, {useEffect, useState} from 'react';
import {SetValidationMessages} from '../../../types/form-types';
import {
  SetFiles,
  SetDeleteModal,
  SetInvalidFileIds,
  SetDeleteEnabledIds,
  SetReplaceModal,
} from '../MeetingFiles/MeetingFiles';
import {useDebouncedCallback} from 'use-debounce';
import {FilesInputDict, MtgFormFile} from '../../../types/meeting-types';
import InlineAlert from '../../FormComponents/InlineAlert/InlineAlert';
import {
  allowed_mtg_file_extensions,
  header_signature_dict,
  max_mtg_file_bytes,
  sanitizeMeetingFileName,
} from '../../../../common/utils/file-utils';
import SectionDescription from '../../FormComponents/SectionDescription/SectionDescription';
import {MtgFileCategoriesGetResCategory} from '../../../../common/types/meeting-file-categories-get-res';

type FieldTag =
  | 'select_file'
  | 'hidden'
  | 'date'
  | 'category'
  | 'title'
  | 'summary'
  | 'editor_notes'
  | 'delete';

type HtmlIdDict = {[key in FieldTag]: string};

// For most fields true/false is sufficient to denote valid/invalid, respectively. The select file field can be invalid
// for one of several reasons that need to be displayed next to the select file button. So, its validation status is
// identified by a string value.
type SelectFileValidationStatus =
  | 'ok'
  | 'name_modified'
  | 'name_invalid'
  | 'ext_unsupported'
  | 'too_big'
  | 'file_missing'
  | 'signature_mismatch';
type FieldValidationStatusDict = Omit<{[key in FieldTag]: boolean}, 'select_file'> & {
  select_file: SelectFileValidationStatus;
};

type FormFileInfo = Pick<MtgFormFile, 'original_file_name' | 'file_name' | 'signature_mismatch'>;
async function getFormFileInfo(form_file: File): Promise<FormFileInfo> {
  const info: FormFileInfo = {
    original_file_name: form_file.name,
    file_name: null,
    signature_mismatch: false,
  };

  // Only perform the signature check (expensive operation) if the file name passes a sanity check
  const sanitization_result = sanitizeMeetingFileName(form_file.name);
  const {file_name, file_ext, error_code} = sanitization_result;
  if (error_code) return info;
  info.file_name = file_name;

  const {offset, signature: expected_signature} = header_signature_dict[file_ext];
  const read_max = offset + expected_signature.length;
  const header = new Uint8Array(read_max);
  const file_reader = form_file.stream().getReader();
  let read_len = 0;
  while (read_len < read_max) {
    const file_read = await file_reader.read();
    const value = file_read.value?.slice(0, read_max - read_len);
    if (value?.length) {
      header.set(value, read_len);
      read_len += value.length;
    }
    if (file_read.done) break;
  }
  await file_reader.cancel();

  const signature = header.slice(offset);
  info.signature_mismatch = expected_signature.some((val, i) => signature[i] != val);
  return info;
}

interface Props {
  file: MtgFormFile;
  file_idx: number;
  num_files: number;
  setFiles: SetFiles;
  categories: MtgFileCategoriesGetResCategory[];
  file_inputs_ref: React.MutableRefObject<FilesInputDict>;
  setValidationMessages: SetValidationMessages;
  setInvalidFileIds: SetInvalidFileIds;
  setDeleteEnabledIds: SetDeleteEnabledIds;
  setDeleteFileModal: SetDeleteModal;
  setReplaceFileModal: SetReplaceModal;
  is_edit_mtg?: boolean;
}

export default function MeetingFile({
  file,
  file_idx,
  num_files,
  setFiles,
  categories,
  file_inputs_ref,
  setValidationMessages,
  setInvalidFileIds,
  setDeleteEnabledIds,
  setDeleteFileModal,
  setReplaceFileModal,
  is_edit_mtg,
}: Props) {
  const [localValidationStatus, setLocalValidationStatus] = useState<FieldValidationStatusDict>({
    select_file: 'ok',
    hidden: true,
    date: true,
    category: true,
    title: true,
    summary: true,
    editor_notes: true,
    delete: true,
  });

  // file.is_removed should only matter in is_edit_mtg context
  const is_file_removed = is_edit_mtg && file.is_removed;
  const file_input_ref = file_inputs_ref.current[file.upload_id];
  const max_megabytes = Math.floor(max_mtg_file_bytes / 1024 ** 2);

  const file_id = getUniqueFileId(file);
  const html_id_dict: HtmlIdDict = {
    select_file: `${file_id}-select_file`,
    hidden: `${file_id}-hidden`,
    date: `${file_id}-date`,
    category: `${file_id}-category`,
    title: `${file_id}-title`,
    summary: `${file_id}-summary`,
    editor_notes: `${file_id}-editor_notes`,
    delete: `${file_id}-delete`,
  };

  const file_prefix = `File ${file_idx + 1}`;
  const label_classname = `text-semibold ${is_file_removed ? 'text-base' : 'text-primary'}`;

  useEffect(() => {
    let is_valid = true;

    // The delete file modal only allows deletion of an existing file if it passes validation. This is largely because
    // OpenAPI schema validation would forbid requests based on things like "title too short". The OpenAPI spec and the
    // custom PUT validation do not forbid setting a file metadata record to is_removed:true when an associated
    // file_envelope is missing. Support this on the front end as well. Then, if a file displays with notice "File
    // missing - please re-upload file!", the meeting organizer can opt to delete the file metadata without selecting a
    // file for upload. Track files that are safe for deletion independent of overall file validation status.
    let is_delete_allowed = !!file.pk;

    const is_file_missing =
      !file.original_file_name && (!file.uploaded_file_name || !file.pk) && !file.is_removed;
    is_valid &&= !is_file_missing;
    // is_delete_allowed is not affected
    addOrRemoveValidationMessage({
      is_condition_met: is_file_missing,
      err_message: getMtgFileValidationMsg(file_prefix, 'file_missing'),
      setValidationMessages,
    });

    const sanitization_result = file.original_file_name
      ? sanitizeMeetingFileName(file.original_file_name)
      : {error_code: undefined};

    const is_file_name_invalid =
      sanitization_result.error_code == 'no_valid_chars' ||
      sanitization_result.error_code == 'name_or_ext_missing';
    is_valid &&= !is_file_name_invalid;
    is_delete_allowed &&= !is_file_name_invalid;
    addOrRemoveValidationMessage({
      is_condition_met: is_file_name_invalid,
      err_message: getMtgFileValidationMsg(file_prefix, 'file_name_invalid'),
      setValidationMessages,
    });

    const is_file_ext_unsupported = sanitization_result.error_code == 'unsupported_ext';
    is_valid &&= !is_file_ext_unsupported;
    is_delete_allowed &&= !is_file_ext_unsupported;
    addOrRemoveValidationMessage({
      is_condition_met: is_file_ext_unsupported,
      err_message: getMtgFileValidationMsg(file_prefix, 'file_ext_unsupported'),
      setValidationMessages,
    });

    const is_file_too_big = (file_input_ref?.files?.[0]?.size ?? 0) > max_mtg_file_bytes;
    is_valid &&= !is_file_too_big;
    is_delete_allowed &&= !is_file_too_big;
    addOrRemoveValidationMessage({
      is_condition_met: is_file_too_big,
      err_message: getMtgFileValidationMsg(file_prefix, 'file_too_big'),
      setValidationMessages,
    });

    // This validation is expensive, so it is performed only once when the file is selected. The signature validation
    // status is saved to the MtgFormFile object.
    const is_file_signature_incorrect = file.signature_mismatch;
    is_valid &&= !is_file_signature_incorrect;
    is_delete_allowed &&= !is_file_signature_incorrect;
    addOrRemoveValidationMessage({
      is_condition_met: is_file_signature_incorrect,
      err_message: getMtgFileValidationMsg(file_prefix, 'signature_mismatch'),
      setValidationMessages,
    });

    const is_date_invalid = !file.date;
    is_valid &&= !is_date_invalid;
    is_delete_allowed &&= !is_date_invalid;
    addOrRemoveValidationMessage({
      is_condition_met: is_date_invalid,
      err_message: getMtgFileValidationMsg(file_prefix, 'date_invalid'),
      setValidationMessages,
    });

    const is_category_invalid = !categories.some((category) => file.category_pk == category.pk);
    is_valid &&= !is_category_invalid;
    is_delete_allowed &&= !is_category_invalid;
    addOrRemoveValidationMessage({
      is_condition_met: is_category_invalid,
      err_message: getMtgFileValidationMsg(file_prefix, 'category_invalid'),
      setValidationMessages,
    });

    const is_title_empty = !file.title.length;
    is_valid &&= !is_title_empty;
    is_delete_allowed &&= !is_title_empty;
    addOrRemoveValidationMessage({
      is_condition_met: is_title_empty,
      err_message: getMtgFileValidationMsg(file_prefix, 'title_min_length'),
      setValidationMessages,
    });

    const is_title_too_long = file.title.length > mtg_file_title_max_len;
    is_valid &&= !is_title_too_long;
    is_delete_allowed &&= !is_title_too_long;
    addOrRemoveValidationMessage({
      is_condition_met: is_title_too_long,
      err_message: getMtgFileValidationMsg(file_prefix, 'title_max_length'),
      setValidationMessages,
    });

    const is_summary_too_long = (file.summary ?? '').length > mtg_file_summary_max_len;
    is_valid &&= !is_summary_too_long;
    is_delete_allowed &&= !is_summary_too_long;
    addOrRemoveValidationMessage({
      is_condition_met: is_summary_too_long,
      err_message: getMtgFileValidationMsg(file_prefix, 'summary_max_length'),
      setValidationMessages,
    });

    const is_editor_notes_too_long =
      (file.editor_notes ?? '').length > mtg_file_editor_notes_max_len;
    is_valid &&= !is_editor_notes_too_long;
    is_delete_allowed &&= !is_editor_notes_too_long;
    addOrRemoveValidationMessage({
      is_condition_met: is_editor_notes_too_long,
      err_message: getMtgFileValidationMsg(file_prefix, 'editor_notes_max_length'),
      setValidationMessages,
    });

    // Not a strict error, just a warning.
    const is_file_name_modified =
      !!file.file_name && !!file.original_file_name && file.file_name != file.original_file_name;

    setLocalValidationStatus((status) => ({
      ...status,
      // Only one file selection validation error is displayed next to the button at a time. Arrange by precedence.
      select_file: is_file_name_invalid
        ? 'name_invalid'
        : is_file_ext_unsupported
        ? 'ext_unsupported'
        : is_file_too_big
        ? 'too_big'
        : is_file_signature_incorrect
        ? 'signature_mismatch'
        : is_file_missing
        ? 'file_missing'
        : is_file_name_modified
        ? 'name_modified'
        : 'ok',
      date: !is_date_invalid,
      category: !is_category_invalid,
      title: !is_title_empty && !is_title_too_long,
      summary: !is_summary_too_long,
      editor_notes: !is_editor_notes_too_long,
    }));

    setInvalidFileIds((ids) => {
      const new_ids = ids.filter((id) => id != file.local_id);
      if (!is_valid) new_ids.push(file.local_id);
      return new_ids;
    });

    setDeleteEnabledIds((ids) => {
      const new_ids = ids.filter((id) => id != file.local_id);
      if (is_delete_allowed) new_ids.push(file.local_id);
      return new_ids;
    });
  }, [
    file,
    file_prefix,
    num_files,
    categories,
    file_input_ref,
    setValidationMessages,
    setInvalidFileIds,
    setDeleteEnabledIds,
  ]);

  const updateFile = (partial_file: Partial<MtgFormFile>) => {
    setFiles((files) => {
      const new_files = [...files];
      new_files[file_idx] = {...new_files[file_idx], ...partial_file};
      return new_files;
    });
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {name, value} = event.target;
    if (name == html_id_dict['title']) {
      updateFile({title: value});
    } else if (name == html_id_dict['summary']) {
      updateFile({summary: value});
    } else if (name == html_id_dict['editor_notes']) {
      updateFile({editor_notes: value});
    } else if (name == html_id_dict['category']) {
      updateFile({category_pk: value});
    }
  };

  const handleInputChangeDebounced = useDebouncedCallback(handleInputChange, debounce_delay_ms);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {files: form_files} = event.target;
    const default_info: FormFileInfo = {
      file_name: null,
      original_file_name: null,
      signature_mismatch: false,
    };

    const form_file = form_files?.[0];
    if (!form_file) {
      updateFile(default_info);
      return;
    }
    // Debugging: To test invalid file names, create a new File instance.
    // new File([form_file.slice(0)], 'file<name>.mp4')
    getFormFileInfo(form_file)
      .then(updateFile)
      .catch((error) => {
        console.error('Unable to read file information\n', g_is_dev ? error : undefined);
        updateFile(default_info);
      });
  };

  const handleDateChange = (val: string | undefined) => {
    updateFile({date: val ? slashToDashDate(val) ?? '' : ''});
  };

  function handleDeleteButton() {
    removeValidationMessage({
      err_message: 'File [0-9]+',
      setValidationMessages,
      use_regex: true,
    });
    setFiles((files) => {
      const new_files = [...files];
      new_files.splice(file_idx, 1);
      return new_files;
    });
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {name, checked} = event.currentTarget;

    if (name == html_id_dict['delete']) {
      if (!file.is_removed) {
        setDeleteFileModal({is_open: true, file_idx});
      } else {
        updateFile({is_removed: checked});
      }
    } else if (name == html_id_dict['hidden']) {
      updateFile({is_hidden: checked});
    }
  }

  function handleSelectFile(event: React.MouseEvent<HTMLInputElement>) {
    // Only interrupt file selection with a modal if 1) this is file replacement request, and 2) the event that
    // triggered the input was user generated (isTrusted is false on programmatically generated events).
    if (file.uploaded_file_name && event.isTrusted) {
      event.preventDefault();
      setReplaceFileModal({is_open: true, file_idx});
    }
  }

  function handleResetFile() {
    if (!file_input_ref) return;
    // <input file=""> is not a controllable input. Wrappers that coerce it into a controllable input add more overhead
    // and under the hood use the native DOM API. While the following doesn't look like "the React way", it is the most
    // efficient approach.
    file_input_ref.value = '';
    file_input_ref.dispatchEvent(new Event('change', {bubbles: true}));
  }

  return (
    <SummaryBox
      className={`radius-top-0 ${styles.summary_box} ${
        file.is_removed ? `bg-base-lightest ${styles.is_removed}` : ''
      }`}
    >
      {is_file_removed && (
        <div className="margin-bottom-05">
          <InlineAlert>
            This file will be deleted when the &quot;Save changes&quot; button is clicked and the
            meeting is saved. Un-check the &quot;Delete file&quot; button below to retain this file.
          </InlineAlert>
        </div>
      )}
      <SummaryBoxHeading
        headingLevel="h4"
        className={`margin-0 word-break-word ${is_file_removed ? 'text-base-dark' : ''}`}
      >
        {file_idx + 1}.{' '}
        {file.pk ? `Edit "${file.title}"` : meeting_file_message_dict.new_config_title}
      </SummaryBoxHeading>
      <SummaryBoxContent>
        <section className="margin-top-3">
          <Label htmlFor={html_id_dict['select_file']} className="maxw-none">
            <span className={label_classname}>File *</span>
          </Label>
          {file.file_name || file.original_file_name || file.uploaded_file_name ? (
            <>
              <div className="word-break-word">
                {!!file.uploaded_file_name && (
                  <div>
                    <span className="text-semibold">{'Uploaded file: '}</span>
                    {file.uploaded_file_name}
                  </div>
                )}
                {!!(file.file_name || file.original_file_name) && (
                  <div>
                    <span className="text-semibold">
                      {file.uploaded_file_name ? 'To be replaced with: ' : 'Selected file: '}
                    </span>
                    {file.file_name || file.original_file_name}
                    {!!file.uploaded_file_name && (
                      <Button
                        type="button"
                        unstyled
                        className={`${styles.reset_file_btn} text-semibold margin-left-1`}
                        onClick={handleResetFile}
                      >
                        Undo
                      </Button>
                    )}
                  </div>
                )}
              </div>
              {!!file.uploaded_file_name && (
                <div>
                  <SectionDescription>
                    Warning! Uploading a new file will cause the current file to be deleted.
                  </SectionDescription>
                </div>
              )}
            </>
          ) : (
            <SectionDescription>Click the button below to select a file.</SectionDescription>
          )}
          <div className="display-flex flex-wrap">
            <div className="flex-auto flex-align-self-center width-full mobile-lg:width-auto">
              <input
                type="file"
                className="offscreen_file_input"
                id={html_id_dict['select_file']}
                name={html_id_dict['select_file']}
                ref={(elm) => (file_inputs_ref.current[file.upload_id] = elm ?? undefined)}
                accept={allowed_mtg_file_extensions.join(',')}
                onChange={handleFileChange}
                onClick={handleSelectFile}
                disabled={is_file_removed}
                required={!file.uploaded_file_name}
              />
              <label
                className={`offscreen_file_label usa-button usa-button--outline ${styles.select_file_btn}`}
                title={
                  localValidationStatus['select_file'] == 'file_missing'
                    ? 'Please select a file'
                    : ['name_invalid', 'ext_unsupported', 'too_big', 'signature_mismatch'].includes(
                        localValidationStatus['select_file']
                      )
                    ? 'Please select a new file'
                    : ''
                }
                aria-disabled={is_file_removed}
                htmlFor={html_id_dict['select_file']}
              >
                {file.uploaded_file_name ? 'Replace file' : 'Select file'}
              </label>
            </div>
            {localValidationStatus['select_file'] != 'ok' && !is_file_removed && (
              <div className="flex-fill flex-align-self-center mobile-lg:margin-left-3">
                <SectionDescription>
                  {localValidationStatus['select_file'] == 'file_missing' && (
                    <span className="text-error text-semibold">Please select a file.</span>
                  )}
                  {localValidationStatus['select_file'] == 'name_invalid' && (
                    <span className="text-error text-semibold">
                      File name is not valid. Please rename the file and try selecting it again.
                    </span>
                  )}
                  {localValidationStatus['select_file'] == 'ext_unsupported' && (
                    <span className="text-error text-semibold">
                      File type is not supported. Supported file types are{' '}
                      {allowed_mtg_file_extensions.join(', ')}.
                    </span>
                  )}
                  {localValidationStatus['select_file'] == 'too_big' && (
                    <span className="text-error text-semibold">
                      File size is too large. Please select a file smaller than {max_megabytes}MB.
                    </span>
                  )}
                  {localValidationStatus['select_file'] == 'signature_mismatch' && (
                    <span className="text-error text-semibold">
                      File contents do not match file extension. Please check file extension and try
                      selecting it again.
                    </span>
                  )}
                  {localValidationStatus['select_file'] == 'name_modified' && (
                    <span className="text-success text-semibold">
                      The file name will be modified as shown above for cross-platform
                      compatibility.
                    </span>
                  )}
                </SectionDescription>
              </div>
            )}
          </div>
        </section>
        <section className="margin-top-neg-1">
          <Label htmlFor={html_id_dict['hidden']} className="maxw-none">
            <span className={label_classname}>Hidden file?</span>
          </Label>
          <SectionDescription>
            If hidden, only other editors and admins can see this file.
          </SectionDescription>
          <div className="display-inline-flex">
            <Checkbox
              id={html_id_dict['hidden']}
              name={html_id_dict['hidden']}
              label="Hidden"
              checked={file.is_hidden}
              onChange={handleCheckboxChange}
              className="margin-top-1 radius-md"
              disabled={is_file_removed}
              tile
            />
          </div>
        </section>
        <section className="margin-top-neg-1 grid-row">
          <div className="tablet:grid-col display-flex padding-right-3">
            <div className="flex-auto">
              <Label htmlFor={html_id_dict['date']} className="maxw-none">
                <span className={label_classname}>Date *</span>
              </Label>
              <DatePicker
                id={html_id_dict['date']}
                name={html_id_dict['date']}
                defaultValue={file.date}
                onChange={handleDateChange}
                validationStatus={localValidationStatus['date'] ? undefined : 'error'}
                disabled={is_file_removed}
                required
              />
              <SectionDescription>(MM/DD/YYYY)</SectionDescription>
            </div>
          </div>
          <div className="tablet:grid-col">
            <Label htmlFor={html_id_dict['category']} className="maxw-none">
              <span className={label_classname}>File category *</span>
            </Label>
            <Select
              id={html_id_dict['category']}
              name={html_id_dict['category']}
              defaultValue={file.category_pk}
              onChange={handleInputChange}
              validationStatus={localValidationStatus['category'] ? undefined : 'error'}
              className="width-full mobile-lg:width-auto"
              disabled={is_file_removed}
              required
            >
              <option key="" value="">
                -Select-
              </option>
              {categories.map((category) => (
                <option key={category.pk} value={category.pk}>
                  {category.title}
                </option>
              ))}
            </Select>
          </div>
        </section>
        <section className="margin-top-neg-1">
          <Label htmlFor={html_id_dict['title']} className="maxw-none">
            <span className={label_classname}>Title *</span>
          </Label>
          <SectionDescription>
            File title that will display on meeting details page.
          </SectionDescription>
          <TextInput
            id={html_id_dict['title']}
            name={html_id_dict['title']}
            type="text"
            defaultValue={file.title}
            onChange={handleInputChangeDebounced}
            validationStatus={localValidationStatus['title'] ? undefined : 'error'}
            disabled={is_file_removed}
            required
          />
        </section>
        <section className="margin-top-neg-1 margin-bottom-2">
          <Label htmlFor={html_id_dict['summary']} className="maxw-none">
            <span className={label_classname}>Summary</span>
          </Label>
          <SectionDescription>
            File summary that will display on meeting details page.
          </SectionDescription>
          <TextInput
            id={html_id_dict['summary']}
            name={html_id_dict['summary']}
            type="text"
            defaultValue={file.summary ?? ''}
            onChange={handleInputChangeDebounced}
            validationStatus={localValidationStatus['summary'] ? undefined : 'error'}
            disabled={is_file_removed}
          />
        </section>
        <section className="bg-internal padding-x-3 padding-y-2 margin-x-neg-3">
          <div className="margin-top-neg-3">
            <Label htmlFor={html_id_dict['editor_notes']} className="maxw-none">
              <span className={label_classname}>Editor notes</span>
            </Label>
            <SectionDescription>
              Notes that only other editors and admins can see.
            </SectionDescription>
            <Textarea
              id={html_id_dict['editor_notes']}
              name={html_id_dict['editor_notes']}
              defaultValue={file.editor_notes ?? ''}
              onChange={handleInputChangeDebounced}
              error={!localValidationStatus['editor_notes']}
              disabled={is_file_removed}
            />
          </div>
        </section>
        <section className="margin-top-3">
          {is_edit_mtg && file.pk ? (
            <Checkbox
              id={html_id_dict['delete']}
              name={html_id_dict['delete']}
              label="Delete file"
              checked={file.is_removed}
              className="usa-checkbox--secondary usa-checkbox--secondary-unchecked radius-md display-inline-block"
              onChange={handleCheckboxChange}
              tile
            />
          ) : (
            <Button
              type="button"
              className={`usa-button--outline usa-button--outline-secondary ${styles.delete_file_btn}`}
              onClick={handleDeleteButton}
            >
              Delete file
            </Button>
          )}
        </section>
      </SummaryBoxContent>
    </SummaryBox>
  );
}
