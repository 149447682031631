import {
  useMutationBulkUpdateAttendance,
  useQueryRegistrants,
} from '../../services/registrant-queries';
import {
  ActiveTab,
  attendance_update_err,
  attendance_update_success,
  reg_list_err,
  scrollToTop,
} from '../../lib/utils';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {useRef, useState} from 'react';
import {Checkbox, Form, FormGroup, Label, TextInput} from '@trussworks/react-uswds';
import {MeetingsGetByIdData} from '../../../common/types/meetings-get-by-id-res';
/* eslint-disable max-len */
import ExportRegistrantsButton from '../../components/RegistrantsTableComponents/ExportRegistrantsButton/ExportRegistrantsButton';
import EditRegistrantsTable from '../../components/RegistrantsTableComponents/EditRegistrantsTable/EditRegistrantsTable';
import ViewRegistrantsTable from '../../components/RegistrantsTableComponents/ViewRegistrantsTable/ViewRegistrantsTable';
/* eslint-enable max-len */
import {RegistrantsAttendancePatchReqBody} from '../../../common/types/registrants-attendance-patch-req';
import {ValidationMessages} from '../../types/form-types';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';

export interface RegistrantsProps {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
  active_tab: ActiveTab;
}

export default function Registrants({meeting_id, active_tab, meeting_data}: RegistrantsProps) {
  const registrants_query = useQueryRegistrants(meeting_id, {
    is_enabled: active_tab == 'registrants',
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [attendanceMode, setAttendanceMode] = useState(false);
  const [formData, setFormData] = useState<RegistrantsAttendancePatchReqBody>([]);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const discard_btn_ref = useRef<HTMLButtonElement>(null);
  const mutation = useMutationBulkUpdateAttendance();

  const [initialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  const toggleAttendanceMode = () => {
    setAttendanceMode((mode) => !mode);
    setFormData([]);
    setGlobalFilter('');
  };

  const is_submit_disabled = !!validationMessages.length || mutation.isPending || !formData.length;
  const is_discard_disabled = mutation.isPending;
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    mutation.mutate(
      {meeting_id, form_data: formData},
      {
        onSuccess: () => {
          toggleAttendanceMode();
        },
        onSettled: () => {
          setTimeout(scrollToTop, 0);
        },
      }
    );
  };
  const handleDiscard = () => {
    mutation.reset();
    toggleAttendanceMode();
  };

  return (
    <>
      {mutation.isSuccess && <SuccessAlert>{attendance_update_success}</SuccessAlert>}
      {mutation.isError && <ErrorAlert>{attendance_update_err}</ErrorAlert>}
      {!registrants_query.isPending && !registrants_query.isError && (
        <>
          <section>
            <ExportRegistrantsButton
              meeting_data={meeting_data}
              registrants_data={registrants_query.data}
              is_disabled={attendanceMode}
              disabled_tooltip="Export is unavailable while attendance mode is active"
            />
          </section>
          <section className="margin-y-4">
            <Checkbox
              id="attendance-mode"
              name="attendance-mode"
              className="display-inline-block"
              label={attendanceMode ? 'Uncheck to discard changes' : 'Check to take attendance'}
              checked={attendanceMode}
              onChange={() => {
                if (attendanceMode) {
                  discard_btn_ref.current?.click();
                } else {
                  toggleAttendanceMode();
                }
              }}
              disabled={mutation.isPending}
              tile
            />
          </section>
        </>
      )}
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word margin-y-2">
        {/* eslint-disable-next-line max-len */}
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            {attendanceMode ? 'Search names and emails' : 'Search names, emails, and organizations'}
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
      </section>
      {registrants_query.isError ? (
        <ErrorAlert>{reg_list_err} </ErrorAlert>
      ) : registrants_query.isPending ? (
        <PageLoading message="Loading registrants" />
      ) : (
        <>
          {attendanceMode ? (
            <EditRegistrantsTable
              registrants_data={registrants_query.data}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              setFormData={setFormData}
              setValidationMessages={setValidationMessages}
            />
          ) : (
            <ViewRegistrantsTable
              meeting_id={meeting_id}
              meeting_data={meeting_data}
              registrants_data={registrants_query.data}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {attendanceMode && (
            <BottomContainer>
              <div className="grid-row flex-justify-end">
                <Form
                  className="width-full mobile-lg:width-auto"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <FormSubmit
                    is_disabled={is_submit_disabled}
                    validationMessages={validationMessages}
                    ref={submit_btn_ref}
                  >
                    Save changes
                  </FormSubmit>
                  <DiscardChanges
                    ref={discard_btn_ref}
                    isFormDirty={isFormDirty}
                    disable_nav_blocker={is_discard_disabled}
                    onConfirm={handleDiscard}
                    visibility="offscreen"
                    tabIndex={-1}
                    watch_url_segments={{pathname: true, search: true, hash: true}}
                  />
                </Form>
              </div>
            </BottomContainer>
          )}
        </>
      )}
    </>
  );
}
