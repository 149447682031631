import {addOrRemoveValidationMessage, getRegistrationFormatStr} from '../../../lib/utils';
import {useEffect, useMemo, useState} from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  RegistrantsGetData,
  RegistrantsGetDataRegistrant,
} from '../../../../common/types/registrants-get-res';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import {Checkbox} from '@trussworks/react-uswds';
import TableUI from '../../TableUI/TableUI';
import LongFieldWithTooltip from '../../LongFieldWithTooltip/LongFieldWithTooltip';
import styles from './EditRegistrantsTable.module.scss';
import {RegistrantsAttendancePatchReqBody} from '../../../../common/types/registrants-attendance-patch-req';
import {SetValidationMessages} from '../../../types/form-types';

interface AttendanceStatus {
  user_fk: string;
  old_did_attend: boolean;
  new_did_attend: boolean;
}

interface RegistrantRowData {
  registrant: RegistrantsGetDataRegistrant;
  status: AttendanceStatus;
}

function getInitialAttendanceStatus(registrant: RegistrantsGetDataRegistrant): AttendanceStatus {
  return {
    user_fk: registrant.user_fk,
    old_did_attend: registrant.did_attend,
    new_did_attend: registrant.did_attend,
  };
}

function prepareFormData(attendance: AttendanceStatus[]): RegistrantsAttendancePatchReqBody {
  return attendance
    .filter((status) => status.new_did_attend != status.old_did_attend)
    .map((status) => ({
      user_fk: status.user_fk,
      did_attend: status.new_did_attend,
    }));
}

interface Props {
  registrants_data: RegistrantsGetData;
  globalFilter: string;
  setGlobalFilter: (value: React.SetStateAction<string>) => void;
  setFormData: React.Dispatch<React.SetStateAction<RegistrantsAttendancePatchReqBody>>;
  setValidationMessages: SetValidationMessages;
}

export default function EditRegistrantsTable({
  registrants_data,
  globalFilter,
  setGlobalFilter,
  setFormData,
  setValidationMessages,
}: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [attendance, setAttendance] = useState<AttendanceStatus[]>([]);

  useEffect(() => {
    setAttendance(
      registrants_data.map((registrant) => ({
        user_fk: registrant.user_fk,
        old_did_attend: registrant.did_attend,
        new_did_attend: registrant.did_attend,
      }))
    );
  }, [registrants_data]);

  useEffect(() => {
    const form_data = prepareFormData(attendance);
    setFormData(form_data);
    addOrRemoveValidationMessage({
      is_condition_met: !form_data.length,
      err_message: 'At least one attendance update must be made before saving changes',
      setValidationMessages,
    });
  }, [attendance, setValidationMessages, setFormData]);

  const columns = useMemo<ColumnDef<RegistrantRowData>[]>(
    () => [
      {
        accessorFn: (row) => (row.status.new_did_attend ? 'Yes' : 'No'),
        cell: (info) => {
          const {registrant, status} = info.row.original;
          const {user_fk} = registrant;
          const id_name = `attendance-${user_fk}`;
          const did_attend = status.new_did_attend;
          const has_pending_update = status.new_did_attend != status.old_did_attend;

          return (
            <Checkbox
              id={id_name}
              name={id_name}
              label={`${did_attend ? 'Yes' : 'No'}${has_pending_update ? '*' : ''}`}
              className={styles.attendance_item_checkbox}
              checked={did_attend}
              onChange={(event) => {
                const new_did_attend = event.currentTarget.checked;
                setAttendance((attendance) =>
                  attendance.map((prev_status) =>
                    prev_status.user_fk == user_fk ? {...prev_status, new_did_attend} : prev_status
                  )
                );
              }}
            />
          );
        },
        header: 'Did attend?',
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.registrant.first_name,
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.first_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.registrant.last_name,
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.last_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => getRegistrationFormatStr(row.registrant),
        header: 'Format',
        id: 'format_info',
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.registrant.email,
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.email.title,
        sortingFn: 'text',
      },
    ],
    []
  );

  const row_data = useMemo(
    () =>
      registrants_data.map<RegistrantRowData>((registrant) => ({
        registrant,
        status:
          attendance.find((status) => status.user_fk == registrant.user_fk) ??
          getInitialAttendanceStatus(registrant),
      })),
    [registrants_data, attendance]
  );

  const table = useReactTable({
    data: row_data,
    columns: columns,
    state: {sorting, globalFilter},
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableUI
      table={table}
      clickedIndex={clickedIndex}
      setClickedIndex={setClickedIndex}
      variant="edit_registrants_table"
      is_unpaginated
    />
  );
}
