import {Checkbox} from '@trussworks/react-uswds';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import metadata_client_meeting from '../../../lib/metadata-client-meeting.json';
import metadata_client_registration from '../../../lib/metadata-client-registration.json';
import styles from './FormCheckbox.module.scss';
import {FormType, HandleInputChange, MetadataValue} from '../../../types/form-types';

interface Props {
  id_name: string;
  is_checked: boolean;
  handleChange: HandleInputChange;
  show_hint?: boolean;
  is_disabled?: boolean;
  form_type: FormType;
  use_danger_styles?: true;
  use_full_width?: true;
}

export default function FormCheckbox({
  is_checked,
  id_name,
  handleChange,
  show_hint,
  is_disabled,
  form_type,
  use_danger_styles,
  use_full_width,
}: Props) {
  let metadata_obj: MetadataValue;
  if (form_type == 'meeting') {
    metadata_obj = metadata_client_meeting[id_name as keyof typeof metadata_client_meeting];
  } else if (form_type == 'account') {
    metadata_obj = metadata_client_account[id_name as keyof typeof metadata_client_account];
  } else {
    metadata_obj =
      metadata_client_registration[id_name as keyof typeof metadata_client_registration];
  }

  if (!('action_title' in metadata_obj)) {
    throw new Error(
      `Please add "action_title" in metadata-client-account.json for checkboxes in ${id_name}`
    );
  }

  if (show_hint && !('hint' in metadata_obj)) {
    throw new Error(
      `Please add "hint" in metadata-client-account.json for show_hint properties in ${id_name}`
    );
  }

  return (
    <>
      <div className="margin-top-0">
        <span className="text-primary text-semibold">{metadata_obj.action_title}</span>
      </div>
      <Checkbox
        id={id_name}
        name={id_name}
        label={metadata_obj.action_title}
        checked={is_checked}
        onChange={handleChange}
        className={`margin-top-1 radius-md ${
          is_checked && use_danger_styles ? styles.danger : ''
        } ${use_full_width ? '' : 'display-inline-flex'}`}
        tile
        disabled={is_disabled}
      />
      {show_hint && (
        <p className="margin-top-05 margin-bottom-0 text-italic text-base">({metadata_obj.hint})</p>
      )}
    </>
  );
}

export function handleTypedCheckbox<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  const handleCheckbox: HandleInputChange = (event) => {
    // Events bubble. By the time the callback to setFormData runs, event.currentTarget can be null. Extract values
    // from event.currentTarget now so they will be available when the callback is invoked.
    const {name, checked} = event.currentTarget;
    setFormData((formData) => ({...formData, [name]: checked}));
  };
  return handleCheckbox;
}
