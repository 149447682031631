import {scrollToTop, logAndGetErrMessage} from '../../../lib/utils';
import {useEffect, useState} from 'react';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';

interface Props {
  error: unknown;
  message?: string;
}

export default function FormGenericError({error, message}: Props) {
  const [errMessage, setErrMessage] = useState('');

  useEffect(() => {
    scrollToTop();
    // Save the err message in a state so `logAndGetErrMessage` only gets triggered once
    // otherwise, the func is called on every re-render
    // which results in a log for every state update, which is a lot
    setErrMessage(logAndGetErrMessage(error));
  }, [error]);

  return <ErrorAlert>{message || errMessage}</ErrorAlert>;
}
