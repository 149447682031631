import {createRoot} from 'react-dom/client';
import {StrictMode} from 'react';
import {app_name} from '../common/lib/shared-vars';
import App from './App';
import '@trussworks/react-uswds/lib/index.css';
import '../../assets/uswds/css/styles.css';

// Initialize <title> to base app name and routes can amend as necessary
document.title = app_name;

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
