import {useEffect, useRef, useState} from 'react';
import {EditMyAccountForm} from '../../types/account-types';
import {Grid} from '@trussworks/react-uswds';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import FormCheckbox, {
  handleTypedCheckbox,
} from '../../components/FormComponents/FormCheckbox/FormCheckbox';
import {
  acc_update_success,
  handleTypedInputChange,
  handleTypedPhoneChange,
  piv_delete_success,
  scrollToTop,
} from '../../lib/utils';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import RoleNameTag from '../../components/AccountComponents/RoleNameTag/RoleNameTag';
import {AccountGetPutData} from '../../../common/types/account-get-put-res';
import {useMutationEditMyAccount} from '../../services/account-queries';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import AdditionalInformation from '../../components/AccountComponents/AdditionalInformation/AdditionalInformation';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import {useNavigate} from 'react-router-dom';
import {MeetingsListAlertState} from '../../types/meeting-types';
// eslint-disable-next-line max-len
import ConfirmAccountDeletionModal, {
  handleTypedDeleteCheckbox,
  handleTypedDeleteConfirmation,
} from '../../components/FormComponents/AccountFormComponents/ConfirmAccountDeletionModal/ConfirmAccountDeletionModal';
import {AccountPivDetails} from '../../components/AccountComponents/AccountPivDetails/AccountPivDetails';
import LinkedLoginGovInfo from '../../components/AccountComponents/LinkedLoginGovInfo/LinkedLoginGovInfo';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';
import {AccountPrivilegesGetData} from '../../../common/types/account-privileges-get-res';

function formatQueryData(query_data: AccountGetPutData, privileges: AccountPrivilegesGetData) {
  return {
    first_name: query_data.first_name || '',
    last_name: query_data.last_name || '',
    job_title: query_data.job_title || '',
    org: query_data.org || '',
    phone: query_data.phone || '',
    is_removed: query_data.is_removed,
    admin_notes: privileges.can_view_users ? query_data.admin_notes || '' : undefined,
  };
}

interface Props {
  query_data: AccountGetPutData;
}

export default function MyAccount({query_data}: Props) {
  usePageTitle('My account');
  const privileges = usePrivilegesContext();
  const [formData, setFormData] = useState<EditMyAccountForm>(
    formatQueryData(query_data, privileges)
  );
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPivDeleteSuccess, setIsPivDeleteSuccess] = useState<true | null>(null);
  const mutation = useMutationEditMyAccount({is_acc_delete: formData.is_removed});
  const navigate = useNavigate();

  const [initialForm, setInitialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  useEffect(() => {
    setFormData(formatQueryData(query_data, privileges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query_data.email]);

  const is_submit_disabled =
    !!validationMessages.length || mutation.isPending || isNavigationPending;
  const is_discard_disabled = mutation.isPending || isNavigationPending;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    setIsPivDeleteSuccess(null);
    const formatted_form_data = {...formData};
    if (!privileges.can_edit_users) delete formatted_form_data.admin_notes;
    // If a user deletes their own account, all queries are reset, so onSuccess/onError do not fire when using
    // mutation.mutate(). Use the async method to capture success/error and respond with frontend actions.
    mutation
      .mutateAsync(formatted_form_data)
      .then((updated_query_data) => {
        if (formatted_form_data.is_removed) {
          setIsNavigationPending(true);
          navigate('/', {state: {is_acc_deleted: true} as MeetingsListAlertState});
        } else {
          const updated_form_data = formatQueryData(updated_query_data, privileges);
          setFormData(updated_form_data);
          setInitialForm(updated_form_data);
          scrollToTop();
        }
      })
      .catch(() => {
        // do nothing as the error is handled by `mutation.isError` below
      });
  };

  function handleSuccessfulPivDelete() {
    mutation.reset();
    setIsPivDeleteSuccess(true);
    scrollToTop();
  }

  const handleInputChange = handleTypedInputChange(setFormData);
  const handleCheckbox = handleTypedCheckbox(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);
  const handleDeleteConfirmation = handleTypedDeleteConfirmation(setFormData, setIsDeleteModalOpen);
  const handleDeleteCheckbox = handleTypedDeleteCheckbox(handleCheckbox, setIsDeleteModalOpen);

  return (
    <section
      className={`grid-container-tablet${
        privileges.can_edit_users ? ' margin-bottom-10' : ''
      } padding-x-0`}
    >
      {mutation.isSuccess && <SuccessAlert>{acc_update_success}</SuccessAlert>}
      {mutation.isError && <FormGenericError error={mutation.error} />}
      {isPivDeleteSuccess && <SuccessAlert>{piv_delete_success}</SuccessAlert>}
      <p className="text-italic line-height-sans-5">
        <span className="margin-right-1">
          <RoleNameTag name={query_data.role.name} level={query_data.role.level} />
        </span>
        You are currently logged in as{' '}
        <span className="text-bold word-break-all">{query_data.email}</span>
      </p>
      {query_data.has_login_gov && <LinkedLoginGovInfo />}
      <FormLayout handleSubmit={handleSubmit}>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="first_name"
              input={formData.first_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="last_name"
              input={formData.last_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_required
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.job_title}
              id_name="job_title"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.org}
              id_name="org"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormPhoneInputLazy
              phone={formData.phone}
              handleChange={handlePhoneChange}
              setValidationMessages={setValidationMessages}
            />
          </div>
          <div className="mobile-lg:grid-col-6 margin-top-3">
            <FormCheckbox
              id_name="is_removed"
              is_checked={formData.is_removed}
              handleChange={handleDeleteCheckbox}
              show_hint
              form_type="account"
              use_danger_styles
              use_full_width
            />
          </div>
          <ConfirmAccountDeletionModal
            isModalOpen={isDeleteModalOpen}
            handleDeleteConfirmation={handleDeleteConfirmation}
            is_my_acc
          />
        </Grid>
        {formData.admin_notes != null && (
          <section className="padding-2 margin-top-205 bg-internal">
            <div className="margin-top-neg-3">
              <FormTextArea
                input={formData.admin_notes}
                id_name="admin_notes"
                handleChange={handleTextAreaChange}
                setValidationMessages={setValidationMessages}
                is_disabled={!privileges.can_edit_users}
                is_validate_length
                form_type="account"
              />
            </div>
          </section>
        )}
        {privileges.can_edit_users ? (
          <BottomContainer>
            <div className="grid-container-tablet padding-x-0 grid-row flex-justify-end">
              <FormSubmit
                validationMessages={validationMessages}
                is_disabled={is_submit_disabled}
                ref={submit_btn_ref}
              >
                Save changes
              </FormSubmit>
            </div>
          </BottomContainer>
        ) : (
          <div className="margin-top-3 mobile-lg:margin-top-4">
            <FormSubmit
              validationMessages={validationMessages}
              is_disabled={is_submit_disabled}
              ref={submit_btn_ref}
              is_full_width
            >
              Save changes
            </FormSubmit>
          </div>
        )}
        <DiscardChanges
          isFormDirty={isFormDirty}
          disable_nav_blocker={is_discard_disabled}
          visibility="none"
        />
      </FormLayout>
      {privileges.can_view_users && (
        <section className="margin-top-4">
          <AdditionalInformation query_data={query_data} info_type="account" />
        </section>
      )}
      {privileges.can_view_piv_data && (
        <AccountPivDetails
          piv_certificates={query_data.piv_certificates}
          user_id={query_data.pk as string}
          setIsPivDeleteSuccess={setIsPivDeleteSuccess}
          handleSuccessfulPivDelete={handleSuccessfulPivDelete}
        />
      )}
    </section>
  );
}
