import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useQueryMeetingByID} from '../../../services/meeting-queries';
import ViewMeetingTabs from '../ViewMeetingTabs';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import {
  mtg_create_success,
  mtg_edit_success,
  reg_create_success,
  reg_remove_success,
  reg_update_success,
  scrollToTop,
} from '../../../lib/utils';
import {useQueryPrivileges} from '../../../services/account-queries';
import {ViewMeetingAlertState} from '../../../types/meeting-types';
import {useEffect, useMemo} from 'react';
import SuccessAlert from '../../../components/SuccessAlert/SuccessAlert';

export default function ViewMeetingWrapper() {
  const {id} = useParams();
  const location = useLocation();
  const state = location.state as ViewMeetingAlertState | null;
  if (!id) throw new Error('id not passed');
  const meeting_query = useQueryMeetingByID(id);

  // Non-critical queries - these will attempt to load on every remount for anonymous users, resulting in a very jarring
  // page view experience on every window focus/blur. Memoize the data and allow the page to load without waiting for
  // isPending. The drawback is miniscule - authenticated users navigating directly here may see content shift as their
  // privileges and registration status load for the first time, enabling additional features on the page. After first
  // load, the content shift will not occur again with window focus/blur.
  const privileges_query = useQueryPrivileges({});
  const privileges_data = useMemo(
    () => (privileges_query.isSuccess ? privileges_query.data : undefined),
    [privileges_query.isSuccess, privileges_query.data]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  // This shouldn't render since we're using err boundary in the query
  if (meeting_query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>{meeting_query.isPending ? 'Loading meeting...' : meeting_query.data.title}</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {state && (
        <>
          {/* Prevent alert from persisting after refresh */}
          {window.history.replaceState({}, document.title)}
          <SuccessAlert>
            {state.is_new_mtg && mtg_create_success}
            {state.is_edit_mtg && mtg_edit_success}
            {state.is_successful_reg && reg_create_success}
            {state.is_successful_edit_reg && reg_update_success}
            {state.is_successful_edit_others_reg &&
              `${state.registrant_name}'s registration has been successfully updated.`}
            {state.is_reg_deleted && reg_remove_success}
          </SuccessAlert>
        </>
      )}
      {meeting_query.isPending ? (
        <PageLoading message="Loading meeting" />
      ) : (
        <ViewMeetingTabs
          meeting_id={id}
          meeting_data={meeting_query.data}
          privileges_data={privileges_data}
        />
      )}
    </>
  );
}
