import {Outlet} from 'react-router-dom';
import {GovBanner, ModalRef} from '@trussworks/react-uswds';
import Navbar from './Navbar/Navbar';
import PhmsaBanner from './PhmsaBanner/PhmsaBanner';
import {RefObject, useRef} from 'react';
import EnvironmentBanner from './EnvironmentBanner/EnvironmentBanner';

export type AppRouterContext = [RefObject<ModalRef>];

export default function RootLayout() {
  const authModalRef = useRef<ModalRef>(null);
  const appRouterContext: AppRouterContext = [authModalRef];

  return (
    <>
      <GovBanner aria-label="Official government website" />
      <EnvironmentBanner />
      <PhmsaBanner />
      <Navbar authModalRef={authModalRef} />
      <main className="grid-container padding-y-5">
        <Outlet context={appRouterContext} />
      </main>
    </>
  );
}
