import {
  Checkbox,
  Label,
  SummaryBox,
  SummaryBoxContent,
  SummaryBoxHeading,
  TextInput,
} from '@trussworks/react-uswds';
import {
  addOrRemoveValidationMessage,
  crq_type_message_dict,
  debounce_delay_ms,
  getUniqueCrqId,
  removeValidationMessage,
} from '../../../lib/utils';
import styles from './CustomRegQuestion.module.scss';
import React, {useEffect, useState} from 'react';
import {SetValidationMessages} from '../../../types/form-types';
import {
  SetCrqs,
  SetDeleteModal,
  SetInvalidCroIds,
  SetInvalidCrqIdsBySource,
} from '../CustomRegQuestions/CustomRegQuestions';
import {useDebouncedCallback} from 'use-debounce';
import CustomRegOptions from '../CustomRegOptions/CustomRegOptions';
import SectionDescription from '../../FormComponents/SectionDescription/SectionDescription';
import {MtgFormCRQuestion} from '../../../types/meeting-types';
import InlineAlert from '../../FormComponents/InlineAlert/InlineAlert';

interface Props {
  crq: MtgFormCRQuestion;
  crq_idx: number;
  num_crqs: number;
  setCrqs: SetCrqs;
  setValidationMessages: SetValidationMessages;
  setDeleteCRModal: SetDeleteModal;
  setInvalidCrqIdsBySource: SetInvalidCrqIdsBySource;
  setInvalidCroIds: SetInvalidCroIds;
  is_edit_mtg?: boolean;
}

export default function CustomRegQuestion({
  crq,
  crq_idx,
  num_crqs,
  setCrqs,
  setValidationMessages,
  setDeleteCRModal,
  setInvalidCrqIdsBySource,
  setInvalidCroIds,
  is_edit_mtg,
}: Props) {
  const [isValid, setIsValid] = useState(true);

  // crq.is_removed should only matter in in is_edit_mtg context
  const is_crq_removed = is_edit_mtg && crq.is_removed;

  const question_id = `${getUniqueCrqId(crq)}-qst`;
  const required_id = `${getUniqueCrqId(crq)}-required`;
  const delete_id = `${getUniqueCrqId(crq)}-delete`;
  const qst_prefix = `Question ${crq_idx + 1}`;
  const required_validation_msg = `Please enter question text for ${qst_prefix}`;
  const length_validation_msg = `Question text for ${qst_prefix} cannot be more than 255 characters`;
  const label_classname = `text-semibold ${is_crq_removed ? 'text-base' : 'text-primary'}`;

  useEffect(() => {
    let is_valid = true;

    const does_input_exist = !!crq.title.length;
    is_valid = does_input_exist;
    addOrRemoveValidationMessage({
      is_condition_met: !does_input_exist,
      err_message: required_validation_msg,
      setValidationMessages,
    });

    const is_input_too_long = crq.title.length > 255;
    is_valid = is_valid && !is_input_too_long;
    addOrRemoveValidationMessage({
      is_condition_met: is_input_too_long,
      err_message: length_validation_msg,
      setValidationMessages,
    });

    setIsValid(is_valid);
    setInvalidCrqIdsBySource((source_dict) => {
      const new_source_dict = {...source_dict};
      const new_ids = new_source_dict.from_crq_validation.filter((id) => id != crq.local_id);
      if (!is_valid) new_ids.push(crq.local_id);
      new_source_dict.from_crq_validation = new_ids;
      return new_source_dict;
    });
  }, [
    crq.title.length,
    crq.local_id,
    crq_idx,
    num_crqs,
    length_validation_msg,
    qst_prefix,
    required_validation_msg,
    setValidationMessages,
    setInvalidCrqIdsBySource,
  ]);

  /**
   * @param partial_crq Include only the properties that should be updated. `partial_crq` can be set to `null` to
   * permanently remove this CRQ.
   */
  function updateCrq(partial_crq: Partial<MtgFormCRQuestion> | null) {
    setCrqs((crqs) => {
      const new_crqs = [...crqs];
      if (!partial_crq) {
        new_crqs.splice(crq_idx, 1);
      } else {
        new_crqs[crq_idx] = {...new_crqs[crq_idx], ...partial_crq} as MtgFormCRQuestion;
      }
      return new_crqs;
    });
  }

  const handleQuestionInputChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      updateCrq({title: value});
    },
    debounce_delay_ms
  );

  function handleDeleteQuestion() {
    removeValidationMessage({
      err_message: 'Question [0-9]+',
      setValidationMessages,
      use_regex: true,
    });
    updateCrq(null);
  }

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    const {name, checked} = event.currentTarget;
    if (name == delete_id) {
      if (crq.is_removed) {
        updateCrq({is_removed: checked});
      } else {
        setDeleteCRModal({
          is_open: true,
          crq_idx: crq_idx,
        });
      }
    } else if (name == required_id) {
      updateCrq({is_required: checked});
    }
  }

  return (
    <SummaryBox
      className={`radius-top-0 ${styles.summary_box} ${
        crq.is_removed ? `bg-base-lightest ${styles.is_removed}` : ''
      }`}
    >
      {is_crq_removed && (
        <div className="margin-bottom-05">
          <InlineAlert>
            This question will be deleted when the &quot;Save changes&quot; button is clicked and
            the meeting is saved. Un-check the &quot;Delete question&quot; button below to retain
            this question.
          </InlineAlert>
        </div>
      )}
      <SummaryBoxHeading
        headingLevel="h4"
        className={`margin-0 ${is_edit_mtg && crq.is_removed ? 'text-base-dark' : ''}`}
      >
        {crq_idx + 1}. {crq_type_message_dict[crq.type].config_title}
      </SummaryBoxHeading>
      <SummaryBoxContent>
        {!is_crq_removed && (
          <SectionDescription>
            Enter the question below. {crq_type_message_dict[crq.type].addtl_subtitle}
          </SectionDescription>
        )}
        <div className="display-flex flex-justify flex-wrap margin-left-neg-4">
          <section className="flex-grow margin-left-4">
            <Label htmlFor={question_id} className="maxw-none margin-top-2">
              <span className={label_classname}>Question text *</span>
            </Label>
            <TextInput
              id={question_id}
              name={question_id}
              type="text"
              defaultValue={crq.title}
              onChange={handleQuestionInputChange}
              validationStatus={isValid ? undefined : 'error'}
              disabled={is_crq_removed}
              required
            />
          </section>
          <section className="width-card margin-left-4">
            <Label htmlFor={required_id} className="maxw-none margin-top-2">
              <span className={label_classname}>Answer required</span>
            </Label>
            <div className="display-inline-flex">
              <Checkbox
                id={required_id}
                name={required_id}
                label="Required"
                checked={crq.is_required}
                className="margin-top-1 radius-md"
                onChange={handleCheckbox}
                disabled={is_crq_removed}
                tile
              />
            </div>
          </section>
        </div>
        {crq.type == 'radio' && (
          <CustomRegOptions
            setCrqs={setCrqs}
            crq={crq}
            crq_idx={crq_idx}
            setValidationMessages={setValidationMessages}
            is_edit_mtg={is_edit_mtg}
            setDeleteCRModal={setDeleteCRModal}
            setInvalidCrqIdsBySource={setInvalidCrqIdsBySource}
            setInvalidCroIds={setInvalidCroIds}
          />
        )}
        {is_edit_mtg && crq.pk ? (
          <div className="display-inline-flex width-full mobile-lg:width-auto">
            <Checkbox
              id={delete_id}
              name={delete_id}
              label="Delete question"
              checked={crq.is_removed}
              className="usa-checkbox--secondary usa-checkbox--secondary-unchecked margin-top-3 radius-md width-full"
              onChange={handleCheckbox}
              tile
            />
          </div>
        ) : (
          <button
            type="button"
            className={`usa-button usa-button--outline usa-button--outline-secondary ${styles.internal_crq_btn}`}
            onClick={handleDeleteQuestion}
          >
            Delete question
          </button>
        )}
      </SummaryBoxContent>
    </SummaryBox>
  );
}
