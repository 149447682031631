import React, {useEffect, useState} from 'react';

export interface AccordionItem<T> {
  prefix?: React.ReactNode | string;
  title: React.ReactNode | string;
  id: string;
  content: React.ReactNode;
  is_initially_expanded?: boolean;
  use_normal_text?: boolean;
  is_invalid?: boolean;
  is_disabled?: boolean;
  original_item: T;
}

interface Props<T> {
  accordion_items: AccordionItem<T>[];
}

interface ExpansionTracker {
  [k: string]: boolean | undefined;
}

export default function Accordion<T>({accordion_items}: Props<T>) {
  const [expansionTracker, setExpansionTracker] = useState<ExpansionTracker>({});

  useEffect(() => {
    setExpansionTracker((prevState) =>
      accordion_items.reduce(
        (state, item) => {
          state[item.id] = state[item.id] ?? !!item.is_initially_expanded;
          return state;
        },
        {...prevState}
      )
    );
  }, [accordion_items]);

  const handleToggle = (id: string) => {
    setExpansionTracker({...expansionTracker, [id]: !expansionTracker[id]});
  };

  return (
    <section className="usa-accordion margin-top-1">
      {accordion_items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <button
              className={`${item.use_normal_text ? 'text-normal' : ''} ${
                item.is_invalid ? 'border-2px border-secondary-dark' : ''
              } ${
                item.is_disabled ? 'border-2px border-base-light bg-white text-base' : ''
              } usa-accordion__button margin-bottom-1 word-break-word`}
              onClick={() => handleToggle(item.id)}
              aria-controls={item.id}
              aria-expanded={expansionTracker[item.id]}
              type="button"
            >
              {item.prefix}
              {item.title}
            </button>
            <section
              id={item.id}
              className="usa-prose margin-bottom-105"
              hidden={!expansionTracker[item.id]}
            >
              {item.content}
            </section>
          </React.Fragment>
        );
      })}
    </section>
  );
}
