import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {Link, useParams} from 'react-router-dom';
import {useQueryMeetingByID} from '../../../services/meeting-queries';
import {useQueryOtherRegistrant} from '../../../services/registrant-queries';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import EditRegistration from '../../EditRegistration/EditRegistration';
import {useMemo} from 'react';
import SuccessFalseError from '../../../lib/success-false-error';
import {RegFormVariant, reg_load_err, reg_not_found_err} from '../../../lib/utils';
import ErrorAlert from '../../../components/ErrorAlert/ErrorAlert';

export default function ViewOthersRegistrationWrapper() {
  const {meeting_id, user_id} = useParams();
  if (!meeting_id || !user_id) throw new Error('id not passed');
  const meeting_query = useQueryMeetingByID(meeting_id);
  const registrant_query = useQueryOtherRegistrant(meeting_id, user_id);

  const is_registrant_not_found = useMemo(() => {
    return (
      registrant_query.isError &&
      registrant_query.error instanceof SuccessFalseError &&
      registrant_query.error.data.message.includes('not found')
    );
  }, [registrant_query]);

  // This shouldn't render since we're using err boundary in the query
  if (meeting_query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <span>
            {meeting_query.isPending ? (
              'Loading meeting...'
            ) : (
              <Link to={`/meetings/${meeting_id}#registrants`} className="usa-breadcrumb__link">
                {meeting_query.data.title}
              </Link>
            )}
          </span>
        </Breadcrumb>
        <Breadcrumb current>
          <span>
            Registration details
            {registrant_query.data &&
              ` for ${registrant_query.data.first_name} ${registrant_query.data.last_name}`}
          </span>
        </Breadcrumb>
      </BreadcrumbBar>
      {meeting_query.isPending || registrant_query.isPending ? (
        <PageLoading message="Loading registration" />
      ) : registrant_query.isError ? (
        <ErrorAlert>{is_registrant_not_found ? reg_not_found_err : reg_load_err}</ErrorAlert>
      ) : (
        <EditRegistration
          meeting_id={meeting_id}
          user_id={user_id}
          meeting_data={meeting_query.data}
          registrant_data={registrant_query.data}
          form_variant={RegFormVariant.edit_others}
        />
      )}
    </>
  );
}
