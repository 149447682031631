import {Button} from '@trussworks/react-uswds';
import {ModalSwitchTypes} from '../../../types/auth-types';
import SupportEmailLink from '../../SupportEmailLink/SupportEmailLink';
import {useQueryClient} from '@tanstack/react-query';
import {auth_err_msg} from '../../../lib/utils';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';

interface Props {
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
  is_legal_notice?: boolean;
  is_piv?: boolean;
}

export default function AuthError({
  setCurrentModal,
  is_legal_notice = false,
  is_piv = false,
}: Props) {
  const query_client = useQueryClient();

  return (
    <>
      <ErrorAlert className="margin-top-0">{auth_err_msg}</ErrorAlert>
      <div>
        {is_piv && (
          <>
            <p>
              PIV sign in failed, or the selected PIV card certificate has not been associated with
              an existing account.
            </p>
            <p>
              If you would like to re-attempt to sign in via PIV card certificate, you may need to
              close all browser windows before trying again.
            </p>
          </>
        )}
        <p>
          Please{' '}
          <Button
            type="button"
            onClick={() => {
              if (is_legal_notice) {
                setCurrentModal(null);
                void query_client.invalidateQueries({queryKey: ['client-config'], type: 'all'});
              } else {
                setCurrentModal('auth-index');
              }
            }}
            className="usa-button--unstyled display-inline width-auto"
          >
            try signing in again
          </Button>
          .
        </p>
        <p className="font-sans-3xs margin-bottom-0">
          Trouble logging in? Please reach out to <SupportEmailLink />.
        </p>
      </div>
    </>
  );
}
