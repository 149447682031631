import {useState} from 'react';
import {AccountGetPutPiv} from '../../../../common/types/account-get-put-res';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation';
import DeletePivModal from '../DeletePivModal/DeletePivModal';
import {SetIsPivDeleteSuccess, DeletePivModalState} from '../../../types/piv-types';

interface Props {
  piv_certificates?: AccountGetPutPiv[];
  user_id: string;
  setIsPivDeleteSuccess: SetIsPivDeleteSuccess;
  handleSuccessfulPivDelete: () => void;
}

const initial_modal: DeletePivModalState = {
  isModalOpen: false,
  pk: null,
  user_id: null,
  serial: null,
};

export function AccountPivDetails({
  piv_certificates,
  user_id,
  setIsPivDeleteSuccess,
  handleSuccessfulPivDelete,
}: Props) {
  const [deletePivModal, setDeletePivModal] = useState<DeletePivModalState>(initial_modal);

  function handleCloseModal() {
    setDeletePivModal(initial_modal);
  }

  return (
    <div className="margin-top-4">
      <section className="bg-internal">
        {piv_certificates && piv_certificates.length ? (
          <>
            <h2 className="font-sans-md margin-bottom-0 padding-top-2 padding-left-2">
              PIV certificates
            </h2>
            {piv_certificates.map((piv) => {
              return (
                <AdditionalInformation
                  query_data={{...piv, user_id}}
                  info_type="piv"
                  key={piv.pk}
                  setDeletePivModal={setDeletePivModal}
                  setIsPivDeleteSuccess={setIsPivDeleteSuccess}
                />
              );
            })}
          </>
        ) : (
          <h2 className="font-sans-md padding-2">
            No PIV certificate associated with this account
          </h2>
        )}
      </section>
      <DeletePivModal
        deletePivModal={deletePivModal}
        closeModal={handleCloseModal}
        handleSuccessfulPivDelete={handleSuccessfulPivDelete}
      />
    </div>
  );
}
