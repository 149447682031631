import {app_version} from '../../../../common/lib/app-version';
import {getHostEnv} from '../../../../common/utils/env-utils';
import styles from './EnvironmentBanner.module.scss';

const env_banner_dict: {
  [key in ReturnType<typeof getHostEnv>]: {text: string; class_name: string} | undefined;
} = {
  'phmsa-prod': undefined,
  'phmsa-stage': {text: 'PHMSA-STAGE SERVER', class_name: 'stage'},
  'phmsa-uat': {text: 'PHMSA-UAT SERVER', class_name: 'uat'},
  'csnw-stable': {text: 'CSNW-STABLE SERVER', class_name: 'stage'},
  'csnw-uat': {text: 'CSNW-UAT SERVER', class_name: 'uat'},
  'csnw-ci': {text: 'CSNW-CI SERVER', class_name: 'ci'},
  'dev': {text: 'DEVELOPMENT SERVER', class_name: 'dev'},
  'unknown': {text: 'UNKNOWN SERVER', class_name: 'unknown'},
};

export default function EnvironmentBanner() {
  const host_env = getHostEnv(window.location.hostname);
  const env_banner = env_banner_dict[host_env];

  return (
    env_banner && (
      <section
        className={`${
          styles[env_banner.class_name]
        } text-center padding-y-05 text-white border-bottom-2px border-black`}
        data-testid="env-banner"
      >
        {env_banner.text} - v{app_version}
      </section>
    )
  );
}
