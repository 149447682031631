import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useQueryMeetingByID} from '../../../services/meeting-queries';
import {useQueryRegistrant} from '../../../services/registrant-queries';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';
import EditRegistration from '../EditRegistration';
import SuccessFalseError from '../../../lib/success-false-error';
import {useEffect, useState} from 'react';
import {RegFormVariant} from '../../../lib/utils';

export default function EditRegistrationWrapper() {
  const {id} = useParams();
  if (!id) throw new Error('id not passed');
  const meeting_query = useQueryMeetingByID(id);
  const registrant_query = useQueryRegistrant(id);
  const [canRedirect, setCanRedirect] = useState(true);
  const navigate = useNavigate();

  const is_registrant_not_found =
    registrant_query.isError &&
    registrant_query.error instanceof SuccessFalseError &&
    registrant_query.error.data.message.includes('not found');

  // If user is not registered for this meeting, send them to /register. This is a one-shot check. We do not want this
  // wrapper hijacking navigation when a registration is deleted.
  useEffect(() => {
    if (!canRedirect || registrant_query.isPending || meeting_query.isPending) return;
    if (!meeting_query.isError && is_registrant_not_found) {
      navigate(`/meetings/${id}/register`, {replace: true});
    }
    setCanRedirect(false);
  }, [
    canRedirect,
    setCanRedirect,
    registrant_query.isPending,
    is_registrant_not_found,
    meeting_query.isError,
    meeting_query.isPending,
    id,
    navigate,
  ]);

  // If the logic here changes, be sure to review the condition for <PageLoading /> below. There are two cases where we
  // intentionally allow (registrant_query.isError == true) to slip through:
  // 1. if the redirect handler will be able to take over: (canRedirect == true && is_registrant_not_found == true)
  //    - We let the page loader spin while waiting for the redirect to /meetings/:mtg_id/register.
  // 2. if registration has just been deleted: (registrant_query.isError == true && registrant_query.data != undefined)
  //    - It is important to make sure the RemoveRegistrationButton component is not unmounted before the onSuccess
  //      callback runs for the delete mutation. Otherwise, the user will not be redirected back to meeting details.
  //    - Even if (registrant_query.isError == true), so long as data was available at some point, that data will
  //      continue to be available in registrant_query.data. So, when the delete registration mutation completes and
  //      (registrant_query.isError == true), we can still safely send registrant_query.data to the EditRegistration
  //      component, avoiding premature unmount of the RemoveRegistrationButton component.
  if (
    meeting_query.isError ||
    (registrant_query.isError &&
      !(canRedirect && is_registrant_not_found) &&
      !registrant_query.data)
  ) {
    throw new Error('Meeting and/or registration data not available');
  }

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <span>
            {meeting_query.isPending ? (
              'Loading meeting...'
            ) : (
              <Link to={`/meetings/${id}`} className="usa-breadcrumb__link">
                {meeting_query.data.title}
              </Link>
            )}
          </span>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Edit registration</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {
        // This might look a little odd to continue showing the page loader if the registrant_query is in an error state
        // but we want to give the redirect handler (see useEffect) a chance to send the user to the new registration
        // page if the reason for the error is "registration not found". We only get here if this error is guaranteed to
        // be handled with a redirect.
        meeting_query.isPending ||
        registrant_query.isPending ||
        (registrant_query.isError && !registrant_query.data) ? (
          <PageLoading message="Loading registration" />
        ) : (
          <EditRegistration
            meeting_id={id}
            meeting_data={meeting_query.data}
            registrant_data={registrant_query.data}
            form_variant={RegFormVariant.edit}
          />
        )
      }
    </>
  );
}
