import {fromUTCtoET, getRegistrationFormatStr} from '../../../lib/utils';
import {useMemo, useState} from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  RegistrantsGetData,
  RegistrantsGetDataRegistrant,
} from '../../../../common/types/registrants-get-res';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import {Link} from 'react-router-dom';
import TableUI from '../../TableUI/TableUI';
import {MeetingsGetByIdData} from '../../../../common/types/meetings-get-by-id-res';
import LongFieldWithTooltip from '../../LongFieldWithTooltip/LongFieldWithTooltip';

interface Props {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
  registrants_data: RegistrantsGetData;
  globalFilter: string;
  setGlobalFilter: (value: React.SetStateAction<string>) => void;
}

export default function ViewRegistrantsTable({
  meeting_id,
  meeting_data,
  registrants_data,
  globalFilter,
  setGlobalFilter,
}: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const columns = useMemo(() => {
    const primary_cols: ColumnDef<RegistrantsGetDataRegistrant>[] = [
      {
        accessorKey: 'first_name',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.first_name.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'last_name',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.last_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: getRegistrationFormatStr,
        header: 'Format',
        id: 'format_info',
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => (row.did_attend ? 'Yes' : 'No'),
        header: 'Did attend?',
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        accessorKey: 'email',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.email.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.org ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.org.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.job_title ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: metadata_client_account.job_title.title,
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        accessorKey: 'create_datetime',
        cell: (info) => fromUTCtoET(info.getValue<string>()),
        header: 'Registration time',
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.phone ?? '',
        header: metadata_client_account.phone.title,
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.comments ?? '',
        cell: (info) => <LongFieldWithTooltip text={info.getValue<string>()} />,
        header: 'Comments',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'user_fk',
        cell: (info) => (
          <Link
            to={`/meetings/${meeting_id}/registrants/${info.getValue<string>()}`}
            className="usa-link"
          >
            Details
          </Link>
        ),
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ];

    const crq_cols = meeting_data.cr_questions.map<ColumnDef<RegistrantsGetDataRegistrant>>(
      (crq) => ({
        accessorFn: (row) => {
          // Right now, we only allow a single response.
          // This logic will need to be updated if we enable multi-select CRQs in the future
          return (
            row.cr_responses.find((cr_res) => cr_res.cr_question_fk == crq.pk)?.responses[0] ?? ''
          );
        },
        cell: (info) => {
          const response = info.getValue<string>();

          const display_response = crq.cr_options
            ? crq.cr_options.find((cr_opt) => cr_opt.pk == response)?.title
            : response;

          return <LongFieldWithTooltip text={display_response} data_id={crq.pk} />;
        },
        header: () => <LongFieldWithTooltip text={crq.title} data_id={crq.pk} />,
        id: crq.pk,
        enableSorting: false,
        enableGlobalFilter: false,
      })
    );

    return [...primary_cols, ...crq_cols];
  }, [meeting_data.cr_questions, meeting_id]);

  const table = useReactTable({
    data: registrants_data,
    columns: columns,
    state: {sorting, globalFilter},
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnPinning: {
        right: ['user_fk'],
      },
    },
  });

  return (
    <TableUI
      table={table}
      clickedIndex={clickedIndex}
      setClickedIndex={setClickedIndex}
      variant="view_registrants_table"
    />
  );
}
