import {fromUTCtoET} from '../../../lib/utils';
import {RegistrationEndDateInfo} from '../../../types/meeting-types';

interface Props {
  meeting_info: RegistrationEndDateInfo;
}

export default function InPersonVirtualInformation({meeting_info}: Props) {
  const in_person_reg_deadline = meeting_info.in_person_reg_end_datetime
    ? fromUTCtoET(meeting_info.in_person_reg_end_datetime)
    : '';

  const virtual_reg_deadline = meeting_info.virtual_reg_end_datetime
    ? fromUTCtoET(meeting_info.virtual_reg_end_datetime)
    : '';

  const meeting_format =
    meeting_info.is_in_person && meeting_info.is_virtual
      ? ' in person and virtually'
      : meeting_info.is_in_person
      ? ' in person'
      : meeting_info.is_virtual
      ? ' virtually'
      : '';

  return (
    <p className="line-height-sans-4 margin-bottom-3 font-body-md">
      This meeting is taking place{meeting_format}.
      {meeting_info.is_in_person &&
      meeting_info.is_virtual &&
      !in_person_reg_deadline &&
      !virtual_reg_deadline ? (
        <> Registration deadlines have not been set.</>
      ) : (
        <>
          {meeting_info.is_in_person &&
            (in_person_reg_deadline ? (
              <>
                {' '}
                Registration for in-person attendance closes{' '}
                <span className="text-bold">{in_person_reg_deadline}</span>.
              </>
            ) : (
              <> An in-person registration deadline has not been set.</>
            ))}
          {meeting_info.is_virtual &&
            (virtual_reg_deadline ? (
              <>
                {' '}
                Registration for virtual attendance closes{' '}
                <span className="text-bold">{virtual_reg_deadline}</span>.
              </>
            ) : (
              <> A virtual registration deadline has not been set.</>
            ))}
        </>
      )}
    </p>
  );
}
