import {ErrorResBody} from '../../common/types/error-response';

export default class SuccessFalseError extends Error {
  // Mimicking structure of AxiosError with .response.data
  response: {data: ErrorResBody};
  constructor(public data: ErrorResBody) {
    super('Request failed with status code 200');
    this.name = 'SuccessFalseError';
    this.response = {data: data};
  }
}
