import React from 'react';

interface Props {
  is_broadcast?: true;
  children: React.ReactNode;
}

export default function InlineAlert({is_broadcast, children}: Props) {
  return (
    <p
      className={`${
        is_broadcast ? 'text-magenta text-bold' : 'text-secondary'
      } text-italic font-sans-xs margin-y-0`}
    >
      {children}
    </p>
  );
}
