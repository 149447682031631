import {
  BreadcrumbBar,
  Breadcrumb,
  Checkbox,
  Tag,
  FormGroup,
  Label,
  TextInput,
} from '@trussworks/react-uswds';
import {Link} from 'react-router-dom';
import {useMutationReattemptEmail, useQueryEmails} from '../../services/email-queries';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import {useMemo, useState} from 'react';
import QuestionTooltip from '../../components/QuestionTooltip/QuestionTooltip';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {EmailsGetDataEmail} from '../../../common/types/emails-get-res';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import TableUI from '../../components/TableUI/TableUI';
import styles from './EmailQueue.module.scss';
import metadata_client_email_queue from '../../lib/metadata-client-email-queue.json';
import TagAlt from '../../components/TagAlt/TagAlt';
import {
  email_reattempt_err,
  email_reattempt_success,
  email_status_background_dict,
  email_type_dict,
  fromUTCtoET,
} from '../../lib/utils';
import ReattemptButton from '../../components/MeetingComponents/ReattemptButton/ReattemptButton';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

export default function EmailQueue() {
  usePageTitle('Email queue');

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'status',
      value: '',
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const query = useQueryEmails();
  const mutation = useMutationReattemptEmail();

  const columns = useMemo<ColumnDef<EmailsGetDataEmail>[]>(
    () => [
      {
        accessorKey: 'create_datetime',
        cell: (info) => {
          const formatted_date = fromUTCtoET(
            info.getValue<EmailsGetDataEmail['create_datetime']>(),
            {use_long_date: true}
          );
          return <span className="font-sans-2xs">{formatted_date}</span>;
        },
        header: metadata_client_email_queue.create_datetime.title,
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'status',
        cell: (info) => {
          const status = info.getValue<EmailsGetDataEmail['status']>();
          return (
            <Tag className={`${email_status_background_dict[status]} text-semibold font-sans-2xs`}>
              {status}
            </Tag>
          );
        },
        header: metadata_client_email_queue.status.title,
        enableGlobalFilter: false,
        filterFn: (row, column_id, filter_value) => {
          const curr_value = row.getValue<EmailsGetDataEmail['status']>('status');
          // Could be consolidated, but separate returns are much easier to parse visually
          if (filter_value == 'show_only_unsent' && curr_value != 'unsent') return false;
          return true;
        },
      },
      {
        accessorKey: 'type',
        cell: (info) => {
          const email_type = info.getValue<EmailsGetDataEmail['type']>();
          return (
            <TagAlt
              display_value={email_type_dict[email_type].name}
              color={email_type_dict[email_type].tag_color}
            />
          );
        },
        header: metadata_client_email_queue.type.title,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.to_emails.join(', '),
        header: metadata_client_email_queue.to_emails.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'email_subject',
        header: metadata_client_email_queue.email_subject.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => ({pk: row.pk, child_email_fk: row.child_email_fk, status: row.status}),
        id: 'show_reattempt',
        cell: (info) => {
          const {pk, child_email_fk, status} =
            info.getValue<Pick<EmailsGetDataEmail, 'pk' | 'child_email_fk' | 'status'>>();
          if (status != 'unsent') return;
          return <ReattemptButton pk={pk} child_email_fk={child_email_fk} mutation={mutation} />;
        },
        header: 'Reattempt?',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorKey: 'pk',
        cell: (info) => {
          const pk = info.getValue<EmailsGetDataEmail['pk']>();
          return (
            <Link to={`/email-queue/${pk}`} className="usa-link text-no-wrap" data-user_pk={pk}>
              Details
            </Link>
          );
        },
        header: undefined,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [mutation]
  );

  const table = useReactTable({
    data: query.data ?? [],
    columns,
    state: {sorting, columnFilters, globalFilter},
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  function handleShowUnsentChange() {
    setColumnFilters((prevState) => {
      const new_filters_state = [...prevState];
      const status_filter_idx = new_filters_state.findIndex((filter) => filter.id == 'status');
      if (status_filter_idx == -1) throw new Error('state should exist');
      new_filters_state[status_filter_idx] = {
        ...new_filters_state[status_filter_idx],
        value: !new_filters_state[status_filter_idx].value ? 'show_only_unsent' : '',
      };
      return new_filters_state;
    });
  }

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Email queue</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {mutation.isSuccess && (
        <SuccessAlert>
          <span>
            {email_reattempt_success} Please{' '}
            <Link
              className="usa-button usa-button--unstyled"
              to={`/email-queue/${mutation.data.child_email_fk}`}
            >
              see details
            </Link>{' '}
            to to verify if the reattempted email&apos;s status is SENT or UNSENT.
          </span>
        </SuccessAlert>
      )}
      {mutation.isError && (
        <FormGenericError message={email_reattempt_err} error={mutation.error} />
      )}
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        {/* eslint-disable-next-line max-len */}
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search emails and subjects
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
        <section className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1">
          <span className="text-italic margin-right-0 mobile-lg:margin-right-5">Filters</span>
          {/* eslint-disable-next-line max-len */}
          <div className="grid-row flex-no-wrap margin-top-1 mobile-lg:margin-top-0 mobile-lg:margin-right-2 width-full mobile-lg:width-auto">
            <Checkbox
              id="show_only_unsent"
              name="show_only_unsent"
              label="Show unsent emails only"
              checked={
                columnFilters.find((filter) => filter.id == 'status')?.value == 'show_only_unsent'
              }
              onChange={handleShowUnsentChange}
              className="margin-right-1 width-full radius-md mobile-lg:width-auto"
              tile
            />
            <QuestionTooltip id="show_unverified_tooltip" className={`height-5 ${styles.tooltip}`}>
              When this box is checked, only unsent emails will appear in the list below
            </QuestionTooltip>
          </div>
        </section>
      </section>
      {query.isPending ? (
        <PageLoading message="Loading emails" />
      ) : (
        <TableUI
          table={table}
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
          variant="email_queue_table"
        />
      )}
    </>
  );
}
