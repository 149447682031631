import {Suspense, lazy} from 'react';
import {FormPhoneInputProps} from './FormPhoneInput';

const FormPhoneInput = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "phone-input" */
      './FormPhoneInput'
    )
);

export default function FormPhoneInputLazy(props: FormPhoneInputProps) {
  return (
    <Suspense fallback={<span>Loading input...</span>}>
      <FormPhoneInput {...props} />
    </Suspense>
  );
}
