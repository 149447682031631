import {Component, ErrorInfo, ReactNode} from 'react';
import SupportEmailLink from '../SupportEmailLink/SupportEmailLink';
import {Alert} from '@trussworks/react-uswds';

interface Props {
  children?: ReactNode;
  field_name: string;
  is_editor?: boolean;
}

interface State {
  hasError: boolean;
}

class RemirrorErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (g_is_dev) console.error('Uncaught error:', error, errorInfo);
  }

  private heading = this.props.is_editor
    ? `An error has occurred in the rich text editor for the ${this.props.field_name} field.`
    : `An error has occurred displaying ${this.props.field_name} information.`;

  public render() {
    if (this.state.hasError) {
      return (
        <Alert
          type="error"
          headingLevel="h2"
          heading={this.heading}
          className="margin-bottom-3"
          slim
        >
          Please refresh the page. If the error persists, please reach out to <SupportEmailLink />.
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default RemirrorErrorBoundary;
