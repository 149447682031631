import {Tooltip} from 'react-tooltip';
import {useEffect, useId, useRef, useState} from 'react';
import styles from './LongFieldWithTooltip.module.scss';

interface Props {
  text: string | null | undefined;
  data_id?: string;
  tooltip_max_width?: React.CSSProperties['maxWidth'];
  content_max_width?: React.CSSProperties['maxWidth'];
}

export default function LongFieldWithTooltip({
  text,
  data_id,
  tooltip_max_width,
  content_max_width,
}: Props) {
  const id = useId();
  const div_ref = useRef<HTMLDivElement>(null);
  const [needsTooltip, setNeedsTooltip] = useState(false);
  const display_text = text ?? '';

  useEffect(() => {
    const div = div_ref.current;
    if (div && div.scrollWidth > div.offsetWidth) {
      setNeedsTooltip(true);
    }
  }, [display_text]);

  return (
    <div
      id={id}
      data-id={data_id}
      ref={div_ref}
      className={styles.long_field}
      style={{maxWidth: content_max_width ?? '20em'}}
    >
      <span>{display_text}</span>
      {needsTooltip && (
        <Tooltip
          anchorSelect={`#${CSS.escape(id)}`}
          place="bottom"
          className={styles.reg_tooltip}
          style={{maxWidth: tooltip_max_width ?? '30em'}}
        >
          {display_text}
        </Tooltip>
      )}
    </div>
  );
}
