import MeetingStatusAccordion from './MeetingStatusAccordion/MeetingStatusAccordion';
import MeetingStatusRadio from './MeetingStatusRadio/MeetingStatusRadio';
import {StatusesGetData} from '../../../../../common/types/statuses-get-res';
import {MeetingForm} from '../../../../types/meeting-types';
import {SetValidationMessages} from '../../../../types/form-types';
import InlineAlert from '../../InlineAlert/InlineAlert';
import {broadcast_email_message} from '../../../../lib/utils';
import SectionTitle from '../../SectionTitle/SectionTitle';

interface Props {
  mtg_title?: string;
  statuses_data: StatusesGetData;
  selected_status_fk: MeetingForm['status_fk'];
  setFormData: React.Dispatch<React.SetStateAction<MeetingForm>>;
  setValidationMessages: SetValidationMessages;
  is_edit_mtg: boolean;
  is_initial_unlisted?: boolean;
}

export default function MeetingStatus({
  mtg_title,
  statuses_data,
  selected_status_fk,
  setFormData,
  setValidationMessages,
  is_edit_mtg,
  is_initial_unlisted,
}: Props) {
  // If a meeting with status is_unlisted: false is changed to cancelled or postponed, render broadcast email warning
  const selected_status = statuses_data.find((status) => status.pk == selected_status_fk);
  if (!selected_status) throw new Error('No status selected');

  return (
    <section className="margin-top-3">
      <SectionTitle>Status</SectionTitle>
      <MeetingStatusAccordion statuses_data={statuses_data} />
      {is_edit_mtg &&
        !is_initial_unlisted &&
        (selected_status.name == 'postponed' || selected_status.name == 'cancelled') && (
          <InlineAlert is_broadcast={true}>{broadcast_email_message}</InlineAlert>
        )}
      <MeetingStatusRadio
        mtg_title={mtg_title}
        statuses_data={statuses_data}
        selected_status_fk={selected_status_fk}
        setFormData={setFormData}
        setValidationMessages={setValidationMessages}
        is_edit_mtg={is_edit_mtg}
        is_initial_unlisted={is_initial_unlisted}
      />
    </section>
  );
}
