import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DnDItem} from '../DnDContextProvider';
import styles from './SortableItem.module.scss';
import DisplayItem from '../DisplayItem/DisplayItem';

interface SortableItemProps {
  item: DnDItem;
}

export function SortableItem({item}: SortableItemProps) {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: item.id});

  // Used the same transform/transition arguments used in the sortable demo:
  // https://docs.dndkit.com/presets/sortable/usesortable#transition-1
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DisplayItem
      className={styles.sortable}
      id={item.id}
      item={item}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
}
