import {Link} from 'react-router-dom';
import SupportEmailLink from '../../components/SupportEmailLink/SupportEmailLink';
import {Alert} from '@trussworks/react-uswds';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {visit_homepage_title} from '../../lib/utils';

interface LoginErrorProps {
  is_disabled_user?: boolean;
  is_login_gov?: boolean;
}

export default function LoginError({is_disabled_user = false, is_login_gov}: LoginErrorProps) {
  usePageTitle('Login Error');
  return (
    <div>
      <h2>Unable to complete sign in process</h2>
      {is_disabled_user ? (
        <Alert type="error" heading="Your account has been disabled" headingLevel="h3">
          Your account has been disabled. Please reach out to support at: <SupportEmailLink /> for
          assistance.
        </Alert>
      ) : (
        <>
          <p className="usa-intro">There was an issue signing you in.</p>
          <p>
            {!is_login_gov &&
              'It could be that the link you followed was expired or had already been used. '}
            Please try signing in again.
          </p>
          <p>
            If the problem persists, or if you&#39;re having trouble with account creation or sign
            in, please reach out to&#160;
            <SupportEmailLink />.
          </p>
        </>
      )}

      <Link to="/" className="usa-button margin-top-1">
        {visit_homepage_title}
      </Link>
    </div>
  );
}
