import {useEffect, useState} from 'react';
import {Label, TextInput} from '@trussworks/react-uswds';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import metadata_client_broadcast from '../../../lib/metadata-client-broadcast.json';
import metadata_client_meeting from '../../../lib/metadata-client-meeting.json';
import {
  addOrRemoveValidationMessage,
  getLengthValidationMsg,
  getMinMaxValidationMsg,
  getNumValidationMsg,
} from '../../../lib/utils';
import {
  FormType,
  HandleInputChange,
  MetadataValue,
  SemiUncontrolled,
  SetValidationMessages,
} from '../../../types/form-types';
import {useDebouncedCallback} from 'use-debounce';
import SectionDescription from '../SectionDescription/SectionDescription';

type Props = {
  id_name: string;
  input: string;
  handleChange: HandleInputChange;
  setValidationMessages: SetValidationMessages;
  is_required?: boolean;
  is_disabled?: boolean;
  is_validate_length?: boolean;
  is_invalid?: boolean;
  form_type: FormType;
  variant?: 'number';
} & SemiUncontrolled &
  ({include_desc?: boolean; custom_desc?: never} | {include_desc?: never; custom_desc?: string});

/**
 * @param use_semi_uncontrolled Use the form component as a hybrid between controlled and uncontrolled form.
 * In semi-uncontrolled mode, the default value is initially set by React,
 * and though the displayed input is controlled by the DOM, the change is still tracked in React's state via onChange.
 */
export default function FormTextInput({
  id_name,
  input,
  handleChange,
  setValidationMessages,
  is_required,
  is_disabled,
  is_validate_length,
  is_invalid,
  include_desc,
  custom_desc,
  form_type,
  variant,
  use_semi_uncontrolled,
  debounce_change_ms,
}: Props) {
  const [isValid, setIsValid] = useState(true);
  // Tip: if debounce_change_ms is falsy, then this callback doesn't get used
  const handleChangeDebounced = useDebouncedCallback(handleChange, debounce_change_ms);

  let metadata_obj: MetadataValue;
  if (form_type == 'meeting') {
    metadata_obj = metadata_client_meeting[id_name as keyof typeof metadata_client_meeting];
  } else if (form_type == 'broadcast') {
    metadata_obj = metadata_client_broadcast[id_name as keyof typeof metadata_client_broadcast];
  } else {
    metadata_obj = metadata_client_account[id_name as keyof typeof metadata_client_account];
  }

  let _validation_message!: string;
  if (is_required) {
    if (!('validation_message' in metadata_obj)) {
      throw new Error(
        `Please add "validation_message" in metadata-client-account.json for required properties in ${id_name}`
      );
    } else {
      _validation_message = metadata_obj.validation_message;
    }
  }

  let _max_length!: number;
  if (is_validate_length) {
    if (!('max_length' in metadata_obj)) {
      throw new Error(
        `Please add "max_length" in metadata-client-account.json for required properties in ${id_name}`
      );
    } else {
      _max_length = metadata_obj.max_length;
    }
  }

  let description!: string;
  if (include_desc) {
    if (!('description' in metadata_obj)) {
      throw new Error(
        `Please add "description" in metadata-client for required properties in ${id_name}`
      );
    } else {
      description = metadata_obj.description;
    }
  }

  let minimum!: number, maximum!: number;
  if (variant == 'number') {
    if (!('minimum' in metadata_obj)) {
      throw new Error(
        `Please add "minimum" in metadata-client for required properties in ${id_name}`
      );
    } else {
      minimum = metadata_obj.minimum;
    }

    if (!('maximum' in metadata_obj)) {
      throw new Error(
        `Please add "maximum" in metadata-client for required properties in ${id_name}`
      );
    } else {
      maximum = metadata_obj.maximum;
    }

    if (is_validate_length) {
      throw new Error(`"Number" variant inputs cannot validate length in ${id_name}`);
    }
  }

  const number_validation = getNumValidationMsg(metadata_obj.title);
  const minimum_validation = getMinMaxValidationMsg(metadata_obj.title, minimum, {is_min: true});
  const maximum_validation = getMinMaxValidationMsg(metadata_obj.title, maximum, {is_min: false});

  useEffect(() => {
    let _is_valid = !is_invalid;

    if (is_required) {
      const does_input_exist = !!input;
      _is_valid = does_input_exist;
      addOrRemoveValidationMessage({
        is_condition_met: !does_input_exist,
        err_message: _validation_message,
        setValidationMessages,
      });
    }

    if (is_validate_length && variant != 'number') {
      const is_too_long = input.length > _max_length;
      _is_valid = _is_valid && !is_too_long;
      addOrRemoveValidationMessage({
        is_condition_met: is_too_long,
        err_message: getLengthValidationMsg(metadata_obj.title, _max_length),
        setValidationMessages,
      });
    }

    if (variant == 'number') {
      const only_num_regex = /^0$|^[1-9][0-9]*$/;
      const is_not_only_num = !!(input && !only_num_regex.test(input));
      _is_valid = _is_valid && !is_not_only_num;
      addOrRemoveValidationMessage({
        is_condition_met: is_not_only_num,
        err_message: number_validation,
        setValidationMessages,
      });

      if (only_num_regex.test(input)) {
        const num_val = parseInt(input);
        const is_too_low = num_val < minimum;
        _is_valid = _is_valid && !is_too_low;
        addOrRemoveValidationMessage({
          is_condition_met: is_too_low,
          err_message: minimum_validation,
          setValidationMessages,
        });

        const is_too_high = num_val > maximum;
        _is_valid = _is_valid && !is_too_high;
        addOrRemoveValidationMessage({
          is_condition_met: is_too_high,
          err_message: maximum_validation,
          setValidationMessages,
        });
      }
    }

    setIsValid(_is_valid);
  }, [
    _max_length,
    _validation_message,
    input,
    is_required,
    is_invalid,
    is_validate_length,
    maximum,
    maximum_validation,
    minimum,
    minimum_validation,
    number_validation,
    setValidationMessages,
    variant,
    metadata_obj.title,
  ]);

  const control_props = use_semi_uncontrolled
    ? {defaultValue: input, onChange: debounce_change_ms ? handleChangeDebounced : handleChange}
    : {value: input, onChange: handleChange};

  return (
    <>
      <Label htmlFor={id_name} className="maxw-none">
        <span className="text-primary text-semibold">
          {metadata_obj.title}
          {is_required && ' *'}
        </span>
      </Label>
      {include_desc && <SectionDescription>{description}</SectionDescription>}
      {custom_desc && <SectionDescription>{custom_desc}</SectionDescription>}
      <TextInput
        id={id_name}
        name={id_name}
        type="text"
        {...control_props}
        validationStatus={isValid ? undefined : 'error'}
        disabled={is_disabled}
        required={is_required}
      />
    </>
  );
}
