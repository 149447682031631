import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {useQueryRoles} from '../../../services/account-queries';
import CreateAccount from '../CreateAccount';
import {Link} from 'react-router-dom';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';

export default function CreateAccountWrapper() {
  const roles = useQueryRoles();

  // This shouldn't render since we're using err boundary in the query
  if (roles.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to="/manage-accounts" className="usa-breadcrumb__link">
            Manage accounts
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>Create new account</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {roles.isPending ? <PageLoading /> : <CreateAccount roles_data={roles.data} />}
    </>
  );
}
