import {
  Checkbox,
  Grid,
  SummaryBox,
  SummaryBoxContent,
  SummaryBoxHeading,
} from '@trussworks/react-uswds';
import {MeetingForm} from '../../../../types/meeting-types';
import {handleTypedCheckbox} from '../../../FormComponents/FormCheckbox/FormCheckbox';
import metadata_client_meeting from '../../../../lib/metadata-client-meeting.json';
import FormTextInput from '../../../FormComponents/FormTextInput/FormTextInput';
import {
  addOrRemoveValidationMessage,
  format_title,
  getCutoffBeforeEndValidationMsg,
  getLengthValidationMsg,
  getPossibleErrMessages,
  handleTypedInputChange,
  isHttpLinkValid,
  format_required_message,
  broadcast_email_message,
  debounce_delay_ms,
  handleTypedRtfChange,
  removeValidationMessages,
  isInMeetingFormKeys,
} from '../../../../lib/utils';
import {SetValidationMessages} from '../../../../types/form-types';
import FormDatetimeInput from '../../FormDatetimeInput/FormDatetimeInput';
import FormTextArea from '../../../FormComponents/FormTextArea/FormTextArea';
import {ChangeEvent, useEffect, useState} from 'react';
import InlineAlert from '../../InlineAlert/InlineAlert';
import PrevTimeInfo from '../PrevTimeInfo/PrevTimeInfo';
import ConfirmFormatChangeModal from './ConfirmFormatChangeModal/ConfirmFormatChangeModal';
import SectionTitle from '../../SectionTitle/SectionTitle';
import {MeetingsGetByIdData} from '../../../../../common/types/meetings-get-by-id-res';

const other_in_person_validation = {
  address_validation: getLengthValidationMsg(
    metadata_client_meeting.in_person_address.title,
    metadata_client_meeting.in_person_address.max_length
  ),
  addtl_info_validation: getLengthValidationMsg(
    metadata_client_meeting.in_person_info.title,
    metadata_client_meeting.in_person_info.max_length
  ),
  cutoff_validation: metadata_client_meeting.in_person_reg_end_datetime.validation_message,
  in_person_cutoff_before_end_validation_msg: getCutoffBeforeEndValidationMsg('In-person'),
};

const possible_in_person_err_messages = getPossibleErrMessages(
  metadata_client_meeting.in_person_capacity,
  Object.values(other_in_person_validation)
);

const other_virtual_validation = {
  address_validation: getLengthValidationMsg(
    metadata_client_meeting.virtual_link.title,
    metadata_client_meeting.virtual_link.max_length
  ),
  addtl_info_validation: getLengthValidationMsg(
    metadata_client_meeting.virtual_info.title,
    metadata_client_meeting.virtual_info.max_length
  ),
  cutoff_validation: metadata_client_meeting.virtual_reg_end_datetime.validation_message,
  virtual_cutoff_before_end_validation_msg: getCutoffBeforeEndValidationMsg('Virtual'),
  // eslint-disable-next-line max-len
  invalid_link_format: `${metadata_client_meeting.virtual_link.title} must be a valid link, beginning with "https://..."`,
};

const possible_virtual_err_messages = getPossibleErrMessages(
  metadata_client_meeting.virtual_capacity,
  Object.values(other_virtual_validation)
);

type Props = {
  formData: MeetingForm;
  setFormData: React.Dispatch<React.SetStateAction<MeetingForm>>;
  setValidationMessages: SetValidationMessages;
  is_date_required: boolean;
  is_format_required?: boolean;
  is_initial_unlisted?: boolean;
} & (
  | {
      is_edit_mtg: true;
      query_data: MeetingsGetByIdData;
    }
  | {
      is_edit_mtg?: never;
      query_data?: never;
    }
);

interface ModalInfo {
  is_open: boolean;
  target_name: string | null;
}

export default function MeetingFormat({
  query_data,
  formData,
  setFormData,
  setValidationMessages,
  is_date_required,
  is_format_required,
  is_edit_mtg,
  is_initial_unlisted,
}: Props) {
  const [isCutoffAfterEnd, setIsCutoffAfterEnd] = useState({in_person: false, virtual: false});
  const [isVirtualLinkValid, setIsVirtualLinkValid] = useState(true);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    is_open: false,
    target_name: null,
  });

  const handleCheckbox = handleTypedCheckbox(setFormData);
  const handleInputChange = handleTypedInputChange(setFormData);
  const handleDatetimeInputChange = (key: string, value: string | null) => {
    if (!isInMeetingFormKeys(['in_person_reg_end_datetime', 'virtual_reg_end_datetime'], key))
      return;
    setFormData((prev) => {
      const new_state = {...prev};
      new_state[key] = value;
      return new_state;
    });
  };
  const handleRtfAreaChange = handleTypedRtfChange(setFormData);

  // Opening the modal causes us to lose access to the check box's `event`, because it's now referring to the modal
  // This means we can't use handleCheckbox (or access `event.target`) here
  function handleUncheck() {
    const name = modalInfo.target_name;
    if (!name) throw new Error();
    setFormData((formData) => ({...formData, [name]: false}));
  }

  function handleCheckChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.checked) {
      handleCheckbox(e);
    } else {
      const target = e.target;
      target.blur();
      setModalInfo({is_open: true, target_name: target.name});
    }
  }

  const modal_format =
    modalInfo.target_name == 'is_virtual'
      ? metadata_client_meeting.is_virtual.title
      : modalInfo.target_name == 'is_in_person'
      ? metadata_client_meeting.is_in_person.title
      : 'Invalid Format';

  const is_neither_format_checked =
    !!is_format_required && !formData.is_in_person && !formData.is_virtual;
  useEffect(() => {
    // Only needs to validate that one format is checked if is_format_required is true
    addOrRemoveValidationMessage({
      is_condition_met: is_neither_format_checked,
      err_message: format_required_message,
      setValidationMessages,
    });
  }, [
    formData.is_in_person,
    formData.is_virtual,
    is_format_required,
    is_neither_format_checked,
    setValidationMessages,
  ]);

  useEffect(() => {
    if (formData.is_in_person) {
      const mtg_end_datetime = formData.end_datetime;
      const cutoff_datetime = formData.in_person_reg_end_datetime;

      const is_cutoff_after_end =
        !!mtg_end_datetime && !!cutoff_datetime && cutoff_datetime > mtg_end_datetime;
      addOrRemoveValidationMessage({
        is_condition_met: is_cutoff_after_end,
        err_message: other_in_person_validation.in_person_cutoff_before_end_validation_msg,
        setValidationMessages,
      });

      setIsCutoffAfterEnd((prevState) => {
        return {...prevState, in_person: is_cutoff_after_end};
      });
    } else {
      // Remove all validation messages when is_in_person is unmounted
      removeValidationMessages({
        err_messages: possible_in_person_err_messages,
        setValidationMessages,
      });
      // Reset data when In-person is unchecked
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          in_person_address: '',
          in_person_capacity: '',
          in_person_info: '',
          in_person_reg_end_datetime: '',
        };
      });
    }
  }, [
    formData.end_datetime,
    formData.in_person_reg_end_datetime,
    formData.is_in_person,
    is_date_required,
    setFormData,
    setValidationMessages,
  ]);

  useEffect(() => {
    if (formData.is_virtual) {
      const mtg_end_datetime = formData.end_datetime;
      const cutoff_datetime = formData.virtual_reg_end_datetime;

      const is_cutoff_after_end =
        !!mtg_end_datetime && !!cutoff_datetime && cutoff_datetime > mtg_end_datetime;
      addOrRemoveValidationMessage({
        is_condition_met: is_cutoff_after_end,
        err_message: other_virtual_validation.virtual_cutoff_before_end_validation_msg,
        setValidationMessages,
      });
      setIsCutoffAfterEnd((prevState) => {
        return {...prevState, virtual: is_cutoff_after_end};
      });

      const is_link_valid = !formData.virtual_link || isHttpLinkValid(formData.virtual_link);
      addOrRemoveValidationMessage({
        is_condition_met: !is_link_valid,
        err_message: other_virtual_validation.invalid_link_format,
        setValidationMessages,
      });
      setIsVirtualLinkValid(is_link_valid);
    } else {
      // Remove all validation messages when is_virtual is unmounted
      removeValidationMessages({
        err_messages: possible_virtual_err_messages,
        setValidationMessages,
      });

      // Reset data when Virtual is unchecked
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          virtual_link: '',
          virtual_capacity: '',
          virtual_info: '',
          virtual_reg_end_datetime: '',
        };
      });
    }
  }, [
    formData.end_datetime,
    formData.is_virtual,
    formData.virtual_link,
    formData.virtual_reg_end_datetime,
    is_date_required,
    setFormData,
    setValidationMessages,
  ]);

  return (
    <section className="margin-top-3">
      <SectionTitle>
        {format_title}
        {is_format_required && ' *'}
      </SectionTitle>
      {/* If a meeting with status_unlisted: false has format removed (unchecked), render broadcast email alert */}
      {/* Additional text also rendered within the return */}
      {is_edit_mtg &&
        !is_initial_unlisted &&
        ((query_data.is_virtual && !formData.is_virtual) ||
          (query_data.is_in_person && !formData.is_in_person)) && (
          <InlineAlert is_broadcast={true}>
            {broadcast_email_message}. Any current registrants&apos; registration format will remain
            unchanged.
          </InlineAlert>
        )}
      {is_neither_format_checked && <InlineAlert>{format_required_message}</InlineAlert>}
      <section className="grid-row">
        <Checkbox
          id="is_in_person"
          name="is_in_person"
          checked={formData.is_in_person}
          onChange={handleCheckChange}
          className="margin-right-2 margin-top-1"
          label={metadata_client_meeting.is_in_person.title}
          tile
        />
        <Checkbox
          id="is_virtual"
          name="is_virtual"
          checked={formData.is_virtual}
          onChange={handleCheckChange}
          className="usa-checkbox--accent-warm margin-top-1"
          label={metadata_client_meeting.is_virtual.title}
          tile
        />
      </section>
      {formData.is_in_person && (
        <SummaryBox>
          <SummaryBoxHeading headingLevel="h4" className="margin-0">
            In-person configuration
          </SummaryBoxHeading>
          <SummaryBoxContent>
            <Grid className="grid-row grid-gap">
              <div className="tablet:grid-col-8">
                <FormTextInput
                  id_name="in_person_address"
                  input={formData.in_person_address}
                  handleChange={handleInputChange}
                  setValidationMessages={setValidationMessages}
                  form_type="meeting"
                  is_validate_length
                  use_semi_uncontrolled
                  debounce_change_ms={debounce_delay_ms}
                />
              </div>
              <div className="tablet:grid-col-4">
                <FormTextInput
                  id_name="in_person_capacity"
                  input={formData.in_person_capacity}
                  handleChange={handleInputChange}
                  setValidationMessages={setValidationMessages}
                  variant="number"
                  is_required
                  form_type="meeting"
                  use_semi_uncontrolled
                  debounce_change_ms={debounce_delay_ms}
                />
              </div>
            </Grid>
            <FormTextArea
              id_name="in_person_info"
              input={formData.in_person_info}
              handleChange={handleRtfAreaChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="meeting"
              is_rtf
            />
            <FormDatetimeInput
              id_name="in_person_reg_end_datetime"
              metadata={metadata_client_meeting.in_person_reg_end_datetime}
              setValidationMessages={setValidationMessages}
              handleChange={handleDatetimeInputChange}
              default_value={
                is_edit_mtg && formData.in_person_reg_end_datetime
                  ? {iso_datetime: formData.in_person_reg_end_datetime}
                  : undefined
              }
              alert_msg={
                isCutoffAfterEnd.in_person
                  ? other_in_person_validation.in_person_cutoff_before_end_validation_msg
                  : undefined
              }
              is_required={is_date_required}
            >
              {is_edit_mtg && formData.in_person_reg_end_datetime && (
                <PrevTimeInfo
                  query_data={query_data}
                  formData={formData}
                  id_name="in_person_reg_end_datetime"
                />
              )}
            </FormDatetimeInput>
          </SummaryBoxContent>
        </SummaryBox>
      )}
      {formData.is_virtual && (
        <SummaryBox className="bg-accent-warm-even-lighter border-accent-warm-light">
          <SummaryBoxHeading headingLevel="h4" className="margin-0">
            Virtual configuration
          </SummaryBoxHeading>
          <SummaryBoxContent>
            <Grid className="grid-row grid-gap">
              <div className="tablet:grid-col-8">
                <FormTextInput
                  id_name="virtual_link"
                  input={formData.virtual_link}
                  handleChange={handleInputChange}
                  setValidationMessages={setValidationMessages}
                  is_validate_length
                  is_invalid={!isVirtualLinkValid}
                  form_type="meeting"
                  use_semi_uncontrolled
                  debounce_change_ms={debounce_delay_ms}
                />
              </div>
              <div className="tablet:grid-col-4">
                <FormTextInput
                  id_name="virtual_capacity"
                  input={formData.virtual_capacity}
                  handleChange={handleInputChange}
                  setValidationMessages={setValidationMessages}
                  variant="number"
                  form_type="meeting"
                  use_semi_uncontrolled
                  debounce_change_ms={debounce_delay_ms}
                />
              </div>
            </Grid>
            <FormTextArea
              id_name="virtual_info"
              input={formData.virtual_info}
              handleChange={handleRtfAreaChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="meeting"
              is_rtf
            />
            <FormDatetimeInput
              id_name="virtual_reg_end_datetime"
              metadata={metadata_client_meeting.virtual_reg_end_datetime}
              setValidationMessages={setValidationMessages}
              handleChange={handleDatetimeInputChange}
              default_value={
                is_edit_mtg && formData.virtual_reg_end_datetime
                  ? {iso_datetime: formData.virtual_reg_end_datetime}
                  : undefined
              }
              alert_msg={
                isCutoffAfterEnd.virtual
                  ? other_virtual_validation.virtual_cutoff_before_end_validation_msg
                  : undefined
              }
              is_required={is_date_required}
            >
              {is_edit_mtg && formData.virtual_reg_end_datetime && (
                <PrevTimeInfo
                  query_data={query_data}
                  formData={formData}
                  id_name="virtual_reg_end_datetime"
                />
              )}
            </FormDatetimeInput>
          </SummaryBoxContent>
        </SummaryBox>
      )}
      <ConfirmFormatChangeModal
        isModalOpen={modalInfo.is_open}
        closeModal={() => {
          setModalInfo({is_open: false, target_name: null});
        }}
        handleUncheck={handleUncheck}
        modal_heading={`Uncheck '${modal_format}' format`}
        confirm_btn_text={`Uncheck '${modal_format}'`}
      >
        <p>
          Are you sure you&apos;d like to uncheck the &quot;{modal_format}&quot; format checkbox?
          All information in the &quot;{modal_format} Configuration&quot; section will be lost.
        </p>
      </ConfirmFormatChangeModal>
    </section>
  );
}
