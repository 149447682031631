import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  file_name: string;
  handleReplaceFile: () => void;
};

export default function ReplaceFileModal({
  isModalOpen,
  closeModal,
  file_name: file_title,
  handleReplaceFile,
}: Props) {
  const replace_btn_id = 'file-replace-modal-replace-btn';
  const modal_heading = `Replace "${file_title}"`;

  return (
    <GenericModalLayout isOpen={isModalOpen} closeModal={closeModal} modal_heading={modal_heading}>
      <div className="usa-prose">
        <p>
          Are you sure you want to replace this file? Doing so will cause the current file to be
          deleted.
        </p>
      </div>
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <div id={replace_btn_id}>
            <Button
              type="button"
              onClick={() => {
                handleReplaceFile();
                closeModal();
              }}
            >
              Replace file
            </Button>
          </div>
          <Button type="button" onClick={closeModal} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </GenericModalLayout>
  );
}
