import axios from 'axios';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import SuccessFalseError from '../lib/success-false-error';
import {AccountGetPutResBody} from '../../common/types/account-get-put-res';
import {ErrorResBody} from '../../common/types/error-response';
import {AccountPutReqBody} from '../../common/types/account-put-req';
import {
  SharedForm,
  EditMyAccountForm,
  CreateAccountForm,
  EditOthersAccountForm,
} from '../types/account-types';
import {AccountsPostReqBody} from '../../common/types/accounts-post-req';
import {AccountsGetPutPostResBody} from '../../common/types/accounts-get-put-post-res';
import {AccountsGetResBody} from '../../common/types/accounts-get-res';
import {RolesGetData, RolesGetResBody} from '../../common/types/roles-get-res';
import {AccountPrivilegesGetResBody} from '../../common/types/account-privileges-get-res';
import {AccountsPutReqBody} from '../../common/types/accounts-put-req';
import {usePrivilegesContext} from '../lib/PrivilegesContext';
import {PivPostResBody} from '../../common/types/piv-post-res';
import {PivDelResBody} from '../../common/types/piv-del-res';
import {PivsGetResBody} from '../../common/types/pivs-get-res';

interface Filters {
  show_disabled: boolean;
  show_unverified: boolean;
}

async function fetchAccounts(filters: Filters) {
  const res = await axios.get<AccountsGetResBody | ErrorResBody>(
    // eslint-disable-next-line max-len
    `/api/accounts?show_disabled=${filters.show_disabled.toString()}&show_unverified=${filters.show_unverified.toString()}`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryAccountsList(filters: Filters) {
  return useQuery({
    queryKey: ['accounts', 'list', filters],
    queryFn: () => fetchAccounts(filters),
    throwOnError: true,
  });
}

type UserId = string;

async function fetchOthersAccount(user_id: UserId) {
  const res = await axios.get<AccountsGetPutPostResBody | ErrorResBody>(`/api/accounts/${user_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryOthersAccount(user_id: UserId) {
  return useQuery({
    queryKey: ['accounts', 'account', user_id],
    queryFn: () => fetchOthersAccount(user_id),
    retry: false,
    throwOnError: true,
  });
}

async function editOthersAccount(req_body: AccountsPutReqBody, user_id: UserId) {
  const res = await axios.put<AccountsGetPutPostResBody | ErrorResBody>(
    `/api/accounts/${user_id}`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

interface MutationParams {
  form: EditOthersAccountForm;
  user_id: UserId;
}

export function useMutationEditOthersAccount({
  is_acc_delete = false,
}: {is_acc_delete?: boolean} = {}) {
  const queryClient = useQueryClient();
  const privilegesContext = usePrivilegesContext();
  const {pk: current_user_id} = privilegesContext;
  return useMutation({
    mutationFn: ({form, user_id}: MutationParams) => {
      if (!form.role_fk) throw new Error('Role not found');
      const formatted_body: AccountsPutReqBody = {
        role_fk: form.role_fk,
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        job_title: form.job_title.trim(),
        org: form.org.trim(),
        phone: form.phone,
        is_removed: form.is_removed,
        is_disabled: form.is_disabled,
        admin_notes: form.admin_notes.trim(),
      };
      return editOthersAccount(formatted_body, user_id);
    },
    onSuccess: async (data, {user_id}) => {
      const async_operations: Array<Promise<void>> = [
        queryClient.invalidateQueries({queryKey: ['accounts', 'list'], type: 'all'}),
      ];

      if (is_acc_delete) {
        // Remove the cached query but do not attempt refetch or it will fail and could trigger throwOnError in query
        queryClient.removeQueries({queryKey: ['accounts', 'account', user_id]});
      } else {
        async_operations.push(
          queryClient.invalidateQueries({queryKey: ['accounts', 'account', user_id], type: 'all'})
        );
      }

      // Not strictly necessary because of PT https://www.pivotaltracker.com/story/show/185138015, but safeguards
      // against business rule changes in case editing one's own account via View Other's Account becomes possible.
      if (current_user_id == user_id) {
        async_operations.push(
          queryClient.invalidateQueries({queryKey: ['my_account'], type: 'all'})
        );
      }

      await Promise.all(async_operations);
    },
  });
}

async function fetchMyAccount() {
  const res = await axios.get<AccountGetPutResBody | ErrorResBody>('/api/account');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

interface QueryMyAccountOptions {
  is_enabled?: boolean;
}
export function useQueryMyAccount(
  key = 'default',
  {is_enabled = true}: QueryMyAccountOptions = {}
) {
  return useQuery({
    queryKey: ['my_account', key],
    queryFn: () => fetchMyAccount(),
    enabled: is_enabled,
    throwOnError: true,
  });
}

async function editMyAccount(req_body: AccountPutReqBody) {
  const res = await axios.put<AccountGetPutResBody | ErrorResBody>('/api/account', req_body);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationEditMyAccount({is_acc_delete}: {is_acc_delete: boolean}) {
  const queryClient = useQueryClient();
  const privilegesContext = usePrivilegesContext();
  const {pk: user_id} = privilegesContext;
  return useMutation({
    mutationFn: (form: EditMyAccountForm) => {
      const formatted_body: AccountPutReqBody = {
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        job_title: form.job_title.trim(),
        org: form.org.trim(),
        phone: form.phone,
        is_removed: form.is_removed,
        admin_notes: form.admin_notes?.trim(),
      };
      return editMyAccount(formatted_body);
    },
    onSuccess: async () => {
      if (is_acc_delete) {
        // When a user deletes their own account, reset all the queries since it's basically a logout
        return queryClient.resetQueries();
      }

      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['my_account'], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['accounts', 'list'], type: 'all'}),
        // Not strictly necessary because of PT https://www.pivotaltracker.com/story/show/185138015, but safeguards
        // against business rule changes in case editing one's own account via View Other's Account becomes possible.
        queryClient.invalidateQueries({queryKey: ['accounts', 'account', user_id], type: 'all'}),
      ]);
    },
  });
}

export function useMutationConfirmAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (form: SharedForm) => {
      const formatted_body: AccountPutReqBody = {
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        job_title: form.job_title.trim(),
        org: form.org.trim(),
        phone: form.phone,
        is_removed: false,
      };
      return editMyAccount(formatted_body);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['privileges'], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['my_account'], type: 'all'}),
      ]);
    },
    throwOnError: true,
  });
}

async function createAccount(req_body: AccountsPostReqBody) {
  const res = await axios.post<AccountsGetPutPostResBody | ErrorResBody>('/api/accounts', req_body);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationCreateAccount() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (form: CreateAccountForm) => {
      const formatted_body: AccountsPostReqBody = {
        email: form.email.trim(),
        role_fk: form.role_fk,
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        job_title: form.job_title.trim(),
        org: form.org.trim(),
        phone: form.phone,
        admin_notes: form.admin_notes.trim(),
      };
      return createAccount(formatted_body);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['accounts', 'list'], type: 'all'});
    },
  });
}

export async function fetchRoles() {
  const res = await axios.get<RolesGetResBody | ErrorResBody>('/api/roles');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

// https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
function sortRoles(data: RolesGetData) {
  return data.sort((role_a, role_b) => role_b.level - role_a.level);
}

export function useQueryRoles() {
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => fetchRoles(),
    select: sortRoles,
    throwOnError: true,
  });
}

async function fetchPrivileges() {
  const res = await axios.get<AccountPrivilegesGetResBody | ErrorResBody>(
    '/api/account/privileges'
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryPrivileges({is_enabled = true, key = 'default'}) {
  return useQuery({
    queryKey: ['privileges', key],
    queryFn: () => fetchPrivileges(),
    retry: false,
    staleTime: 5000,
    enabled: is_enabled,
    // Not all 401 errs are created equal
    // 401s on a public page is not an error
    // 401s on private routes, however, is indeed an error
    // So, that err is re-thrown in PrivateRoute component
    throwOnError: (error) =>
      axios.isAxiosError(error) && !!error.response && error.response.status != 401,
  });
}

// When the app is hosted in IIS, don't expect error message or status codes to always be the same.
// IIS passes along its own error status codes for certain errors (those involving 4xx errors).
// Front end error handling should not be dependent on error status code for POST /api/piv or PIV auth routes
export async function addPiv() {
  const res = await axios.post<PivPostResBody | ErrorResBody>('/piv');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationAssociatePiv(user_id: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addPiv,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['pivs'], type: 'all'});
      await queryClient.invalidateQueries({queryKey: ['my_account'], type: 'all'});
      // Not strictly necessary because of PT https://www.pivotaltracker.com/story/show/185138015, but safeguards
      // against business rule changes in case editing one's own account via View Other's Account becomes possible.
      if (user_id) {
        await queryClient.invalidateQueries({
          queryKey: ['accounts', 'account', user_id],
          type: 'all',
        });
      }
    },
  });
}

export async function deletePiv(piv_id: string) {
  const res = await axios.delete<PivDelResBody | ErrorResBody>(`/api/piv/${piv_id}`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationDeletePiv(user_id: string) {
  const queryClient = useQueryClient();
  const privilegesContext = usePrivilegesContext();
  const {pk: current_user_id} = privilegesContext;
  return useMutation({
    mutationFn: (piv_id: string) => deletePiv(piv_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['pivs'], type: 'all'});
      if (user_id == current_user_id) {
        await queryClient.invalidateQueries({queryKey: ['my_account'], type: 'all'});
      }
      await queryClient.invalidateQueries({
        queryKey: ['accounts', 'account', user_id],
        type: 'all',
      });
    },
  });
}

async function fetchPivs() {
  const res = await axios.get<PivsGetResBody | ErrorResBody>('api/pivs');
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryPivsList() {
  return useQuery({
    queryKey: ['pivs'],
    queryFn: () => fetchPivs(),
    throwOnError: true,
  });
}
