import {MeetingsPostPutReqBody} from '../../common/types/meetings-post-put-req';
import metadata_client_meeting from '../lib/metadata-client-meeting.json';
import {MeetingForm} from '../types/meeting-types';
import {splitAndTrimEmails, isEmailValid, stringToNullOrNum, trimFormFields} from './utils';

export function formatMeetingFormData(form: MeetingForm) {
  trimFormFields(form);
  let formatted_cc_emails: MeetingsPostPutReqBody['cc_emails'] = [];
  if (form.cc_emails) {
    const trimmed_emails = splitAndTrimEmails(form.cc_emails);
    if (!trimmed_emails.every(isEmailValid))
      throw new Error(`An email in ${metadata_client_meeting.cc_emails.title} is invalid`);
    formatted_cc_emails = trimmed_emails;
  }
  return {
    ...form,
    in_person_capacity: stringToNullOrNum(form.in_person_capacity),
    in_person_reg_end_datetime: form.in_person_reg_end_datetime || null,
    virtual_capacity: stringToNullOrNum(form.virtual_capacity),
    virtual_reg_end_datetime: form.virtual_reg_end_datetime || null,
    cc_emails: formatted_cc_emails,
    start_datetime: form.start_datetime || null,
    end_datetime: form.end_datetime || null,
  };
}

export type MeetingRegData = {
  status: {
    is_open_for_reg: boolean;
  };
  is_in_person: boolean;
  in_person_capacity: number | null;
  in_person_registrant_count: number;
  in_person_reg_end_datetime: string | null;
  is_virtual: boolean;
  virtual_capacity: number | null;
  virtual_registrant_count: number;
  virtual_reg_end_datetime: string | null;
};

export function getMeetingRegSummary(meeting_data: MeetingRegData) {
  const {
    status,
    in_person_capacity,
    in_person_registrant_count,
    in_person_reg_end_datetime,
    virtual_capacity,
    virtual_registrant_count,
    virtual_reg_end_datetime,
  } = meeting_data;

  // In person meetings require a capacity be defined whereas virtual meetings do not.
  const is_in_person_at_capacity =
    in_person_capacity == null || in_person_registrant_count >= in_person_capacity;
  const is_virtual_at_capacity =
    virtual_capacity != null && virtual_registrant_count >= virtual_capacity;

  const now = new Date();
  const is_in_person_reg_ended =
    !in_person_reg_end_datetime || now >= new Date(in_person_reg_end_datetime);
  const is_virtual_reg_ended =
    !virtual_reg_end_datetime || now >= new Date(virtual_reg_end_datetime);

  return {
    is_open_for_reg: status.is_open_for_reg,
    is_in_person_at_capacity,
    is_in_person_reg_ended,
    is_virtual_at_capacity,
    is_virtual_reg_ended,
  };
}
