interface Props {
  children: React.ReactNode;
}

export default function BottomContainer({children}: Props) {
  return (
    <section className="position-fixed pin-bottom pin-x padding-y-205 bg-white form-container shadow z-200">
      <div className="grid-container word-break-word">{children}</div>
    </section>
  );
}
