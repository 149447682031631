/* eslint-disable max-len */
import {useMemo, useState} from 'react';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {PivsGetDataPiv} from '../../../common/types/pivs-get-res';
import metadata_client_piv from '../../lib/metadata-client-piv.json';
import metadata_client_account from '../../lib/metadata-client-account.json';
import TableUI from '../../components/TableUI/TableUI';
import {
  Breadcrumb,
  BreadcrumbBar,
  Button,
  FormGroup,
  Label,
  TextInput,
} from '@trussworks/react-uswds';
import {Link} from 'react-router-dom';
import {fromUTCtoET, piv_delete_success, scrollToTop} from '../../lib/utils';
import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import {useQueryPivsList} from '../../services/account-queries';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';
import DeletePivModal from '../../components/AccountComponents/DeletePivModal/DeletePivModal';
import {DeletePivModalState} from '../../types/piv-types';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';

const initial_modal: DeletePivModalState = {
  isModalOpen: false,
  pk: null,
  user_id: null,
  serial: null,
};

interface DeletePivData {
  user_pk: string;
  serial: string;
  piv_pk: string;
}

export default function PivList() {
  usePageTitle('All associated PIV certificates');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [deletePivModal, setDeletePivModal] = useState<DeletePivModalState>(initial_modal);
  const [isPivDeleteSuccess, setIsPivDeleteSuccess] = useState<true | null>(null);
  const query = useQueryPivsList();
  const privileges = usePrivilegesContext();

  function handleCloseModal() {
    setDeletePivModal(initial_modal);
  }

  function handleSuccessfulPivDelete() {
    setIsPivDeleteSuccess(true);
    scrollToTop();
  }

  function handleDeletePiv({user_pk, serial, piv_pk}: DeletePivData) {
    setIsPivDeleteSuccess(null);
    setDeletePivModal({
      isModalOpen: true,
      pk: piv_pk,
      serial: serial,
      user_id: user_pk,
    });
  }

  const columns = useMemo<ColumnDef<PivsGetDataPiv>[]>(
    () => [
      {
        accessorFn: (row) => row.user.first_name ?? '',
        header: metadata_client_account.first_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.user.last_name ?? '',
        header: metadata_client_account.last_name.title,
        sortingFn: 'text',
      },
      {
        accessorFn: (row) => row.user.email,
        header: metadata_client_account.email.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'piv_serialnumber',
        header: metadata_client_piv.piv_serialnumber.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'piv_issuer',
        header: metadata_client_piv.piv_issuer.title,
        enableGlobalFilter: false,
        sortingFn: 'text',
      },
      {
        accessorKey: 'piv_subject',
        header: metadata_client_piv.piv_subject.title,
        sortingFn: 'text',
      },
      {
        accessorKey: 'create_datetime',
        cell: (info) => fromUTCtoET(info.getValue<string>()),
        header: 'Association date',
        sortingFn: 'datetime',
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row): DeletePivData => {
          return {user_pk: row.user.pk, serial: row.piv_serialnumber, piv_pk: row.pk};
        },
        cell: (info) => {
          const {user_pk, serial, piv_pk} = info.getValue<DeletePivData>();
          return (
            <Button
              id={`delete-piv-btn-${piv_pk}`}
              type="button"
              className="usa-button--outline usa-button--outline-secondary text-no-wrap margin-right-0"
              onClick={() => handleDeletePiv({user_pk, serial, piv_pk})}
            >
              Delete certificate
            </Button>
          );
        },
        header: undefined,
        id: 'delete_cert',
        enableSorting: false,
        enableGlobalFilter: false,
      },
      {
        accessorFn: (row) => row.user.pk,
        cell: (info) => {
          const my_pk = privileges.pk;
          const user_fk = info.getValue<string>();
          return (
            <Link
              to={user_fk == my_pk ? '/my-account' : `/manage-accounts/account/${user_fk}`}
              className="usa-link text-no-wrap"
              data-user_pk={user_fk == my_pk ? user_fk : undefined}
            >
              {user_fk == my_pk ? 'My account' : 'View account'}
            </Link>
          );
        },
        header: undefined,
        id: 'user_fk',
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [privileges.pk]
  );

  const table = useReactTable({
    data: query.data ?? [],
    columns,
    state: {sorting, globalFilter},
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      columnPinning: {
        right: ['user_fk'],
      },
    },
  });

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>PIV certificate list</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {isPivDeleteSuccess && <SuccessAlert>{piv_delete_success}</SuccessAlert>}
      <section className="grid-container bg-base-lightest padding-y-2 word-break-word">
        <FormGroup className="grid-row flex-align-center flex-no-wrap flex-column mobile-lg:flex-row padding-y-1 margin-0">
          <Label htmlFor="input-search" className="text-italic mobile-lg:margin-right-4">
            Search names, emails, subject, and serial numbers
          </Label>
          <TextInput
            id="input-search"
            name="input-type-text"
            type="text"
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            placeholder="Enter search term..."
            className="mobile-lg:margin-0 width-full mobile-lg:width-mobile maxw-full height-5"
          />
        </FormGroup>
      </section>
      {query.isPending ? (
        <PageLoading message="Loading accounts" />
      ) : (
        <>
          <TableUI
            table={table}
            clickedIndex={clickedIndex}
            setClickedIndex={setClickedIndex}
            variant="manage_pivs_table"
          />
          <DeletePivModal
            deletePivModal={deletePivModal}
            closeModal={handleCloseModal}
            handleSuccessfulPivDelete={handleSuccessfulPivDelete}
          />
        </>
      )}
    </>
  );
}
