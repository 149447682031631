import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import {forwardRef, useEffect, useState} from 'react';
import {useBlocker} from 'react-router-dom';

interface Props {
  isFormDirty: () => boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  disable_nav_blocker?: boolean;
  visibility?: 'visible' | 'offscreen' | 'none';
  variant?: 'confirm_acct' | 'general';
  watch_url_segments?: {
    pathname: boolean;
    search: boolean;
    hash: boolean;
  };
}

type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'ref' | 'type' | 'children' | 'onClick'
>;

export default forwardRef(function DiscardChanges(
  {
    isFormDirty,
    onCancel,
    onConfirm,
    disable_nav_blocker,
    watch_url_segments = {pathname: true, search: true, hash: false},
    visibility = 'visible',
    variant = 'general',
    className,
    ...buttonProps
  }: Props & ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlockerDisabled, setIsBlockerDisabled] = useState(false);
  const blocker = useBlocker(({currentLocation, nextLocation}) => {
    const is_url_changed =
      (watch_url_segments.pathname && currentLocation.pathname != nextLocation.pathname) ||
      (watch_url_segments.search && currentLocation.search != nextLocation.search) ||
      (watch_url_segments.hash && currentLocation.hash != nextLocation.hash);
    return !disable_nav_blocker && !isBlockerDisabled && is_url_changed && isFormDirty();
  });

  function handleClick() {
    if (
      buttonProps.disabled ||
      buttonProps['aria-disabled'] == true ||
      buttonProps['aria-disabled'] == 'true'
    ) {
      return;
    }

    setIsBlockerDisabled(true);
    if (isFormDirty()) {
      setIsModalOpen(true);
    } else {
      handleConfirm();
    }
  }

  function handleCancel() {
    setIsModalOpen(false);
    setIsBlockerDisabled(false);
    if (blocker.state == 'blocked') {
      blocker.reset();
    } else if (onCancel) {
      onCancel();
    }
  }

  function handleConfirm() {
    setIsModalOpen(false);
    if (blocker.state == 'blocked') {
      blocker.proceed();
    } else if (onConfirm) {
      onConfirm();
    }
  }

  useEffect(() => {
    if (blocker.state == 'blocked' && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [blocker.state, isModalOpen]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!disable_nav_blocker && !isBlockerDisabled && isFormDirty()) {
        event.preventDefault();
        // For Chrome/Edge < 119
        event.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [disable_nav_blocker, isBlockerDisabled, isFormDirty]);

  return (
    <>
      {visibility != 'none' && (
        <button
          className={
            (visibility == 'offscreen' ? 'sr-only' : 'usa-button') +
            (className ? ` ${className}` : '')
          }
          ref={ref}
          type="button"
          onClick={handleClick}
          {...buttonProps}
        >
          Discard changes
        </button>
      )}
      <GenericModalLayout
        isOpen={isModalOpen}
        closeModal={handleCancel}
        modal_heading={variant == 'confirm_acct' ? 'Account not confirmed' : 'Discard changes'}
      >
        <div className="usa-prose">
          {variant == 'confirm_acct'
            ? 'Are you sure you want to leave this page without completing your account creation? ' +
              'Your account will not be usable until your account information is confirmed.'
            : 'Are you sure you want to discard your changes?'}
        </div>
        <ModalFooter>
          <ButtonGroup
            type="default"
            className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
          >
            <Button type="button" className="usa-button--secondary" onClick={handleConfirm}>
              {variant == 'confirm_acct' ? 'Yes' : 'Discard changes'}
            </Button>
            <Button type="button" onClick={handleCancel} unstyled>
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </GenericModalLayout>
    </>
  );
});
