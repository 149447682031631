import {Navigate, Outlet, useLocation} from 'react-router-dom';
import PrivilegesProvider from '../../lib/PrivilegesContext';
import {useQueryPrivileges} from '../../services/account-queries';
import PageLoading from '../LoadingComponents/PageLoading/PageLoading';

export default function PrivateRoute() {
  const query = useQueryPrivileges({});
  const location = useLocation();

  if (query.isPending) {
    return <PageLoading />;
  }
  // rethrow the 401 error since 401 in a private route means users shouldn't have access to this
  if (query.isError) throw query.error;

  // We don't want users to access private routes if they're not verified
  if (!query.data.is_verified && !location.pathname.startsWith('/confirm-account'))
    return (
      <Navigate
        to="/confirm-account"
        state={
          // eslint-disable-next-line max-len
          "In order to view the page you're trying to reach, please fill out this form to complete your account creation."
        }
      />
    );

  return (
    <PrivilegesProvider>
      <Outlet />
    </PrivilegesProvider>
  );
}
