/* eslint-disable max-len */ // The svg paths are long
import phmsa_triskelion from '../../../images/phmsa-triskelion.svg';

export default function PhmsaBanner() {
  return (
    <section className="bg-primary-dark">
      <div className="grid-container font-body-3xs text-gray-5 grid-row flex-no-wrap flex-align-center padding-y-1">
        <img
          src={phmsa_triskelion}
          alt="Phmsa Triskelion Logo"
          className="opacity-50 height-2 margin-right-1"
        />
        <span>United States Department of Transportation</span>
      </div>
    </section>
  );
}
