import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import {useMutationDeleteSession} from '../../../services/session-mgmt-queries';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';
import {session_revoke_err} from '../../../lib/utils';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';

export type DeleteModalState = {
  is_open: boolean;
  email: string;
  session_pk: string;
};

type Props = DeleteModalState & {
  handleDismiss: () => void;
  handleSuccess: () => void;
};

export default function DeleteModal({
  is_open,
  email,
  session_pk,
  handleDismiss,
  handleSuccess,
}: Props) {
  const mutation = useMutationDeleteSession();

  const closeModal = () => {
    mutation.reset();
    handleDismiss();
  };

  const handleConfirmed = () => {
    if (!session_pk) return;
    mutation.mutate(session_pk, {
      onSuccess: () => {
        handleSuccess();
        closeModal();
      },
    });
  };

  return (
    <GenericModalLayout
      isOpen={is_open}
      closeModal={() => undefined}
      modal_heading="Revoke session"
    >
      <div className="usa-prose">
        <p>Are you sure you want to revoke this session for {email}?</p>
        <p>
          The next time this user interacts with the web site using the browser associated with this
          session, they will be automatically logged out.
        </p>
      </div>
      {mutation.isError && <ErrorAlert>{session_revoke_err}</ErrorAlert>}
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <Button
            className="usa-button--secondary"
            type="button"
            onClick={handleConfirmed}
            disabled={mutation.isPending}
          >
            {mutation.isPending ? <TextLoading /> : 'Revoke session'}
          </Button>
          <Button type="button" onClick={closeModal} disabled={mutation.isPending} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </GenericModalLayout>
  );
}
