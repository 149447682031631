import {useOutletContext, Link} from 'react-router-dom';
import {AppRouterContext} from '../../../layouts/RootLayout/RootLayout';
import {Button, ModalToggleButton} from '@trussworks/react-uswds';
import {MeetingRegData, getMeetingRegSummary} from '../../../lib/meeting-utils';
import {useId, useMemo} from 'react';
import {PlacesType, Tooltip} from 'react-tooltip';

function getRegAllowed(meeting_reg_data: MeetingRegData) {
  const {is_in_person, is_virtual} = meeting_reg_data;
  const {
    is_open_for_reg,
    is_in_person_at_capacity,
    is_in_person_reg_ended,
    is_virtual_at_capacity,
    is_virtual_reg_ended,
  } = getMeetingRegSummary(meeting_reg_data);

  const summary = {
    /** Does the meeting status permit registration? */
    is_open_for_reg,
    /** Is registration available, overall? */
    is_reg_allowed: is_open_for_reg,
    /** Is the only reason registration is unavailable because it is at capacity? */
    is_only_full: false,
  };

  if (!is_open_for_reg) return summary;

  if (is_in_person && is_virtual) {
    if (
      (is_in_person_at_capacity || is_in_person_reg_ended) &&
      (is_virtual_at_capacity || is_virtual_reg_ended)
    ) {
      summary.is_reg_allowed = false;
      summary.is_only_full =
        (is_in_person_at_capacity && !is_in_person_reg_ended) ||
        (is_virtual_at_capacity && !is_virtual_reg_ended);
    }
  } else if (is_in_person) {
    if (is_in_person_at_capacity || is_in_person_reg_ended) {
      summary.is_reg_allowed = false;
      summary.is_only_full = is_in_person_at_capacity && !is_in_person_reg_ended;
    }
  } else if (is_virtual) {
    if (is_virtual_at_capacity || is_virtual_reg_ended) {
      summary.is_reg_allowed = false;
      summary.is_only_full = is_virtual_at_capacity && !is_virtual_reg_ended;
    }
  } else {
    // Missing meeting format. Shouldn't get here.
    summary.is_reg_allowed = false;
  }

  return summary;
}

interface Props {
  meeting_id: string;
  meeting_reg_data: MeetingRegData;
  is_logged_in: boolean;
  is_verified: boolean;
  is_registered: boolean;
  tooltip_placement?: PlacesType;
  use_link_variant?: boolean;
}

export default function RegisterButton({
  meeting_id,
  meeting_reg_data,
  is_logged_in,
  is_verified,
  is_registered,
  tooltip_placement,
  use_link_variant,
}: Props) {
  const [authModalRef] = useOutletContext<AppRouterContext>();
  const reg_allowed = useMemo(() => getRegAllowed(meeting_reg_data), [meeting_reg_data]);
  const register_url = `/meetings/${meeting_id}/register`;
  const edit_registration_url = `/meetings/${meeting_id}/edit-registration`;
  const btn_id = useId();

  if (is_registered) {
    return (
      <Link
        className={use_link_variant ? 'usa-link text-no-wrap' : 'usa-button usa-button--outline'}
        to={edit_registration_url}
      >
        View registration
      </Link>
    );
  }

  if (reg_allowed.is_reg_allowed) {
    if (is_logged_in) {
      const link_url = is_verified
        ? register_url
        : `/confirm-account?redirect=${encodeURIComponent(register_url)}`;
      return (
        <Link className={use_link_variant ? 'usa-link' : 'usa-button'} to={link_url}>
          Register
        </Link>
      );
    } else {
      return (
        <ModalToggleButton
          modalRef={authModalRef}
          aria-controls="authentication-modal"
          className={`open_auth_modal ${use_link_variant ? 'usa-button--unstyled' : ''}`}
          opener
          data-redirect={register_url}
        >
          Register
        </ModalToggleButton>
      );
    }
  } else if (reg_allowed.is_only_full) {
    return (
      <>
        {use_link_variant ? (
          <span id={btn_id} className="text-disabled text-italic">
            Full
          </span>
        ) : (
          <Button type="button" id={btn_id} aria-disabled>
            Full
          </Button>
        )}
        <Tooltip anchorSelect={`#${CSS.escape(btn_id)}`} place={tooltip_placement ?? 'bottom'}>
          This meeting is at capacity.
        </Tooltip>
      </>
    );
  } else if (reg_allowed.is_open_for_reg) {
    return (
      <>
        {use_link_variant ? (
          <span id={btn_id} className="text-disabled text-italic">
            <span className="register-btn-longtxt">Registration unavailable</span>
            <span className="register-btn-shorttxt display-none">Unavailable</span>
          </span>
        ) : (
          <Button type="button" id={btn_id} aria-disabled>
            <span className="register-btn-longtxt">Registration unavailable</span>
            <span className="register-btn-shorttxt display-none">Unavailable</span>
          </Button>
        )}
        <Tooltip anchorSelect={`#${CSS.escape(btn_id)}`} place={tooltip_placement ?? 'bottom'}>
          The registration period for this meeting has ended.
        </Tooltip>
      </>
    );
  }

  return <></>;
}
