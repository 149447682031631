import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {ErrorResBody} from '../../common/types/error-response';
import {RegistrantGetResBody} from '../../common/types/registrant-get-res';
import SuccessFalseError from '../lib/success-false-error';
import {RegistrantPutPostReqBody} from '../../common/types/registrant-put-post-req';
import {RegistrantPutPostDelResBody} from '../../common/types/registrant-put-post-del-res';
import {EditOthersRegistrationForm, RegistrationForm} from '../types/registration-types';
import {RegistrationsGetResBody} from '../../common/types/registrations-get-res';
import {RegistrantsGetResBody} from '../../common/types/registrants-get-res';
import {RegistrantsGetByUserIdResBody} from '../../common/types/registrants-get-by-user-id-res';
import {RegistrantsPutByUserIdReqBody} from '../../common/types/registrants-put-by-user-id-req';
import {RegistrantsPutByUserIdResBody} from '../../common/types/registrants-put-by-user-id-res';
import {usePrivilegesContext} from '../lib/PrivilegesContext';
import {RegistrantsAttendancePatchReqBody} from '../../common/types/registrants-attendance-patch-req';
import {RegistrantsAttendancePatchResBody} from '../../common/types/registrants-attendance-patch-res';
import {useQueryPrivileges} from './account-queries';

type MeetingId = string;
type UserId = string;

async function fetchRegistrant(meeting_id: MeetingId) {
  const res = await axios.get<RegistrantGetResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrant`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

interface QueryRegistrantOptions {
  is_critical?: boolean;
  is_enabled?: boolean;
}
export function useQueryRegistrant(
  meeting_id: MeetingId,
  {is_critical = true, is_enabled = true}: QueryRegistrantOptions = {}
) {
  return useQuery({
    queryKey: ['registrant', meeting_id],
    queryFn: () => fetchRegistrant(meeting_id),
    retry: false,
    enabled: is_enabled,
    throwOnError: (error) =>
      is_critical && axios.isAxiosError(error) && !!error.response && error.response.status != 401,
  });
}

interface CreateEditRegistrantMutationParams {
  form: RegistrationForm;
  meeting_id: MeetingId;
}

async function createRegistrant(req_body: RegistrantPutPostReqBody, meeting_id: MeetingId) {
  const res = await axios.post<RegistrantPutPostDelResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrant`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationCreateRegistrant() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({form, meeting_id}: CreateEditRegistrantMutationParams) => {
      const formatted_body: RegistrantPutPostReqBody = {
        is_in_person_reg: form.is_in_person_reg,
        is_virtual_reg: form.is_virtual_reg,
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        org: form.org.trim(),
        job_title: form.job_title.trim(),
        phone: form.phone,
        comments: form.comments.trim(),
        cr_responses: [
          ...form.cr_responses.map((crr) => ({
            ...crr,
            responses: crr.responses.map((response) =>
              typeof response == 'string' ? response.trim() : response
            ),
          })),
        ],
      };
      return createRegistrant(formatted_body, meeting_id);
    },
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['registrant', variables.meeting_id],
          type: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: ['registrants', variables.meeting_id],
          type: 'all',
        }),
        queryClient.invalidateQueries({queryKey: ['registrations'], type: 'all'}),
      ]);
    },
  });
}

async function editRegistrant(req_body: RegistrantPutPostReqBody, meeting_id: MeetingId) {
  const res = await axios.put<RegistrantPutPostDelResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrant`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationEditRegistrant() {
  const queryClient = useQueryClient();
  const privilegesContext = usePrivilegesContext();
  const {pk: current_user_id} = privilegesContext;
  return useMutation({
    mutationFn: ({form, meeting_id}: CreateEditRegistrantMutationParams) => {
      const formatted_body: RegistrantPutPostReqBody = {
        is_in_person_reg: form.is_in_person_reg,
        is_virtual_reg: form.is_virtual_reg,
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        org: form.org.trim(),
        job_title: form.job_title.trim(),
        phone: form.phone,
        comments: form.comments.trim(),
        cr_responses: [
          ...form.cr_responses.map((crr) => ({
            ...crr,
            responses: crr.responses.map((response) =>
              typeof response == 'string' ? response.trim() : response
            ),
          })),
        ],
      };
      return editRegistrant(formatted_body, meeting_id);
    },
    onSuccess: async (data, {meeting_id}) => {
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['registrant', meeting_id], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['registrants', meeting_id], type: 'all'}),
        queryClient.invalidateQueries({
          queryKey: ['registrants', 'registrant', meeting_id, current_user_id],
          type: 'all',
        }),
        // It is not necessary to invalidate ['registrations'] because that endpoint only returns a list of meeting PKs
        // to which a user is registered. Editing registration does not affect that list (deleting does).
      ]);
    },
  });
}

export async function deleteRegistrant(meeting_id: MeetingId) {
  const res = await axios.delete<RegistrantPutPostDelResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrant`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationDeleteRegistrant() {
  const queryClient = useQueryClient();
  const privilegesContext = usePrivilegesContext();
  const {pk: user_id} = privilegesContext;
  return useMutation({
    mutationFn: (meeting_id: MeetingId) => deleteRegistrant(meeting_id),
    onSuccess: async (data, meeting_id) => {
      // Remove cached queries but do not attempt refetch or they will fail and could trigger throwOnError in queries
      queryClient.removeQueries({queryKey: ['registrant', meeting_id]});
      queryClient.removeQueries({queryKey: ['registrants', 'registrant', meeting_id, user_id]});

      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['registrations'], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['registrants', meeting_id], type: 'all'}),
      ]);
    },
  });
}

async function fetchRegistrants(meeting_id: MeetingId) {
  const res = await axios.get<RegistrantsGetResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrants`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

interface QueryRegistrantsOptions {
  is_enabled?: boolean;
}
export function useQueryRegistrants(
  meeting_id: MeetingId,
  {is_enabled = true}: QueryRegistrantsOptions = {}
) {
  return useQuery({
    queryKey: ['registrants', meeting_id],
    queryFn: () => fetchRegistrants(meeting_id),
    enabled: is_enabled,
  });
}

async function fetchOtherRegistrant(meeting_id: MeetingId, user_id: UserId) {
  const res = await axios.get<RegistrantsGetByUserIdResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrants/${user_id}`
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryOtherRegistrant(meeting_id: MeetingId, user_id: UserId) {
  return useQuery({
    queryKey: ['registrants', 'registrant', meeting_id, user_id],
    queryFn: () => fetchOtherRegistrant(meeting_id, user_id),
  });
}

interface EditOthersRegistrationMutationParams {
  form: EditOthersRegistrationForm;
  meeting_id: MeetingId;
  user_id: UserId;
}

async function editOthersRegistration(
  req_body: RegistrantsPutByUserIdReqBody,
  meeting_id: MeetingId,
  user_id: UserId
) {
  const res = await axios.put<RegistrantsPutByUserIdResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrants/${user_id}`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useMutationEditOthersRegistration() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({form, meeting_id, user_id}: EditOthersRegistrationMutationParams) => {
      return editOthersRegistration(form, meeting_id, user_id);
    },
    onSuccess: async (data, {meeting_id, user_id}) => {
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['registrant', meeting_id], type: 'all'}),
        queryClient.invalidateQueries({queryKey: ['registrants', meeting_id], type: 'all'}),
        queryClient.invalidateQueries({
          queryKey: ['registrants', 'registrant', meeting_id, user_id],
          type: 'all',
        }),
        // It is not necessary to invalidate ['registrations'] because that endpoint only returns a list of meeting PKs
        // to which a user is registered. Editing registration does not affect that list (deleting does).
      ]);
    },
  });
}

async function fetchRegistrations() {
  const res = await axios.get<RegistrationsGetResBody | ErrorResBody>(`/api/registrations`);
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

export function useQueryRegistrations() {
  return useQuery({
    queryKey: ['registrations'],
    queryFn: () => fetchRegistrations(),
    retry: false,
  });
}

export async function bulkUpdateAttendance(
  meeting_id: string,
  req_body: RegistrantsAttendancePatchReqBody
) {
  const res = await axios.patch<RegistrantsAttendancePatchResBody | ErrorResBody>(
    `/api/meetings/${meeting_id}/registrants-attendance`,
    req_body
  );
  if (!res.data.success) throw new SuccessFalseError(res.data);
  return res.data.data;
}

type BulkUpdateAttendanceMutationParams = {
  meeting_id: string;
  form_data: RegistrantsAttendancePatchReqBody;
};

export function useMutationBulkUpdateAttendance() {
  const queryClient = useQueryClient();
  // This mutation is used in a public route (meeting details) within a tab that checks privileges.
  // usePrivilegesContext() cannot be used here, so useQueryPrivileges() is used instead.
  const queryPrivileges = useQueryPrivileges({});
  const user_id = queryPrivileges.isSuccess ? queryPrivileges.data.pk : undefined;

  return useMutation({
    mutationFn: ({meeting_id, form_data}: BulkUpdateAttendanceMutationParams) =>
      bulkUpdateAttendance(meeting_id, form_data),
    onSuccess: async (data, {meeting_id, form_data}) => {
      const updated_user_ids = form_data.map((item) => item.user_fk);
      await Promise.all([
        user_id && updated_user_ids.includes(user_id)
          ? queryClient.invalidateQueries({queryKey: ['registrant', meeting_id], type: 'all'})
          : Promise.resolve(),
        queryClient.invalidateQueries({queryKey: ['registrants', meeting_id], type: 'all'}),
        updated_user_ids.flatMap((updated_user_id) =>
          queryClient.invalidateQueries({
            queryKey: ['registrants', 'registrant', meeting_id, updated_user_id],
            type: 'all',
          })
        ),
      ]);
    },
  });
}
