import {useEffect, useMemo, useRef, useState} from 'react';
import {Alert, Grid} from '@trussworks/react-uswds';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {RegistrationForm} from '../../types/registration-types';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import FormEmailInput from '../../components/FormComponents/FormEmailInput/FormEmailInput';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {
  RegFormVariant,
  addOrRemoveValidationMessage,
  handleTypedInputChange,
  handleTypedPhoneChange,
  meeting_registration_closed_msg,
} from '../../lib/utils';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import RegistrationFormatRadio, {
  Format,
  RegAllowed,
} from '../../components/FormComponents/RegistrationFormComponents/MeetingRegFormatRadio';
import {MeetingsGetByIdData} from '../../../common/types/meetings-get-by-id-res';
import {AccountGetPutData} from '../../../common/types/account-get-put-res';
import {MeetingRegData, getMeetingRegSummary} from '../../lib/meeting-utils';
import RegistrationHeading from '../../components/MeetingComponents/RegistrationHeading/RegistrationHeading';
import {useMutationCreateRegistrant} from '../../services/registrant-queries';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import {useNavigate} from 'react-router-dom';
import {ViewMeetingAlertState} from '../../types/meeting-types';
// eslint-disable-next-line max-len
import CustomRegistrationQuestions from '../../components/FormComponents/RegistrationFormComponents/CustomRegistrationQuestions/CustomRegistrationQuestions';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';

function getRegAllowed(meeting_reg_data: MeetingRegData): RegAllowed {
  const {is_in_person, is_virtual} = meeting_reg_data;
  const {
    is_open_for_reg,
    is_in_person_at_capacity,
    is_in_person_reg_ended,
    is_virtual_at_capacity,
    is_virtual_reg_ended,
  } = getMeetingRegSummary(meeting_reg_data);

  const is_in_person_reg_allowed =
    is_open_for_reg && is_in_person && !is_in_person_at_capacity && !is_in_person_reg_ended;
  const is_virtual_reg_allowed =
    is_open_for_reg && is_virtual && !is_virtual_at_capacity && !is_virtual_reg_ended;
  const is_reg_allowed = is_in_person_reg_allowed || is_virtual_reg_allowed;

  return {
    is_reg_allowed,
    is_in_person_reg_allowed,
    is_virtual_reg_allowed,
  };
}

interface Props {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
  account_data: AccountGetPutData;
}

export default function Registration({meeting_id, meeting_data, account_data}: Props) {
  usePageTitle(`Register for ${meeting_data.title || 'Untitled Meeting'}`);

  const reg_allowed = useMemo(() => getRegAllowed(meeting_data), [meeting_data]);

  const initial_data: RegistrationForm = {
    is_in_person_reg: reg_allowed.is_in_person_reg_allowed && !reg_allowed.is_virtual_reg_allowed,
    is_virtual_reg: reg_allowed.is_virtual_reg_allowed && !reg_allowed.is_in_person_reg_allowed,
    first_name: account_data.first_name ?? '',
    last_name: account_data.last_name ?? '',
    org: account_data.org ?? '',
    job_title: account_data.job_title ?? '',
    phone: account_data.phone ?? '',
    comments: '',
    cr_responses: meeting_data.cr_questions.map((crq) => ({
      cr_question_fk: crq.pk,
      responses: crq.type == 'radio' ? [null] : [''],
    })),
  };

  const [formData, setFormData] = useState<RegistrationForm>(initial_data);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const mutation = useMutationCreateRegistrant();
  const navigate = useNavigate();

  const handleInputChange = handleTypedInputChange(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);

  const [initialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  useEffect(() => {
    addOrRemoveValidationMessage({
      is_condition_met: !reg_allowed.is_reg_allowed,
      err_message: meeting_registration_closed_msg,
      setValidationMessages,
    });
  }, [reg_allowed.is_reg_allowed]);

  const is_submit_disabled =
    !!validationMessages.length ||
    mutation.isPending ||
    // On mutation success, we navigate to another page. Leave the submit button disabled.
    mutation.isSuccess;
  const is_discard_disabled =
    mutation.isPending ||
    // On mutation success, we navigate to another page. Leave the discard feature disabled.
    mutation.isSuccess;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    mutation.mutate(
      {form: formData, meeting_id: meeting_id},
      {
        onSuccess: () =>
          navigate(`/meetings/${meeting_id}`, {
            state: {is_successful_reg: true} as ViewMeetingAlertState,
          }),
      }
    );
  };

  const selected_format = useMemo(() => {
    if (formData.is_in_person_reg) return Format.in_person;
    if (formData.is_virtual_reg) return Format.virtual;
  }, [formData.is_in_person_reg, formData.is_virtual_reg]);

  const meeting_formats = useMemo(() => {
    const formats: Format[] = [];
    if (meeting_data.is_in_person) formats.push(Format.in_person);
    if (meeting_data.is_virtual) formats.push(Format.virtual);
    return formats;
  }, [meeting_data.is_in_person, meeting_data.is_virtual]);

  return (
    <section className="grid-container-tablet margin-bottom-10 padding-x-0">
      {mutation.isError && <FormGenericError error={mutation.error} />}
      {!reg_allowed.is_reg_allowed && (
        <Alert type="info" headingLevel="h2" className="margin-bottom-2" slim>
          {meeting_registration_closed_msg}
        </Alert>
      )}
      <RegistrationHeading
        meeting_title={meeting_data.title}
        meeting_start_datetime={meeting_data.start_datetime}
        form_variant={RegFormVariant.create}
      />
      <FormLayout handleSubmit={handleSubmit}>
        <div className="margin-top-1">
          <RegistrationFormatRadio
            setFormData={setFormData}
            setValidationMessages={setValidationMessages}
            reg_allowed={reg_allowed}
            selected_format={selected_format}
            meeting_formats={meeting_formats}
            form_variant={RegFormVariant.create}
          />
        </div>
        <section className="margin-top-4 mobile-lg:margin-top-4 bg-base-lightest padding-2">
          <Grid className="grid-row grid-gap margin-top-neg-3">
            <div className="mobile-lg:grid-col-6">
              <FormEmailInput
                id_name="email"
                input={account_data.email}
                handleChange={() => undefined}
                setValidationMessages={setValidationMessages}
                is_disabled
                form_type="account"
                is_required
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                id_name="first_name"
                input={formData.first_name}
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_required
                is_validate_length
                is_disabled={!reg_allowed.is_reg_allowed}
                form_type="account"
              />
            </div>
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                id_name="last_name"
                input={formData.last_name}
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_required
                is_validate_length
                is_disabled={!reg_allowed.is_reg_allowed}
                form_type="account"
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                input={formData.job_title}
                id_name="job_title"
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_validate_length
                is_disabled={!reg_allowed.is_reg_allowed}
                form_type="account"
              />
            </div>
            <div className="mobile-lg:grid-col-6">
              <FormTextInput
                input={formData.org}
                id_name="org"
                handleChange={handleInputChange}
                setValidationMessages={setValidationMessages}
                is_validate_length
                is_disabled={!reg_allowed.is_reg_allowed}
                form_type="account"
              />
            </div>
          </Grid>
          <Grid className="grid-row grid-gap">
            <div className="mobile-lg:grid-col-6">
              <FormPhoneInputLazy
                phone={formData.phone}
                handleChange={handlePhoneChange}
                setValidationMessages={setValidationMessages}
                is_disabled={!reg_allowed.is_reg_allowed}
              />
            </div>
          </Grid>
          <p className="text-italic font-sans-xs margin-bottom-0">
            The fields displayed above are from your account information. If you wish to change
            these values for this meeting registration, you may do so here. The changes won&#39;t
            affect your account.
          </p>
        </section>
        <FormTextArea
          input={formData.comments}
          id_name="comments"
          handleChange={handleTextAreaChange}
          setValidationMessages={setValidationMessages}
          is_validate_length
          is_disabled={!reg_allowed.is_reg_allowed}
          form_type="registration"
        />
        {!!meeting_data.cr_questions.length && (
          <CustomRegistrationQuestions
            cr_questions={meeting_data.cr_questions}
            form_variant={RegFormVariant.create}
            formData={formData}
            setFormData={setFormData}
            setValidationMessages={setValidationMessages}
          />
        )}
        <BottomContainer>
          <div className="grid-container-tablet padding-x-0 grid-row flex-justify-end">
            <FormSubmit
              validationMessages={validationMessages}
              is_disabled={is_submit_disabled}
              ref={submit_btn_ref}
            >
              Confirm registration
            </FormSubmit>
          </div>
        </BottomContainer>
        <DiscardChanges
          isFormDirty={isFormDirty}
          disable_nav_blocker={is_discard_disabled}
          visibility="none"
        />
      </FormLayout>
    </section>
  );
}
