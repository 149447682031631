import {useState} from 'react';
import {Checkbox, Alert, ModalFooter, Button, ButtonGroup} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';
import SupportEmailLink from '../../SupportEmailLink/SupportEmailLink';
import {MtgBroadcastModalState} from '../../../pages/ViewMeeting/ViewMeetingTab-Broadcast';
import {UseMutationResult} from '@tanstack/react-query';
import {MtgBroadcastPostData} from '../../../../common/types/mtg-broadcast-post-res';
import {MtgBroadcastMutationParams} from '../../../services/broadcast-queries';
import FormGenericError from '../../FormComponents/FormGenericError/FormGenericError';
import {
  broadcast_checkbox_warning,
  isQueryError,
  partially_sent_err_msg,
  unsent_err_msg,
} from '../../../lib/utils';

interface Props {
  modalState: MtgBroadcastModalState;
  setModalState: React.Dispatch<React.SetStateAction<MtgBroadcastModalState>>;
  sendBroadcast: () => void;
  mutation: UseMutationResult<MtgBroadcastPostData, unknown, MtgBroadcastMutationParams>;
}

export default function SendMtgBroadcastModal({
  modalState,
  setModalState,
  sendBroadcast,
  mutation,
}: Props) {
  const [isWarnChecked, setIsWarnChecked] = useState(false);

  function handleClose() {
    mutation.reset();
    setModalState({is_open: false});
    setIsWarnChecked(false);
  }

  // For situations where one or more, but not all, emails failed.
  // https://github.com/CSNW/primis-mtg2/pull/591#issuecomment-1796976955
  const is_broadcast_incomplete = !!(
    mutation.isError &&
    isQueryError(mutation.error) &&
    mutation.error.response?.data.message.includes('Some emails')
  );

  return (
    <GenericModalLayout
      isOpen={modalState.is_open}
      closeModal={handleClose}
      modal_heading={modalState.is_preview ? 'Preview email sent' : 'Send broadcast'}
    >
      <div className="usa-prose">
        {modalState.is_preview ? (
          <>
            <p>
              A preview of this broadcast has been sent to <i>{modalState.preview_email}</i>.
            </p>
            <p>
              When you&apos;re ready to send this email broadcast to all recipients specified in the
              form, click the &quot;Send broadcast&quot; button.
            </p>
          </>
        ) : (
          <>
            {!is_broadcast_incomplete && (
              <Checkbox
                id="confirm-broadcast-warning"
                name="confirm-broadcast-warning"
                label={broadcast_checkbox_warning}
                className="margin-y-3"
                checked={isWarnChecked}
                onChange={(event) => setIsWarnChecked(event.currentTarget.checked)}
              />
            )}
          </>
        )}
      </div>
      {mutation.isError &&
        (is_broadcast_incomplete ? (
          <Alert type="error" headingLevel="h2" slim>
            {partially_sent_err_msg}
            <br />
            We do not recommend resending this broadcast, as it was sent successfully to a portion
            of the recipients. Please contact <SupportEmailLink /> so that the support team may
            identify and reattempt the unsent emails.
          </Alert>
        ) : (
          <FormGenericError message={unsent_err_msg} error={mutation.error} />
        ))}
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          {!modalState.is_preview && !is_broadcast_incomplete && (
            <Button
              type="button"
              disabled={!isWarnChecked || mutation.isPending}
              onClick={sendBroadcast}
            >
              {mutation.isPending ? <TextLoading /> : 'Send broadcast'}
            </Button>
          )}
          <Button type="button" onClick={handleClose} disabled={mutation.isPending} unstyled>
            {modalState.is_preview ? 'Return to broadcast form' : 'Cancel'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </GenericModalLayout>
  );
}
