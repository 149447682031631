import {useParams, useSearchParams} from 'react-router-dom';
import {useQueryConfirmToken} from '../../services/auth-queries';
import {Navigate} from 'react-router-dom';
import {logAndGetErrMessage} from '../../lib/utils';
import LoginError from '../LoginError/LoginError';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import PageLoading from '../../components/LoadingComponents/PageLoading/PageLoading';

export default function Confirmation() {
  usePageTitle('Confirmation');

  const {token_id} = useParams();
  const [searchParams] = useSearchParams();

  let redirect = searchParams.get('redirect') || '/';
  if (!/^\//.test(redirect)) {
    redirect = '/';
  }

  const token_confirm_query = useQueryConfirmToken(token_id ?? '');

  if (token_confirm_query.isPending) {
    return <PageLoading />;
  } else if (token_confirm_query.isError) {
    const err_message = logAndGetErrMessage(token_confirm_query.error);
    return <LoginError is_disabled_user={err_message.includes('disabled')} />;
  }

  if (!token_confirm_query.data.is_user_verified) {
    let toUrl = '/confirm-account';
    if (redirect != '/') {
      toUrl += `?redirect=${encodeURIComponent(redirect)}`;
    }
    return <Navigate to={toUrl} />;
  }

  return <Navigate to={redirect} />;
}
