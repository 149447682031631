import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {SortableItem} from './SortableItem/SortableItem';
import DisplayItem from './DisplayItem/DisplayItem';

export interface DnDItem {
  id: string;
  content: ReactNode;
}
interface Props {
  items: DnDItem[];
  setItems: Dispatch<SetStateAction<DnDItem[]>>;
}

export default function DnDContextProvider({items, setItems}: Props) {
  const [activeItem, setActiveItem] = useState<DnDItem>();
  // Sensors are how dnd-kit detects input methods
  // https://docs.dndkit.com/api-documentation/sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  function handleDragStart(event: DragStartEvent) {
    const {active} = event;
    setActiveItem(items.find((item) => item.id == active.id));
  }

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (!over) return;

    if (active.id != over.id) {
      setItems((prevItems) => {
        const oldIndex = items.findIndex((item) => item.id == active.id);
        const newIndex = items.findIndex((item) => item.id == over.id);
        return arrayMove<DnDItem>(prevItems, oldIndex, newIndex);
      });
    }
    setActiveItem(undefined);
  }

  return (
    <div className="margin-top-2 border-accent-cool border-2px border-dashed padding-2">
      {/* dnd-kit suggests using closest corners or closest center collision detection
          for sortable lists:
          https://docs.dndkit.com/presets/sortable#collision-detection-algorithm
      */}
      <DndContext
        collisionDetection={closestCorners}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {/* dnd-kit recommends using a presentational component in DragOverlay, and a sortable component that
              renders the presentational component that is sortable via refForwarding:
              https://docs.dndkit.com/presets/sortable#drag-overlay
          */}
          {items.map((item) => (
            <SortableItem key={item.id} item={item} />
          ))}
        </SortableContext>

        {/* The use of DragOverlay is optional, but suggested for most sortable lists, especially if scrollable: 
            https://docs.dndkit.com/presets/sortable#drag-overlay
        */}
        <DragOverlay>
          {activeItem ? <DisplayItem id={activeItem.id} item={activeItem} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
