import {useId} from 'react';
import {Tooltip} from 'react-tooltip';
import styles from './TextWithTooltip.module.scss';

interface Props {
  display_text: string;
  tooltip_text: string;
}

export default function TextWithTooltip({display_text, tooltip_text}: Props) {
  const id = useId();
  return (
    <>
      <span id={id}>{display_text}</span>
      <Tooltip anchorSelect={`#${CSS.escape(id)}`} place="bottom" className={styles.table_tooltip}>
        {tooltip_text}
      </Tooltip>
    </>
  );
}
