import {SummaryBox, SummaryBoxContent} from '@trussworks/react-uswds';
import {ReactNode} from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

export default function DisplayBox({title, children}: Props) {
  return (
    <>
      <h3 className="text-primary-dark margin-0">{title}</h3>
      <SummaryBox className="bg-base-lightest radius-0 padding-0 margin-top-105 margin-bottom-4 border-0">
        <SummaryBoxContent className="padding-x-2 padding-bottom-05 word-break-word">
          {children}
        </SummaryBoxContent>
      </SummaryBox>
    </>
  );
}
