import logingovlogo from '../../../images/login-gov-logo.svg';

export default function LinkedLoginGovInfo() {
  return (
    <p className="text-italic line-height-sans-5 margin-0">
      <img src={logingovlogo} className="height-105 text-middle margin-right-1" alt="Login.gov" />
      <span>This account is linked to Login.gov.</span>
    </p>
  );
}
