import {Alert} from '@trussworks/react-uswds';
import SupportEmailLink from '../SupportEmailLink/SupportEmailLink';
import {ReactNode} from 'react';

type Props = {
  /** Only inline child nodes are supported, no blocks. */
  children: ReactNode;
  className?: string;
  cta?: ReactNode;
};

export default function ErrorAlert({children, className, cta}: Props) {
  return (
    <Alert
      type="error"
      headingLevel="h2"
      className={'margin-bottom-2' + (className ? ` ${className}` : '')}
      slim
      cta={cta}
    >
      {children}
      <br />
      <span className="display-inline-block margin-top-105">
        If the issue persists, please reach out to <SupportEmailLink />.
      </span>
    </Alert>
  );
}
