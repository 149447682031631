import {ModalSwitchTypes, UserNavTypes} from '../../../types/auth-types';
import logingovlogo from '../../../images/login-gov-logo.svg';
import {useMutationPivAuth} from '../../../services/auth-queries';
import TextLoading from '../../LoadingComponents/TextLoading/TextLoading';
import {Button} from '@trussworks/react-uswds';
import {getLoginGovLoginUrl} from '../../../lib/utils';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

interface Props {
  setCurrentModal: React.Dispatch<React.SetStateAction<ModalSwitchTypes>>;
  setUserNav: React.Dispatch<React.SetStateAction<UserNavTypes>>;
  redirect?: string;
}
export default function AuthIndex({setCurrentModal, setUserNav, redirect}: Props) {
  const pivAuthMutation = useMutationPivAuth();
  const navigate = useNavigate();

  function handlePivClick() {
    pivAuthMutation
      .mutateAsync()
      .then(() => {
        if (redirect && /^\//.test(redirect)) {
          navigate(redirect);
        }
      })
      .catch(() => {
        if (g_is_dev) {
          alert(
            "In dev mode? This logic won't work if you aren't using IIS with the correct SSL settings"
          );
        }
      });
  }

  useEffect(() => {
    if (pivAuthMutation.isError) setCurrentModal('piv-error');
  }, [setCurrentModal, pivAuthMutation.isError]);

  return (
    <>
      <h2 className="margin-0">Sign in</h2>
      <h3 className="hrheader margin-top-3 margin-bottom-1">Public & non-DOT personnel</h3>
      <a
        href={getLoginGovLoginUrl(redirect)}
        className="usa-button usa-button--outline width-full margin-bottom-1 box-shadow-3 word-break-word"
      >
        Sign in with <img src={logingovlogo} className="height-105" alt="Login.gov" />
      </a>
      <div className="text-center">
        <Button
          type="button"
          className="usa-button usa-button--unstyled display-inline-block text-left"
          onClick={() => {
            setUserNav(null);
            setCurrentModal('auth');
          }}
        >
          Don&#39;t have a Login.gov account?
        </Button>
      </div>
      <h3 className="hrheader margin-top-3 margin-bottom-1">DOT personnel</h3>
      <Button
        type="button"
        className="usa-button--outline width-full margin-bottom-1 box-shadow-3 word-break-word"
        aria-controls="authentication-modal"
        onClick={handlePivClick}
        disabled={pivAuthMutation.isPending}
      >
        {pivAuthMutation.isPending ? <TextLoading /> : 'Sign in with DOT PIV card'}
      </Button>

      <div className="text-center">
        <Button
          type="button"
          className="usa-button--unstyled display-inline-block text-left"
          onClick={() => {
            setUserNav('piv');
            setCurrentModal('auth');
          }}
        >
          PIV not yet associated with app?
        </Button>
      </div>
      <hr className="margin-top-5 margin-bottom-4" />
      <h2 className="margin-0">Sign up</h2>
      <h3 className="hrheader margin-top-3 margin-bottom-1">Public, non-DOT, & DOT personnel</h3>
      <Button
        type="button"
        className="usa-button--outline width-full box-shadow-3 word-break-word"
        onClick={() => {
          setUserNav('signup');
          setCurrentModal('auth');
        }}
      >
        Create an account
      </Button>
    </>
  );
}
