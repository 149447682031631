import {Link} from 'react-router-dom';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import SupportEmailLink from '../../components/SupportEmailLink/SupportEmailLink';
import {useQueryPrivileges} from '../../services/account-queries';
import {visit_homepage_title} from '../../lib/utils';

export default function DownloadError() {
  usePageTitle('File Unavailable');
  const privileges_query = useQueryPrivileges({});

  return (
    <div>
      <h2>File unavailable</h2>

      <p className="usa-intro">The file you requested is missing or unavailable.</p>
      {privileges_query.isError && (
        <p>If the file is protected, you may need to sign-in and then try to access it again.</p>
      )}
      <p>
        If the problem persists, please reach out to <SupportEmailLink />.
      </p>

      <Link to="/" className="usa-button margin-top-1">
        {visit_homepage_title}
      </Link>
    </div>
  );
}
