import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import {Tooltip} from 'react-tooltip';

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  modal_heading: string;
  children: React.ReactNode;
  is_btn_enabled: boolean;
  is_cro?: true;
  handleDelete: () => void;
};

export default function DeleteCRModal({
  isModalOpen,
  closeModal,
  modal_heading,
  children,
  is_cro,
  handleDelete,
  is_btn_enabled,
}: Props) {
  const del_btn_id = 'crq-del-modal-del-btn';
  const option_or_question_string = is_cro ? 'option' : 'question';

  const handleConfirm = () => {
    if (!is_btn_enabled) return;

    handleDelete();
    closeModal();
  };

  return (
    <GenericModalLayout isOpen={isModalOpen} closeModal={closeModal} modal_heading={modal_heading}>
      <div className="usa-prose">{children}</div>
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <Button
            id={del_btn_id}
            type="button"
            className="usa-button--secondary"
            onClick={handleConfirm}
            aria-disabled={!is_btn_enabled}
          >
            Mark for deletion
          </Button>
          <Button type="button" onClick={closeModal} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
        {!is_btn_enabled && (
          <Tooltip anchorSelect={`#${del_btn_id}`} place="bottom">
            Please ensure that all fields for this {option_or_question_string} are valid in order to
            mark this {option_or_question_string} for deletion
          </Tooltip>
        )}
      </ModalFooter>
    </GenericModalLayout>
  );
}
