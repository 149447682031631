import {useLocation, useNavigate} from 'react-router-dom';
import Meeting, {MeetingProps} from './ViewMeetingTab-Meeting';
import Registrants, {RegistrantsProps} from './ViewMeetingTab-Registrants';
import {Tab, TabList, TabPanel, Tabs, TabsProps} from 'react-tabs';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import {view_meeting_tab_dict as tab_dict} from '../../lib/utils';
import styles from './ViewMeeting.module.scss';
import {useEffect, useMemo, useState} from 'react';
import StatusNameTag from '../../components/MeetingComponents/StatusNameTag/StatusNameTag';
import Broadcast, {BroadcastProps} from './ViewMeetingTab-Broadcast';

type ViewMeetingTabsProps = Omit<MeetingProps & RegistrantsProps & BroadcastProps, 'active_tab'>;
const default_tab: keyof typeof tab_dict = 'meeting';

export default function ViewMeetingTabs(props: ViewMeetingTabsProps) {
  const {meeting_data, privileges_data} = props;

  usePageTitle(meeting_data.title || 'Untitled Meeting');
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(default_tab);
  const tab_props = {...props, active_tab: activeTab};
  const available_tabs = useMemo(() => {
    const tabs: Array<keyof typeof tab_dict> = ['meeting'];
    if (privileges_data?.can_view_registrants) tabs.push('registrants');
    if (privileges_data?.can_send_broadcast) tabs.push('broadcast');
    return tabs;
  }, [privileges_data?.can_send_broadcast, privileges_data?.can_view_registrants]);
  const default_tab_idx = available_tabs.indexOf(default_tab);
  // one tab just means one page, so no need to show tabs
  const show_tabs = available_tabs.length > 1;

  useEffect(() => {
    if (!show_tabs) return;

    // Only handle recognized tab names. It should still be possible to support anchors on the page if desired.
    const hash_tab = location.hash.replace('#', '');
    const hash_tab_idx = (available_tabs as string[]).indexOf(hash_tab);
    if (hash_tab == default_tab) {
      // Strip the hash from URL for the default tab
      navigate(location.pathname + location.search, {replace: true});
    } else if (!hash_tab) {
      // React will not rerender if this is already the active tab
      setActiveTab(default_tab);
    } else if (hash_tab_idx != default_tab_idx && hash_tab_idx != -1) {
      setActiveTab(hash_tab as keyof typeof tab_dict);
    }
  }, [show_tabs, location, navigate, available_tabs, default_tab_idx]);

  const handleTabSelect: TabsProps['onSelect'] = (idx) => {
    const tab_name = available_tabs[idx];
    if (tab_name == activeTab) return;

    const selected_tab_idx = available_tabs.indexOf(tab_name);
    if (selected_tab_idx != default_tab_idx && selected_tab_idx != -1) {
      navigate(`#${tab_name}`, {replace: true});
    } else {
      navigate(location.pathname + location.search, {replace: true});
    }
  };

  return (
    <>
      <StatusNameTag name={meeting_data.status.name} />
      <h2 className="margin-top-2 margin-left-0 text-primary font-heading-lg">
        {meeting_data.title}
      </h2>
      {show_tabs ? (
        <div className={styles.container}>
          <Tabs onSelect={handleTabSelect} selectedIndex={available_tabs.indexOf(activeTab)}>
            <TabList>
              {Object.values(available_tabs).map((name, idx) => (
                <Tab key={idx}>{tab_dict[name]}</Tab>
              ))}
            </TabList>
            {Object.values(available_tabs).map((name, idx) => (
              <TabPanel key={idx}>
                {name == 'meeting' && <Meeting {...tab_props} />}
                {name == 'registrants' && <Registrants {...tab_props} />}
                {name == 'broadcast' && <Broadcast {...tab_props} />}
              </TabPanel>
            ))}
          </Tabs>
        </div>
      ) : (
        <div className={`${styles.container} ${styles.smaller_margin}`}>
          <Meeting {...tab_props} />
        </div>
      )}
    </>
  );
}
