import {Breadcrumb, BreadcrumbBar} from '@trussworks/react-uswds';
import {useQueryMyAccount} from '../../../services/account-queries';
import MyAccount from '../MyAccount';
import {Link} from 'react-router-dom';
import PageLoading from '../../../components/LoadingComponents/PageLoading/PageLoading';

export default function MyAccountWrapper() {
  const query = useQueryMyAccount();

  // This shouldn't render since we're using err boundary in the query
  if (query.isError) return <>Error</>;

  return (
    <>
      <BreadcrumbBar variant="wrap">
        <Breadcrumb>
          <Link to="/" className="usa-breadcrumb__link">
            Home
          </Link>
        </Breadcrumb>
        <Breadcrumb current>
          <span>My account</span>
        </Breadcrumb>
      </BreadcrumbBar>
      {query.isPending ? (
        <PageLoading message="Loading account" />
      ) : (
        <MyAccount query_data={query.data} />
      )}
    </>
  );
}
