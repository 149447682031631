import {fromUTCtoET} from '../../../lib/utils';

interface Props {
  start_date?: string | null;
  end_date?: string | null;
  custom_start_string?: string;
  custom_end_string?: string;
}

export function MtgStartEndDate({
  start_date,
  end_date,
  custom_start_string,
  custom_end_string,
}: Props) {
  return (
    <p className="display-flex grid-row margin-0">
      {start_date && (
        <span className="margin-right-2">
          <span className="text-bold">{custom_start_string || 'Start:'} </span>
          {fromUTCtoET(start_date)}
        </span>
      )}
      {end_date && (
        <span>
          <span className="text-bold">{custom_end_string || 'End:'} </span>
          {fromUTCtoET(end_date)}
        </span>
      )}
    </p>
  );
}
