import {Button, ButtonGroup, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import {Tooltip} from 'react-tooltip';

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  file_title: string;
  is_btn_enabled: boolean;
  handleDeleteFile: () => void;
};

export default function DeleteFileModal({
  isModalOpen,
  closeModal,
  file_title,
  handleDeleteFile,
  is_btn_enabled,
}: Props) {
  const del_btn_id = 'file-del-modal-del-btn';
  const modal_heading = `Delete "${file_title}"`;

  const handleConfirm = () => {
    if (!is_btn_enabled) return;

    handleDeleteFile();
    closeModal();
  };

  return (
    <GenericModalLayout isOpen={isModalOpen} closeModal={closeModal} modal_heading={modal_heading}>
      <div className="usa-prose">
        <p>Are you sure you want to delete this file?</p>
        <p>
          Clicking the &quot;Mark for deletion&quot; button below will cause this file to be deleted
          when the &quot;Save changes&quot; button is clicked and the meeting is saved. If
          &quot;Save changes&quot; is not clicked, this file will remain in place.
        </p>
      </div>
      <ModalFooter>
        <ButtonGroup
          type="default"
          className="flex-row-reverse flex-wrap flex-align-center gap-x-105"
        >
          <Button
            id={del_btn_id}
            type="button"
            className="usa-button--secondary"
            onClick={handleConfirm}
            aria-disabled={!is_btn_enabled}
          >
            Mark for deletion
          </Button>
          <Button type="button" onClick={closeModal} unstyled>
            Cancel
          </Button>
        </ButtonGroup>
        {!is_btn_enabled && (
          <Tooltip anchorSelect={`#${del_btn_id}`} place="bottom">
            Please ensure that all fields for this file are valid in order to mark this file for
            deletion
          </Tooltip>
        )}
      </ModalFooter>
    </GenericModalLayout>
  );
}
