import {fromUTCtoET, getRegistrationFormatStr} from '../../../lib/utils';
import {RegistrantsGetData} from '../../../../common/types/registrants-get-res';
import metadata_client_account from '../../../lib/metadata-client-account.json';
import {MeetingsGetByIdData} from '../../../../common/types/meetings-get-by-id-res';
import {stringify} from 'csv-stringify/browser/esm';
import {DateTime} from 'luxon';
import {Tooltip} from 'react-tooltip';
import {useEffect, useState} from 'react';
import SupportEmailLink from '../../SupportEmailLink/SupportEmailLink';

interface Props {
  meeting_data: MeetingsGetByIdData;
  registrants_data: RegistrantsGetData;
  is_disabled: boolean;
  disabled_tooltip?: string;
}

export default function ExportRegistrantsButton({
  meeting_data,
  registrants_data,
  is_disabled,
  disabled_tooltip,
}: Props) {
  // Use null to indicate an error while preparing CSV blob and use undefined to indicate unset.
  const [csvBlob, setCsvBlob] = useState<File | null | undefined>();
  const [csvUrl, setCsvUrl] = useState<string | null | undefined>();
  const is_export_disabled = is_disabled || !csvBlob;

  useEffect(() => {
    const csv_data = registrants_data.map((registrant) => {
      const reg_data: Record<string, string | undefined> = {
        first_name: registrant.first_name,
        last_name: registrant.last_name,
        format: getRegistrationFormatStr(registrant),
        did_attend: registrant.did_attend ? 'Yes' : 'No',
        email: registrant.email,
        org: registrant.org ?? '',
        job_title: registrant.job_title ?? '',
        registration_time: fromUTCtoET(registrant.create_datetime),
        phone: registrant.phone ?? '',
        comments: registrant.comments ?? '',
      };

      meeting_data.cr_questions.forEach((crq) => {
        const cr_res = registrant.cr_responses.find((res) => res.cr_question_fk == crq.pk);

        if (cr_res?.responses.length == 0 || !cr_res?.responses[0]) {
          reg_data[crq.pk] = '';
        } else if (crq.cr_options) {
          // Right now, we only allow a single response.
          // This logic will need to be updated if we enable multi-select CRQs in the future
          reg_data[crq.pk] =
            crq.cr_options.find((cr_opt) => cr_opt.pk == cr_res.responses[0])?.title ?? '';
        } else {
          reg_data[crq.pk] = cr_res.responses[0];
        }
      });

      return reg_data;
    });

    const csv_headers = [
      {
        label: metadata_client_account.first_name.title,
        key: 'first_name',
      },
      {
        label: metadata_client_account.last_name.title,
        key: 'last_name',
      },
      {
        label: 'Format',
        key: 'format',
      },
      {
        label: 'Did attend?',
        key: 'did_attend',
      },
      {
        label: metadata_client_account.email.title,
        key: 'email',
      },
      {
        label: metadata_client_account.org.title,
        key: 'org',
      },
      {
        label: metadata_client_account.job_title.title,
        key: 'job_title',
      },
      {
        label: 'Registration time',
        key: 'registration_time',
      },
      {
        label: metadata_client_account.phone.title,
        key: 'phone',
      },
      {
        label: 'Comments',
        key: 'comments',
      },
      ...meeting_data.cr_questions.map((crq) => ({
        label: crq.title,
        key: crq.pk,
      })),
    ];

    const csv_rows = [
      csv_headers.map(({label}) => label),
      ...csv_data.map((data) => csv_headers.map(({key}) => data[key] ?? '')),
    ];

    const filename = `mtgreglist_${DateTime.utc().toFormat('yyyyLLdd-HHmmss')}.csv`;
    stringify(csv_rows, (err, output) => {
      if (err) {
        if (g_is_dev) console.error('Failed to create CSV blob\n', err);
        setCsvBlob(null);
        setCsvUrl(null);
      } else {
        const blob = new File([output], filename, {type: 'text/csv'});
        setCsvBlob(blob);
        setCsvUrl(URL.createObjectURL(blob));
      }
    });

    return () => {
      setCsvUrl((url) => {
        if (url) URL.revokeObjectURL(url);
        return undefined;
      });
      setCsvBlob(undefined);
    };
  }, [meeting_data.cr_questions, registrants_data]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (is_export_disabled) event.preventDefault();
  };

  return (
    <>
      <a
        id="export-registrants"
        href={csvUrl ?? undefined}
        className="usa-button usa-button--primary"
        download={csvBlob?.name}
        aria-disabled={is_export_disabled}
        onClick={handleClick}
      >
        Export registrant list
      </a>
      {is_disabled && !!disabled_tooltip && (
        <Tooltip anchorSelect="#export-registrants" place="bottom" className="z-100">
          {disabled_tooltip}
        </Tooltip>
      )}
      {!is_disabled && Object.is(csvBlob, null) && (
        <Tooltip anchorSelect="#export-registrants" place="bottom" className="z-100">
          A problem was encountered while preparing this export. Please refresh the page and try
          again. If this issue persists, contact support at <SupportEmailLink /> for assistance.
        </Tooltip>
      )}
    </>
  );
}
