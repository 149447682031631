import axios, {AxiosError} from 'axios';
import {ErrorResBody} from '../../common/types/error-response';
import SuccessFalseError from './success-false-error';
import metadata_client_account from '../lib/metadata-client-account.json';
import metadata_client_meeting from '../lib/metadata-client-meeting.json';
import metadata_client_registration from '../lib/metadata-client-registration.json';
import {HandleInputChange, SetValidationMessages} from '../types/form-types';
import {DateTime} from 'luxon';
import {
  MtgPostPutReqCRQuestion,
  MeetingForm,
  MtgFormFile,
  MtgFormCRQuestion,
} from '../types/meeting-types';
import {MtgPostPutReqCROption, MtgPostPutReqFile} from '../../common/types/meetings-post-put-req';
import {Value} from 'react-phone-number-input';
import {AuthLogGetItem, AuthLogGetResUser} from '../../common/types/auth-log-get-res';
import {DateTimeFilter} from '../services/session-mgmt-queries';

export const debounce_delay_ms = 200;
export const cr_option_max_length = 255;

export function convertCharsToKB(chars: number) {
  return chars / 1000;
}

export function getNumValidationMsg(title: string) {
  return `Please only enter numerical values for ${title}`;
}
export function getMinMaxValidationMsg(title: string, num: number, opts: {is_min: boolean}) {
  return `${title} may not be ${opts.is_min ? 'lower' : 'higher'} than ${num}`;
}
export function getCutoffBeforeEndValidationMsg(title: string) {
  return `${title} registration cutoff time must take place before meeting end time`;
}
export function getLengthValidationMsg(title: string, max_length: number, is_rtf?: boolean) {
  if (is_rtf) return `${title} cannot contain more than ${convertCharsToKB(max_length)}KB of data`;
  return `${title} cannot be more than ${max_length} characters`;
}

export const time_title = 'Time';
export const time_note = 'All times should be noted in Eastern time';
export const time_range_title = 'Adjust time range';

export const format_title = 'Format';
export const format_required_message = 'Please select at least one format option';

export const registration_title = 'Registration';

export const end_time_after_start_validation = 'Start time must take place before end time';

export const broadcast_email_message = 'Remember to send a broadcast email about this change';

export const piv_already_associated = 'Selected PIV card is already associated with this account.';

export const visit_homepage_title = 'Visit homepage';

// Success messages
// Account mgmt
export const self_acc_delete_success = 'Your account has been successfully deleted.';
export const acc_delete_success = 'Account successfully deleted.';
export const acc_update_success = 'Account information successfully updated.';
export const acc_create_success = (email: string) => `Account successfully created for ${email}.`;
// Emails (including broadcast)
export const email_reattempt_success = 'Email successfully reattempted.';
export const broadcast_success = 'The broadcast has been successfully sent.';
// Meetings
export const mtg_delete_success = 'Meeting successfully deleted.';
export const mtg_create_success = 'Meeting successfully created.';
export const mtg_edit_success =
  'Meeting updated successfully. If your changes will affect registrants, remember to send a broadcast email.';
// PIV
export const piv_delete_success = 'PIV certificate successfully deleted.';
export const piv_associate_success =
  'PIV card was successfully associated with your account. You will now be able to log in with this PIV card.';
// Registration (including attendance)
export const attendance_update_success = 'Attendance successfully updated.';
export const reg_create_success = 'You have been successfully registered.';
export const reg_update_success = 'Your registration has been successfully updated.';
export const reg_remove_success = 'Your registration has been removed.';
// Session mgmt & auth
export const session_revoke_success = 'Session successfully revoked.';
export const logout_success = 'You have been signed out.';

// Error messages
const problem_msg = 'There was a problem';
export const generic_err_msg = `${problem_msg} processing your request.`;
// Emails (including broadcast)
export const unsent_err_msg = `${problem_msg} sending the broadcast.`;
export const partially_sent_err_msg = `${problem_msg} sending the broadcast to one or more recipients.`;
export const email_reattempt_err = `${problem_msg} reattempting the email.`;
// Meetings
export const mtg_delete_err = `${problem_msg} deleting the meeting.`;
export const mtg_list_err = `${problem_msg} loading the meeting list.`;
// PIV
export const piv_delete_err = `${problem_msg} deleting this PIV certificate.`;
// eslint-disable-next-line max-len
export const piv_association_err = `${problem_msg} associating the selected PIV card. You may need to close all browsers before re-trying.`;
// Registration (including attendance)
export const attendance_update_err = `${problem_msg} updating attendance.`;
export const reg_remove_err = `${problem_msg} removing your registration.`;
export const reg_list_err = `${problem_msg} loading the registrant list.`;
export const reg_not_found_err =
  'Registration details were not found. This registration may have been removed.';
export const reg_load_err = `${problem_msg} loading the registration details.`;
// Session mgmt & auth
export const session_revoke_err = `${problem_msg} revoking this session.`;
export const logout_err = 'Sign out unsuccessful.';
export const login_gov_failure = 'Login.gov sign in failed.';
export const auth_err_msg = `${problem_msg} processing your request, see below for more details.`;

export const email_status_background_dict = {
  sent: 'bg-success-dark',
  unsent: 'bg-error-dark',
  partial: 'bg-warning-dark',
};

export const tag_color_dict = {
  primary: 'text-primary border-primary',
  secondary: 'text-secondary-dark border-secondary-dark',
  accent_warm: 'text-accent-warm-dark border-accent-warm-dark',
  base: 'text-base-dark border-base-dark',
};

interface EmailTypeDict {
  [key: string]: {
    tag_color: keyof typeof tag_color_dict;
    name: string;
  };
}

export const email_type_dict: EmailTypeDict = {
  mtg_broadcast: {tag_color: 'primary', name: 'Broadcast'},
  system: {tag_color: 'base', name: 'System'},
};

// If the AxiosError has a response, response.data will be typed as ErrorResBody
export function isAxiosError(err: unknown): err is AxiosError<ErrorResBody> {
  return axios.isAxiosError(err);
}

export function isQueryError(err: unknown): err is AxiosError<ErrorResBody> | SuccessFalseError {
  return isAxiosError(err) || err instanceof SuccessFalseError;
}

export function logAndGetErrMessage(err: unknown) {
  if (isQueryError(err)) {
    let msg = `${err.name}: ${err.message}`;
    if (err.response) msg += `\nResponse body: ${JSON.stringify(err.response.data, null, 2)}`;
    if (g_is_dev) console.log(msg);
    return err.response?.data.message || err.message;
  } else {
    if (g_is_dev) console.log('Query error:', err);
    throw err;
  }
}

export function splitAndTrimEmails(email_str: string) {
  return email_str.split(/,|;|\n/).map((email) => email.trim());
}

export function isEmailValid(email: string) {
  const email_format = new RegExp(metadata_client_account.email.regex_format);

  return (
    !(email.length < metadata_client_account.email.min_length) &&
    !(email.length > metadata_client_account.email.max_length) &&
    email_format.test(email)
  );
}

export function isHttpLinkValid(link: string) {
  const link_format = /^$|^https?:\/\//;
  return link_format.test(link);
}

export function isTruthy<T>(x: T | false | 0 | '' | null | undefined): x is T {
  return !!x;
}

export function capitalizeFirstLetter(string: string) {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

// Instead of putting this in FormTextInput like other handlers in their respective components,
// this handler is used by multiple components such as FormEmailInput
export function handleTypedInputChange<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  const handleInputChange: HandleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData((formData) => ({...formData, [name]: value}));
  };
  return handleInputChange;
}

// This needs to reside outside of the component source file or code splitting doesn't chunk FormPhoneInput correctly.
export function handleTypedPhoneChange<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  return (value?: Value) => {
    // The onChange value from react-phone-number-input is `undefined` when there's an empty value, which is why `?? ''`
    // is needed. Tangential note: even when there is a '+' in the value, the library treats it as an empty value.
    setFormData((formData) => ({...formData, phone: value ?? ''}));
  };
}

export function handleTypedRtfChange<T>(setFormData: React.Dispatch<React.SetStateAction<T>>) {
  const handleRtfInputChange = (target: string, value: string) => {
    setFormData((formData) => ({...formData, [target]: value}));
  };
  return handleRtfInputChange;
}

interface RemoveValidationMessages {
  err_messages: string[];
  setValidationMessages: SetValidationMessages;
  /** Interpret `err_messages` as regexes when comparing messages */
  use_regex?: boolean;
}

export function removeValidationMessages({
  err_messages,
  setValidationMessages,
  use_regex = false,
}: RemoveValidationMessages) {
  setValidationMessages((prev_validation_messages) => {
    const err_indices = err_messages
      .flatMap((msg) =>
        use_regex
          ? prev_validation_messages.map((prev_msg, idx) =>
              new RegExp(msg, 'g').test(prev_msg) ? idx : -1
            )
          : prev_validation_messages.map((prev_msg, idx) => (prev_msg == msg ? idx : -1))
      )
      .filter((idx) => idx >= 0);

    // Only update the state if the validation messages have changed. Otherwise, return the same array to avoid
    // unnecessary renders.
    if (!err_indices.length) return prev_validation_messages;

    return prev_validation_messages.filter((msg, idx) => !err_indices.includes(idx));
  });
}

interface RemoveValidationMessage {
  err_message: string;
  setValidationMessages: SetValidationMessages;
  /** Interpret `err_message` as regex when comparing messages */
  use_regex?: boolean;
}

export function removeValidationMessage({
  err_message,
  setValidationMessages,
  use_regex = false,
}: RemoveValidationMessage) {
  removeValidationMessages({
    err_messages: [err_message],
    setValidationMessages,
    use_regex,
  });
}

interface AddOrRemoveValidationMessage {
  is_condition_met: boolean;
  err_message: string;
  setValidationMessages: SetValidationMessages;
}

export function addOrRemoveValidationMessage({
  is_condition_met,
  err_message,
  setValidationMessages,
}: AddOrRemoveValidationMessage) {
  setValidationMessages((prev_validation_messages) => {
    const err_idx = prev_validation_messages.indexOf(err_message);

    // Only update the state if the validation messages have changed. Otherwise, return the same array to avoid
    // unnecessary renders.
    if ((is_condition_met && err_idx >= 0) || (!is_condition_met && err_idx < 0)) {
      return prev_validation_messages;
    }

    return is_condition_met && err_idx < 0
      ? [...prev_validation_messages, err_message]
      : prev_validation_messages.filter((msg, idx) => idx != err_idx);
  });
}

export const account_view_additional_fields = [
  'pk',
  'is_verified',
  'is_disabled',
  'is_removed',
  'create_datetime',
  'create_user_fk',
  'modify_datetime',
  'modify_user_fk',
  'login_gov_uuid',
  'login_gov_emails',
  'login_first_datetime',
  'login_last_datetime',
  'login_count',
] as const;

export const piv_view_additional_fields = [
  'pk',
  'piv_serialnumber',
  'piv_subject',
  'piv_issuer',
  'is_removed',
  'create_datetime',
  'create_user_fk',
  'modify_datetime',
  'modify_user_fk',
] as const;

export const meeting_view_additional_fields = [
  'pk',
  'create_user_fk',
  'create_datetime',
  'modify_user_fk',
  'modify_datetime',
] as const;

export const registrant_view_additional_fields = [
  'create_user_fk',
  'create_datetime',
  'modify_user_fk',
  'modify_datetime',
] as const;

// These are optional fields in the MeetingsGetByIdResBody that:
// 1. Aren't dependent on if the meeting is in-person or virtual
// 2. Are displayed in the DisplayBox component of ViewMeeting.tsx
// 3. Contain Rich Text formatting
export const view_meeting_display_box_fields = [
  'docket_number',
  'summary',
  'addtl_info',
  'agenda',
  'results',
] as const;

export const meeting_registration_closed_msg = 'Registration for this meeting is currently closed.';

export const meeting_registration_format_validation_msg_dict = {
  select_attendance_plan: metadata_client_registration.format.validation_message,
  format_changed_to_in_person:
    'The format of this meeting has changed to in-person. Please update your attendance plan to in-person.',
  format_changed_to_virtual:
    'The format of this meeting has changed to virtual. Please update your attendance plan to virtual.',
  others_format_changed_to_in_person: 'The format of this meeting has changed to in-person.',
  others_format_changed_to_virtual: 'The format of this meeting has changed to virtual.',
  reg_ended_in_person:
    'Updates to in-person registrations are no longer permitted. You can change to virtual registration if desired.',
  reg_ended_virtual:
    'Updates to virtual registrations are no longer permitted. You can change to in-person registration if desired.',
};

export const view_meeting_tab_dict = Object.freeze({
  meeting: 'Meeting details',
  registrants: 'Registrants',
  broadcast: 'Broadcast',
});
export type ActiveTab = keyof typeof view_meeting_tab_dict;

export function getRegistrationFormatStr({
  is_virtual_reg,
  is_in_person_reg,
}: {
  is_virtual_reg: boolean;
  is_in_person_reg: boolean;
}) {
  // Our logic prevents a user from registering for both virtual & in-person formats, or
  // from not registering for a format at all
  // Including logic for both cases in case that changes
  return is_virtual_reg && is_in_person_reg
    ? 'Virtual & In-person'
    : is_virtual_reg
    ? 'Virtual'
    : is_in_person_reg
    ? 'In-person'
    : 'Format info unavailable';
}

export type RichTextFields = Pick<
  MeetingForm,
  'docket_number' | 'summary' | 'addtl_info' | 'agenda' | 'results'
>;
export const rich_text_fields = ['docket_number', 'summary', 'addtl_info', 'agenda', 'results'];

export function scrollToTop() {
  // Use setTimeout here to ensure that scrolling is the last thing added to the webapi queue
  // and avoid race conditions with window size change that interfere with scroll behavior
  setTimeout(() => window.window.scrollTo({top: 0, behavior: 'smooth'}), 0);
}

export const eastern_tz = 'America/New_York';

export function fromUTCtoET(datetime_str: string, options: {use_long_date?: true} = {}) {
  if (!datetime_str) return '';
  const format_string = options.use_long_date ? 'MMM dd, yyyy, tt' : 'DDD, t';
  const dt = DateTime.fromISO(datetime_str);
  if (!dt.isValid) throw new Error('Invalid date');
  return dt.setZone(eastern_tz).toFormat(format_string) + ' ET';
}

// Convert MM/DD/YYYY to YYYY-MM-DD
export function slashToDashDate(date: string) {
  return DateTime.fromFormat(date, 'MM/dd/yyyy').toISODate();
}

export function isValidSlashDate(date: string) {
  return DateTime.fromFormat(date, 'MM/dd/yyyy').isValid;
}

export function isValidFormTime(time: string) {
  return DateTime.fromFormat(time, 'HH:mm').isValid;
}

export function fromETtoUTC(date: string) {
  const dt = DateTime.fromISO(date, {zone: eastern_tz});
  if (!dt.isValid) throw new Error('Invalid date');
  return dt.toUTC().toISO();
}

export interface ParsedDateTime {
  form_date: string;
  form_time: string;
  iso_datetime: string;
}

export type DefaultDatetimeValue =
  | {form_date: string; form_time: string; iso_datetime?: never}
  | {form_date?: never; form_time?: never; iso_datetime: string};

export function getDefaultDatetime(
  default_value?: DefaultDatetimeValue,
  fallback_form_time = '00:00'
): ParsedDateTime {
  const {form_date, form_time, iso_datetime} = default_value ?? {};
  if (iso_datetime) return outputFormDatetime(iso_datetime);
  if (form_date) return parseFormDatetime(form_date, form_time ?? fallback_form_time);
  return parseFormDatetime('', '');
}

export function parseFormDatetime(form_date: string, form_time: string): ParsedDateTime {
  const dt_state: ParsedDateTime = {
    form_date: '',
    form_time: '',
    iso_datetime: '',
  };
  if (form_time && isValidFormTime(form_time)) {
    dt_state.form_time = form_time;
  }

  if (form_date && isValidSlashDate(form_date)) {
    dt_state.form_date = form_date;
    dt_state.iso_datetime = truncateSeconds(
      fromETtoUTCUnformatted(dt_state.form_date, dt_state.form_time)
    );
  }

  return dt_state;
}

export function outputFormDatetime(iso_datetime: string): ParsedDateTime {
  const dt_state: ParsedDateTime = {
    form_date: '',
    form_time: '',
    iso_datetime: '',
  };

  const dt = DateTime.fromISO(iso_datetime, {zone: eastern_tz}).startOf('minute');
  if (!dt.isValid) return dt_state;
  dt_state.form_date = dt.toFormat('MM/dd/yyyy');
  dt_state.form_time = dt.toFormat('HH:mm');
  dt_state.iso_datetime = dt.toUTC().toISO();
  return dt_state;
}

export function fromETtoUTCUnformatted(date: string, time: string | null) {
  let formatted_date = slashToDashDate(date);
  if (!formatted_date) throw new Error('Invalid date');
  if (time) formatted_date += `T${time}`;
  return fromETtoUTC(formatted_date);
}

export function truncateSeconds(date: string) {
  const dt = DateTime.fromISO(date);
  if (!dt.isValid) throw new Error('Invalid date');
  return dt.startOf('minute').toUTC().toISO();
}

export function inKeysGuard<T extends object>() {
  return function <K extends keyof T>(
    haystack: readonly (keyof T)[] & readonly K[],
    needle: unknown
  ): needle is K {
    return haystack.includes(needle as keyof T);
  };
}

export const isInMeetingFormKeys = inKeysGuard<MeetingForm>();
export const isInDateTimeFilterKeys = inKeysGuard<DateTimeFilter>();

export function stringToNullOrNum(string: string) {
  return string.length ? parseInt(string) : null;
}

export function getPossibleErrMessages(
  capacity_metadata:
    | typeof metadata_client_meeting.in_person_capacity
    | typeof metadata_client_meeting.virtual_capacity,
  other_errs: string[]
) {
  const default_validation = capacity_metadata.validation_message;
  const title = capacity_metadata.title;
  const num_validation = getNumValidationMsg(title);
  const min_validation = getMinMaxValidationMsg(title, capacity_metadata.minimum, {
    is_min: true,
  });
  const max_validation = getMinMaxValidationMsg(title, capacity_metadata.maximum, {
    is_min: false,
  });
  return [...other_errs, default_validation, num_validation, min_validation, max_validation];
}

export function trimFormFields(form_data: MeetingForm) {
  (Object.keys(form_data) as Array<keyof typeof form_data>).forEach((key) => {
    const value = form_data[key];
    if (typeof value == 'string') return ((form_data[key] as string) = value.trim());
  });
}

export enum RegFormVariant {
  create = 'create',
  edit = 'edit',
  edit_others = 'edit_others',
}

export const crq_type_message_dict = {
  textarea: {
    untitled_title: 'Untitled text field question',
    config_title: 'Text field question configuration',
    addtl_subtitle: 'Registrants will be provided a text box to respond.',
  },
  radio: {
    untitled_title: 'Untitled radio button question',
    config_title: 'Radio button question configuration',
    addtl_subtitle: 'Registrants will be provided radio buttons with the options added below.',
  },
};

export function getUniqueCrqId(crq: MtgPostPutReqCRQuestion, cro?: MtgPostPutReqCROption) {
  let output = `crq-${crq.type}${crq.order}`;
  if (cro) output += `-${cro.order}`;
  return output;
}

export function getMtgPostPutReqCRQuestion(crq: MtgFormCRQuestion): MtgPostPutReqCRQuestion {
  // Ensuring additional properties are not included in an object is not yet possible with type checks:
  // https://github.com/microsoft/TypeScript/issues/12936
  // Need to maintain this manually based on MtgFormCRQuestion type in src/client/types/meeting-types.d.ts.
  const {local_id, ...tmp_crq} = crq;
  return tmp_crq.type == 'radio'
    ? {
        ...tmp_crq,
        title: tmp_crq.title.trim(),
        cr_options: tmp_crq.cr_options.map((cro) => {
          const {local_id, ...req_cro} = cro;
          return {
            ...req_cro,
            disabled_note: req_cro.disabled_note && req_cro.disabled_note.trim(),
            title: req_cro.title.trim(),
          };
        }),
      }
    : {
        ...tmp_crq,
        title: tmp_crq.title.trim(),
      };
}

// Meeting Files
export const meeting_file_message_dict = {
  untitled_title: 'Untitled new file',
  new_config_title: 'New file configuration',
};

export function getUniqueFileId(file: MtgFormFile) {
  return `mf-${file.local_id}`;
}

export function getMtgPostPutReqFile(file: MtgFormFile): MtgPostPutReqFile {
  // Ensuring additional properties are not included in an object is not yet possible with type checks:
  // https://github.com/microsoft/TypeScript/issues/12936
  // Need to maintain this manually based on MtgFormFile type in src/client/types/meeting-types.d.ts.
  const {uploaded_file_name, original_file_name, local_id, signature_mismatch, ...req_file} = file;
  return {
    ...req_file,
    title: req_file.title.trim(),
    summary: req_file.summary && req_file.summary.trim(),
    editor_notes: req_file.editor_notes && req_file.editor_notes.trim(),
  };
}

export const mtg_files_max_qty = 100;
export const mtg_files_maxed_qty_btn_tooltip_msg = `Only ${mtg_files_max_qty} files may be added to each meeting`;
// eslint-disable-next-line max-len
export const mtg_files_exceeded_max_qty_validation_msg = `${mtg_files_maxed_qty_btn_tooltip_msg}. Please delete excess files`;
export const mtg_file_title_max_len = 100;
export const mtg_file_summary_max_len = 100;
export const mtg_file_editor_notes_max_len = 5000;

type MtgFileValidationMsgKind =
  | 'file_missing'
  | 'file_name_invalid'
  | 'file_ext_unsupported'
  | 'file_too_big'
  | 'signature_mismatch'
  | 'title_min_length'
  | 'title_max_length'
  | 'date_invalid'
  | 'category_invalid'
  | 'summary_max_length'
  | 'editor_notes_max_length';

export function getMtgFileValidationMsg(
  file_prefix: string,
  kind: MtgFileValidationMsgKind
): string {
  switch (kind) {
    case 'file_missing':
      return `Please select a file for ${file_prefix}`;
    case 'file_name_invalid':
      return `Please select file with a valid file name for ${file_prefix}`;
    case 'file_ext_unsupported':
      return `The file type for ${file_prefix} is not supported, please select another file`;
    case 'file_too_big':
      return `The file size of ${file_prefix} is too large, please select another file`;
    case 'signature_mismatch':
      return `The file extension for ${file_prefix} does not match its contents, please select another file`;
    case 'title_min_length':
      return `Please enter a title for ${file_prefix}`;
    case 'title_max_length':
      return `Title for ${file_prefix} cannot be more than ${mtg_file_title_max_len} characters`;
    case 'date_invalid':
      return `Please enter a valid date for ${file_prefix}`;
    case 'category_invalid':
      return `Please select a category for ${file_prefix}`;
    case 'summary_max_length':
      return `Summary for ${file_prefix} cannot be more than ${mtg_file_summary_max_len} characters`;
    case 'editor_notes_max_length':
      return `Editor notes for ${file_prefix} cannot be more than ${mtg_file_editor_notes_max_len} characters`;
  }
}

// Navbar
export const admin_tools_nav_menu_title = 'Admin tools';
export const email_queue_nav_title = 'Email queue';
export const piv_data_nav_title = 'PIV certificate list';
export const session_log_title = 'Active session log';
export const auth_log_title = 'Authentication log';

// Broadcast
export const broadcast_checkbox_warning =
  'I understand that this email broadcast will be sent to all recipients specified in the form.';

export function getLoginGovLoginUrl(redirect?: string) {
  let url = '/login-gov-login';
  if (redirect) url += `?redirect=${encodeURIComponent(redirect)}`;
  return url;
}

export type TableVariant =
  | 'manage_accounts_table'
  | 'meetings_list_table'
  | 'edit_registrants_table'
  | 'view_registrants_table'
  | 'email_queue_table'
  | 'manage_pivs_table'
  | 'meeting_files_table'
  | 'session_log_table'
  | 'auth_log_table';

export const auth_method_dict: Record<AuthLogGetItem['auth_method'], string> = {
  piv: 'PIV',
  email: 'Email',
  login_gov: 'Login.gov',
};

export type FlattenedAuthLogGetItem = Omit<AuthLogGetItem, 'users'> & {
  user: AuthLogGetResUser | undefined;
};

export const auth_result_dict: Record<
  AuthLogGetItem['result'],
  {title: string; tooltip: string | null}
> = {
  SUCCESS: {
    title: 'Success',
    tooltip: null,
  },
  FAIL_USER_DISABLED: {
    title: 'Failed - Account disabled',
    tooltip: "Login was denied because the user's account is disabled.",
  },
  FAIL_USER_REMOVED: {
    title: 'Failed - Account removed',
    tooltip: "Login failed because the user's account was removed.",
  },
  FAIL_LIG_MULTIPLE_USERS_MATCH: {
    title: 'Failed - Multiple accounts match',
    tooltip:
      // eslint-disable-next-line max-len
      'Login failed because multiple user accounts were identified that could be associated with a single Login.gov account.',
  },
  FAIL_LIG_EMAIL_MISMATCH: {
    title: 'Failed - Email mismatch',
    tooltip:
      "Login failed because the user's email is no longer associated with their Login.gov account.",
  },
  FAIL_TOKEN_NOT_FOUND: {
    title: 'Failed - Email token not found',
    tooltip: 'Login failed because the email token was not recognized.',
  },
  FAIL_TOKEN_NOT_VALID: {
    title: 'Failed - Invalid email token',
    tooltip: 'Login failed because the email token is invalid.',
  },
  FAIL_TOKEN_EXPIRED: {
    title: 'Failed - Expired email token',
    tooltip: 'Login failed because the email token expired.',
  },
  FAIL_PIV_ROLE: {
    title: 'Failed - PIV permissions',
    tooltip: "Login was denied because the user's role does not allow logging in via PIV.",
  },
  FAIL_PIV_NOT_FOUND: {
    title: 'Failed - PIV not found',
    tooltip: 'Login failed because the PIV certificate is not associated with any user.',
  },
  FAIL_OTHER: {
    title: 'Failed - Unexpected error',
    tooltip: 'Login could not proceed because of an unexpected error.',
  },
};
