import {Alert} from '@trussworks/react-uswds';
import DisplayBox from '../../components/MeetingComponents/DisplayBox/DisplayBox';
import {ActiveTab, view_meeting_display_box_fields} from '../../lib/utils';
import {checkIfRemirrorStringAndGetJson} from '../../lib/remirror-utils';
import metadata_client_meeting from '../../lib/metadata-client-meeting.json';
import {Fragment, useMemo} from 'react';
import {MeetingsGetByIdData} from '../../../common/types/meetings-get-by-id-res';
import {MtgStartEndDate} from '../../components/MeetingComponents/MtgStartEndDate/MtgStartEndDate';
// eslint-disable-next-line max-len
import InPersonVirtualInformation from '../../components/MeetingComponents/InPersonVirtualInformation/InPersonVirtualInformation';
import {RegistrationEndDateInfo} from '../../types/meeting-types';
import TimeZoneBanner from '../../components/MeetingComponents/TimeZoneBanner/TimeZoneBanner';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import {AccountPrivilegesGetData} from '../../../common/types/account-privileges-get-res';
import DeleteMeetingModal from '../../components/MeetingComponents/DeleteMeetingModal/DeleteMeetingModal';
import RegisterButton from '../../components/MeetingComponents/RegisterButton/RegisterButton';
import {Link} from 'react-router-dom';
import {useQueryRegistrant} from '../../services/registrant-queries';
import RemirrorAlert from '../../components/MeetingComponents/RemirrorAlert/RemirrorAlert';
import styles from './ViewMeeting.module.scss';
import {RemirrorRendererComponentWrapper} from '../../components/RemirrorRendererComponent/RemirrorRendererComponent';
import ViewMeetingFilesTable from '../../components/MeetingComponents/ViewMeetingFilesTable/ViewMeetingFilesTable';

export interface MeetingProps {
  meeting_id: string;
  meeting_data: MeetingsGetByIdData;
  active_tab: ActiveTab;
  privileges_data: AccountPrivilegesGetData | undefined;
}

export default function Meeting({
  meeting_id,
  meeting_data,
  active_tab,
  privileges_data,
}: MeetingProps) {
  const registrant_query = useQueryRegistrant(meeting_id, {
    is_critical: false,
    is_enabled: active_tab == 'meeting',
  });
  const registrant_data = useMemo(
    () => (registrant_query.isSuccess ? registrant_query.data : undefined),
    [registrant_query.isSuccess, registrant_query.data]
  );

  const meeting_format_obj: RegistrationEndDateInfo = {
    is_in_person: meeting_data.is_in_person,
    is_virtual: meeting_data.is_virtual,
    in_person_reg_end_datetime: meeting_data.in_person_reg_end_datetime,
    virtual_reg_end_datetime: meeting_data.virtual_reg_end_datetime,
  };

  const is_registered = !!registrant_data;
  const is_logged_in = !!privileges_data;
  const is_verified = !!privileges_data?.is_verified;

  const registrant_status_message = useMemo(() => {
    if (is_registered) {
      return 'You are registered for this meeting.';
    }
    return 'You are not registered for this meeting.';
  }, [is_registered]);

  // The RemirrorRenderer will throw an error if any value type besides RemirrorJSON is passed into `json`
  const remirror_json = meeting_data.banner && checkIfRemirrorStringAndGetJson(meeting_data.banner);
  return (
    <>
      {meeting_data.banner && (
        <div className={styles.alert_wrapper}>
          {remirror_json ? (
            <RemirrorAlert title={metadata_client_meeting.banner.title} json={remirror_json} />
          ) : (
            <Alert type="info" headingLevel="h2" noIcon className="margin-bottom-3">
              {meeting_data.banner}
            </Alert>
          )}
        </div>
      )}
      <TimeZoneBanner />
      {(meeting_data.start_datetime || meeting_data.end_datetime) && (
        <div className="margin-top-05 margin-bottom-3 font-body-md">
          <MtgStartEndDate
            start_date={meeting_data.start_datetime}
            end_date={meeting_data.end_datetime}
          />
        </div>
      )}
      {meeting_data.is_in_person || meeting_data.is_virtual ? (
        <InPersonVirtualInformation meeting_info={meeting_format_obj} />
      ) : (
        <p className="font-body-md">A format for this meeting has not been selected yet.</p>
      )}

      {meeting_data.is_in_person &&
        (meeting_data.in_person_address || meeting_data.in_person_info) && (
          <DisplayBox title={metadata_client_meeting.in_person_info.title}>
            {meeting_data.in_person_address && (
              <p className="text-bold">{meeting_data.in_person_address}</p>
            )}
            {meeting_data.in_person_info && (
              <RemirrorRendererComponentWrapper
                title={metadata_client_meeting.in_person_info.title}
                initial_query_data={meeting_data.in_person_info}
              />
            )}
          </DisplayBox>
        )}
      {meeting_data.is_virtual && (meeting_data.virtual_link || meeting_data.virtual_info) && (
        <DisplayBox title={metadata_client_meeting.virtual_info.title}>
          {meeting_data.virtual_link && (
            <p>
              <span className="text-bold">Virtual Link: </span>
              <a href={meeting_data.virtual_link}>Click here</a> to access virtual meeting.
            </p>
          )}
          {meeting_data.virtual_info && (
            <RemirrorRendererComponentWrapper
              title={metadata_client_meeting.virtual_info.title}
              initial_query_data={meeting_data.virtual_info}
            />
          )}
        </DisplayBox>
      )}
      {view_meeting_display_box_fields.map((field) => {
        const field_val = meeting_data[field];
        const field_title = metadata_client_meeting[field].title;
        return (
          <Fragment key={field}>
            {!!field_val && (
              <DisplayBox title={field_title}>
                <RemirrorRendererComponentWrapper
                  title={field_title}
                  initial_query_data={field_val.toString()}
                />
              </DisplayBox>
            )}
          </Fragment>
        );
      })}
      {meeting_data.files.length > 0 && (
        <ViewMeetingFilesTable meeting_id={meeting_id} files={meeting_data.files} />
      )}
      <BottomContainer>
        <div className="grid-row">
          <div className="tablet:grid-col text-italic font-sans-xs">
            {registrant_status_message}
          </div>
          <div className="tablet:grid-col"></div>
        </div>
        <div className="display-flex flex-column mobile-lg:flex-row flex-justify margin-top-05">
          <div className="flex-grow margin-top-105">
            <RegisterButton
              meeting_id={meeting_id}
              meeting_reg_data={meeting_data}
              is_logged_in={is_logged_in}
              is_verified={is_verified}
              is_registered={is_registered}
              tooltip_placement="top"
            />
          </div>
          {privileges_data?.can_edit_mtg && (
            // Why neg is applied: https://stackoverflow.com/a/30891910
            <div className="grid-row margin-right-neg-2">
              <div className="margin-top-105 margin-right-2 flex-grow">
                <Link
                  to={`/meetings/${meeting_id}/edit`}
                  className="usa-button usa-button--internal margin-0"
                >
                  Edit meeting
                </Link>
              </div>
              <div className="margin-top-105 margin-right-2 flex-grow">
                <DeleteMeetingModal title={meeting_data.title} pk={meeting_id} />
              </div>
            </div>
          )}
        </div>
      </BottomContainer>
    </>
  );
}
