import TagAlt from '../../TagAlt/TagAlt';

interface Props {
  name: string;
  level: number;
}

export default function RoleNameTag({name, level}: Props) {
  return (
    <TagAlt
      display_value={name}
      color={level > 0 ? (level > 1 ? 'primary' : 'accent_warm') : 'secondary'}
    />
  );
}
