import {Button, Icon, ModalFooter} from '@trussworks/react-uswds';
import GenericModalLayout from '../../../layouts/GenericModalLayout/GenericModalLayout';
import Spinner from '../../LoadingComponents/Spinner/Spinner';
import style from './UploadFilesModal.module.scss';
import {MtgFileUpload} from '../../../types/meeting-types';

type Props = {
  isModalOpen: boolean;
  meeting_title: string;
  uploads: MtgFileUpload[];
  handleFailure: () => void;
};

export default function UploadFilesModal({
  isModalOpen,
  meeting_title,
  uploads,
  handleFailure,
}: Props) {
  const modal_heading = 'File upload progress';

  const is_complete = uploads.every((upload) => upload.status != 'in_progress');
  const num_error = is_complete ? uploads.filter((upload) => upload.status == 'error').length : 0;
  const is_error = num_error > 0;

  return (
    <GenericModalLayout
      isOpen={isModalOpen}
      closeModal={handleFailure}
      modal_heading={modal_heading}
      shouldCloseOnOverlayClick={false}
    >
      <div className="text-primary text-semibold font-sans-md word-break-word">{meeting_title}</div>
      <div className="usa-prose">
        <p>
          {!is_error ? (
            <>
              The meeting has been saved successfully. Please leave this window open while the{' '}
              {uploads.length > 1 ? 'files upload' : 'file uploads'}.
            </>
          ) : (
            <>
              The following file{num_error > 1 ? 's' : ''} did not upload successfully. Please
              return to the edit meeting screen and attempt to re-upload the file
              {num_error > 1 ? 's' : ''}.
            </>
          )}
        </p>
      </div>
      <ul className={`usa-list usa-list--unstyled ${style.file_list_container}`}>
        {uploads
          .filter((upload) => !is_error || upload.status == 'error')
          .map((upload) => {
            const {status, title, file_name, upload_id} = upload;
            return (
              <li key={upload_id} className="grid-row margin-top-1">
                <div className="grid-col-auto">
                  {status == 'success' && (
                    <Icon.CheckCircle className="text-middle" size={4} color="green" />
                  )}
                  {status == 'error' && <Icon.Error size={4} className="text-middle" color="red" />}
                  {status == 'in_progress' && (
                    // As the spinner rotates, the square represented by ::after pokes its corners out of the icon
                    // container. This is not an issue unless the files list container is just shy of the 40vh
                    // max-height. Then, these corners are enough to trigger overflow on the files list container
                    // (which is allowed to scroll if the number of files is long) and the container keeps flipping
                    // between scroll and no-scroll. Keep overflow-hidden here unless you've accounted for this.
                    <div className="height-4 width-4 display-flex overflow-hidden">
                      <Spinner size="file_upload" className="margin-x-auto margin-y-auto" />
                    </div>
                  )}
                </div>
                <div className="grid-col padding-left-1 word-break-word ">
                  <span className="text-middle line-height-sans-2">
                    <span className="text-semibold">{title}</span> ({file_name})
                  </span>
                </div>
              </li>
            );
          })}
      </ul>
      <ModalFooter>
        {is_error && (
          <div className="text-center padding-x-3">
            <Button
              type="button"
              onClick={handleFailure}
              className="usa-button--outline text-center width-full margin-top-2"
            >
              Take me to edit meeting screen
            </Button>
          </div>
        )}
      </ModalFooter>
    </GenericModalLayout>
  );
}
