import {Radio} from '@trussworks/react-uswds';
import {StatusesGetData} from '../../../../../../common/types/statuses-get-res';
import {capitalizeFirstLetter, removeValidationMessages} from '../../../../../lib/utils';
import {MeetingForm} from '../../../../../types/meeting-types';
import {SetValidationMessages} from '../../../../../types/form-types';
import metadata_client_meeting from '../../../../../lib/metadata-client-meeting.json';
import {useState} from 'react';
import ConfirmStatusChangeModal from '../ConfirmStatusChangeModal/ConfirmStatusChangeModal';

interface Props {
  mtg_title?: string;
  statuses_data: StatusesGetData;
  selected_status_fk: string;
  setFormData: React.Dispatch<React.SetStateAction<MeetingForm>>;
  setValidationMessages: SetValidationMessages;
  is_edit_mtg: boolean;
  is_initial_unlisted?: boolean;
}

interface Modals {
  postponed: boolean;
  cancelled: boolean;
}

export default function MeetingStatusRadio({
  mtg_title,
  statuses_data,
  selected_status_fk,
  setFormData,
  setValidationMessages,
  is_edit_mtg,
  is_initial_unlisted,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState<Modals>({
    postponed: false,
    cancelled: false,
  });

  function changeStatus(pk: string) {
    setFormData((prevForm) => ({...prevForm, status_fk: pk}));
    // Clean up validation messages when statuses w/ `is_date_required` is clicked off
    const start_datetime_validation = metadata_client_meeting.start_datetime.validation_message;
    const end_datetime_validation = metadata_client_meeting.end_datetime.validation_message;
    removeValidationMessages({
      err_messages: [start_datetime_validation, end_datetime_validation],
      setValidationMessages,
    });
  }

  return (
    <section className="grid-row margin-top-1">
      {statuses_data.map(({name, pk, is_unlisted}) => {
        return (
          <div
            key={pk}
            className="mobile-lg:margin-right-2 margin-bottom-105 width-full mobile-lg:width-auto"
          >
            <Radio
              id={name}
              name={name}
              label={capitalizeFirstLetter(name)}
              className={`${name}-status`}
              checked={selected_status_fk == pk}
              onChange={(e) => {
                if (e.target.id == 'postponed') {
                  e.target.blur();
                  setIsModalOpen({...isModalOpen, postponed: true});
                } else if (is_edit_mtg && e.target.id == 'cancelled') {
                  e.target.blur();
                  setIsModalOpen({...isModalOpen, cancelled: true});
                } else {
                  changeStatus(pk);
                }
              }}
              disabled={is_edit_mtg && !is_initial_unlisted && is_unlisted}
              tile
            />
            {name == 'postponed' && (
              <ConfirmStatusChangeModal
                isModalOpen={isModalOpen.postponed}
                pk={pk}
                closeModal={() => setIsModalOpen({...isModalOpen, postponed: false})}
                changeStatus={changeStatus}
                modal_heading={mtg_title ? `Postpone ${mtg_title}` : "You've selected POSTPONED."}
                confirm_btn_text="Select 'Postponed'"
              >
                <p>
                  This will clear the meeting&apos;s start and end dates and times. Do you want to
                  proceed?
                </p>
              </ConfirmStatusChangeModal>
            )}
            {name == 'cancelled' && is_edit_mtg && (
              <ConfirmStatusChangeModal
                isModalOpen={isModalOpen.cancelled}
                pk={pk}
                closeModal={() => setIsModalOpen({...isModalOpen, cancelled: false})}
                changeStatus={changeStatus}
                modal_heading={mtg_title ? `Cancel ${mtg_title}` : "You've selected CANCELLED."}
                confirm_btn_text="Select 'Cancelled'"
              >
                <p>
                  Are you sure you want to cancel this meeting? Any registrants will remain
                  registered in case this meeting is rescheduled in the future. Remember to send a
                  broadcast email to registrants about this change.
                </p>
                <p className="text-red">
                  All cancelled meetings display in the public meeting list, and cancelled meeting
                  details are visible to the public. If you prefer to keep the details of this
                  meeting private you may wish to{' '}
                  {is_initial_unlisted &&
                    "either leave it in DRAFT or TENTATIVE status with an editor's note, or "}
                  delete it instead of cancelling it.
                </p>
              </ConfirmStatusChangeModal>
            )}
          </div>
        );
      })}
    </section>
  );
}
