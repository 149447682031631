import {Button} from '@trussworks/react-uswds';
import {Tooltip} from 'react-tooltip';

interface Props {
  session_pk: string;
  is_current_session: boolean;
  handleClick: () => void;
}

export default function DeleteButton({session_pk, is_current_session, handleClick}: Props) {
  const is_disabled = is_current_session;
  const disabled_tooltip = is_current_session
    ? 'You cannot revoke your own session. Log out to clear this session.'
    : '';

  const openModal = () => {
    if (is_disabled) return;
    handleClick();
  };

  return (
    <>
      <Button
        type="button"
        id={`delete-session-btn-${session_pk}`}
        className="usa-button--outline usa-button--outline-secondary margin-right-0 text-no-wrap"
        aria-disabled={is_disabled}
        onClick={openModal}
      >
        Revoke
      </Button>
      {is_disabled && !!disabled_tooltip && (
        <Tooltip anchorSelect={`#delete-session-btn-${session_pk}`} place="bottom">
          {disabled_tooltip}
        </Tooltip>
      )}
    </>
  );
}
