import {Link} from 'react-router-dom';
import styles from './AdminTools.module.scss';
import {HandleSubMenu, IsSetMobileNavExpanded} from '../Navbar';
import {AccountPrivilegesGetData} from '../../../../../common/types/account-privileges-get-res';
import {Icon} from '@trussworks/react-uswds';
import {
  admin_tools_nav_menu_title,
  email_queue_nav_title,
  piv_data_nav_title,
  session_log_title,
  auth_log_title,
} from '../../../../lib/utils';

interface Props {
  name: string;
  isSubMenuOpen: boolean;
  handleSubMenu: HandleSubMenu;
  privileges_data: AccountPrivilegesGetData;
  setIsMobileNavExpanded: IsSetMobileNavExpanded;
}

export default function AdminTools({
  name,
  isSubMenuOpen,
  handleSubMenu,
  privileges_data,
  setIsMobileNavExpanded,
}: Props) {
  const should_display =
    privileges_data.can_view_email_queue ||
    privileges_data.can_view_piv_data ||
    privileges_data.can_manage_sessions;
  const handleToolClick = () => {
    setIsMobileNavExpanded(false);
    handleSubMenu();
  };

  return (
    <>
      {should_display && (
        <div className="nav__submenu_wrapper">
          <button
            className={`${styles.btn_wrapper} nav__hide_mobile ${
              isSubMenuOpen ? 'open' : ''
            } font-sans-md`}
            aria-expanded="false"
            aria-controls={name}
            onClick={handleSubMenu}
          >
            {admin_tools_nav_menu_title}
            {isSubMenuOpen ? (
              <Icon.ArrowDropUp className="text-white" />
            ) : (
              <Icon.ArrowDropDown className="text-white" />
            )}
          </button>
          <ul
            id={name}
            className={`usa-nav__submenu nav__dropdown_menu ${isSubMenuOpen ? 'open' : ''}`}
          >
            {privileges_data.can_manage_sessions && (
              <li className="usa-nav__submenu-item nav__dropdown_menu_item">
                <Link to="/session-log" onClick={handleToolClick}>
                  {session_log_title}
                </Link>
              </li>
            )}
            {privileges_data.can_manage_sessions && (
              <li className="usa-nav__submenu-item nav__dropdown_menu_item">
                <Link to="/auth-log" onClick={handleToolClick}>
                  {auth_log_title}
                </Link>
              </li>
            )}
            {privileges_data.can_view_email_queue && (
              <li className="usa-nav__submenu-item nav__dropdown_menu_item">
                <Link to="/email-queue" onClick={handleToolClick}>
                  {email_queue_nav_title}
                </Link>
              </li>
            )}
            {privileges_data.can_view_piv_data && (
              <li className="usa-nav__submenu-item nav__dropdown_menu_item">
                <Link to="/manage-pivs" onClick={handleToolClick}>
                  {piv_data_nav_title}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}
