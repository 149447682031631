import {useRef, useState} from 'react';
import {Grid} from '@trussworks/react-uswds';
import {Link} from 'react-router-dom';
import {CreateAccountForm} from '../../types/account-types';
import {useMutationCreateAccount} from '../../services/account-queries';
import FormLayout from '../../layouts/FormLayout/FormLayout';
import FormTextInput from '../../components/FormComponents/FormTextInput/FormTextInput';
import FormEmailInput from '../../components/FormComponents/FormEmailInput/FormEmailInput';
import FormPhoneInputLazy from '../../components/FormComponents/FormPhoneInput/FormPhoneInputLazy';
import RoleDropdown, {
  handleTypedDropdownChange,
} from '../../components/FormComponents/AccountFormComponents/RoleDropdown/RoleDropdown';
import FormTextArea, {
  handleTypedTextAreaChange,
} from '../../components/FormComponents/FormTextArea/FormTextArea';
import FormSubmit from '../../components/FormComponents/FormSubmit/FormSubmit';
import {acc_create_success, handleTypedInputChange, handleTypedPhoneChange} from '../../lib/utils';
import {RolesGetData} from '../../../common/types/roles-get-res';
import {HandleSubmit, ValidationMessages} from '../../types/form-types';
import {usePageTitle} from '../../lib/hooks/usePageTitle';
import BottomContainer from '../../components/BottomContainer/BottomContainer';
import FormGenericError from '../../components/FormComponents/FormGenericError/FormGenericError';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import DiscardChanges from '../../components/FormComponents/DiscardChanges/DiscardChanges';
import isEqual from 'lodash/isEqual';

interface Props {
  roles_data: RolesGetData;
}

export default function CreateAccount({roles_data}: Props) {
  usePageTitle('Create new account');
  const initial_data = {
    email: '',
    first_name: '',
    last_name: '',
    job_title: '',
    org: '',
    phone: '',
    admin_notes: '',
    role_fk: roles_data[0].pk,
  };
  const [formData, setFormData] = useState<CreateAccountForm>(initial_data);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages>([]);
  const mutation = useMutationCreateAccount();

  const [initialForm] = useState(formData);
  const isFormDirty = () => !isEqual(initialForm, formData);

  const is_submit_disabled = !!validationMessages.length || mutation.isPending;
  const is_discard_disabled = mutation.isPending;
  const submit_btn_ref = useRef<HTMLButtonElement>(null);
  const handleSubmit: HandleSubmit = (event) => {
    event.preventDefault();
    if (is_submit_disabled) {
      submit_btn_ref.current?.focus();
      return;
    }

    mutation.mutate(formData, {
      onSuccess: () => setFormData(initial_data),
    });
  };

  const handleInputChange = handleTypedInputChange(setFormData);
  const handlePhoneChange = handleTypedPhoneChange(setFormData);
  const handleDropdownChange = handleTypedDropdownChange(setFormData);
  const handleTextAreaChange = handleTypedTextAreaChange(setFormData);

  return (
    <section className="grid-container-tablet margin-bottom-10 padding-x-0">
      {mutation.isSuccess && (
        <SuccessAlert>
          <span>
            {acc_create_success(mutation.data.email)}{' '}
            <Link
              className="usa-button usa-button--unstyled"
              to={`/manage-accounts/account/${mutation.data.pk}`}
            >
              See Details
            </Link>
          </span>
          <br />
          They will receive a welcome email with a verification link and instructions on how to
          verify their account. The verification link will be valid for 24 hours.
        </SuccessAlert>
      )}
      {mutation.isError && <FormGenericError error={mutation.error} />}
      <FormLayout handleSubmit={handleSubmit}>
        <Grid className="grid-row grid-gap margin-top-neg-3">
          <div className="mobile-lg:grid-col-6">
            <FormEmailInput
              id_name="email"
              input={formData.email}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              form_type="account"
              is_required
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="first_name"
              input={formData.first_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              id_name="last_name"
              input={formData.last_name}
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.job_title}
              id_name="job_title"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
          <div className="mobile-lg:grid-col-6">
            <FormTextInput
              input={formData.org}
              id_name="org"
              handleChange={handleInputChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
        </Grid>
        <Grid className="grid-row grid-gap">
          <div className="mobile-lg:grid-col-6">
            <FormPhoneInputLazy
              phone={formData.phone}
              handleChange={handlePhoneChange}
              setValidationMessages={setValidationMessages}
            />
          </div>
          <RoleDropdown
            role_fk={formData.role_fk}
            handleChange={handleDropdownChange}
            roles={roles_data}
          />
        </Grid>
        <section className="padding-2 margin-top-205 bg-internal">
          <div className="margin-top-neg-3">
            <FormTextArea
              input={formData.admin_notes}
              id_name="admin_notes"
              handleChange={handleTextAreaChange}
              setValidationMessages={setValidationMessages}
              is_validate_length
              form_type="account"
            />
          </div>
        </section>
        <BottomContainer>
          <div className="grid-container-tablet padding-x-0 grid-row flex-justify-end">
            <FormSubmit
              validationMessages={validationMessages}
              is_disabled={is_submit_disabled}
              ref={submit_btn_ref}
            >
              Create account & send welcome email
            </FormSubmit>
          </div>
        </BottomContainer>
        <DiscardChanges
          isFormDirty={isFormDirty}
          disable_nav_blocker={is_discard_disabled}
          visibility="none"
        />
      </FormLayout>
    </section>
  );
}
