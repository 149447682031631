import {useEffect} from 'react';
import {
  SummaryBox,
  SummaryBoxContent,
  Checkbox,
  TextInput,
  Tag,
  Label,
} from '@trussworks/react-uswds';
import {useDebouncedCallback} from 'use-debounce';
import SectionDescription from '../../FormComponents/SectionDescription/SectionDescription';
import SectionTitle from '../../FormComponents/SectionTitle/SectionTitle';
import {SetCrqs, SetDeleteModal, SetInvalidCroIds} from '../CustomRegQuestions/CustomRegQuestions';
import {SetValidationMessages} from '../../../types/form-types';
import {
  addOrRemoveValidationMessage,
  debounce_delay_ms,
  getUniqueCrqId,
  cr_option_max_length,
  removeValidationMessage,
} from '../../../lib/utils';
import styles from './CustomRegOption.module.scss';
import {MtgFormCROption, MtgFormCRQuestionRadio} from '../../../types/meeting-types';
import InlineAlert from '../../FormComponents/InlineAlert/InlineAlert';

interface Props {
  setCrqs: SetCrqs;
  crq: MtgFormCRQuestionRadio;
  crq_idx: number;
  cro: MtgFormCROption;
  cro_idx: number;
  num_cros: number;
  setValidationMessages: SetValidationMessages;
  setDeleteCRModal: SetDeleteModal;
  setInvalidCroIds: SetInvalidCroIds;
  is_edit_mtg?: boolean;
}

export default function CustomRegOption({
  setCrqs,
  crq,
  crq_idx,
  cro,
  cro_idx,
  num_cros,
  setValidationMessages,
  setDeleteCRModal,
  setInvalidCroIds,
  is_edit_mtg,
}: Props) {
  const disabled_checkbox_id = `${getUniqueCrqId(crq, cro)}-disabled`;
  const delete_id = `${getUniqueCrqId(crq, cro)}-delete`;
  const disabled_note_id = `${disabled_checkbox_id}-note`;

  const is_option_disabled = cro.is_disabled;
  const is_disabled_note_too_long = !!(
    cro.disabled_note && cro.disabled_note.length > cr_option_max_length
  );
  const is_option_removed = is_edit_mtg && cro.is_removed;
  const is_queried_cro = !!(is_edit_mtg && cro.pk);

  const qst_prefix = `Question ${crq_idx + 1}`;
  const disabled_note_validation_msg = `Disabled note for ${qst_prefix}, option ${
    cro_idx + 1
  } cannot be more than ${cr_option_max_length} characters`;

  useEffect(() => {
    addOrRemoveValidationMessage({
      is_condition_met: is_disabled_note_too_long,
      err_message: disabled_note_validation_msg,
      setValidationMessages,
    });

    setInvalidCroIds((ids) => {
      const new_ids = ids.filter((id) => id != cro.local_id);
      if (is_disabled_note_too_long) new_ids.push(cro.local_id);
      return new_ids;
    });
  }, [
    cro.local_id,
    num_cros,
    disabled_note_validation_msg,
    is_disabled_note_too_long,
    setValidationMessages,
    setInvalidCroIds,
  ]);

  /**
   * @param partial_cro Include only the properties that should be updated. `partial_cro` can be set to `null` to
   * permanently remove this CRO.
   */
  function updateCro(partial_cro: Partial<MtgFormCROption> | null) {
    setCrqs((crqs) => {
      // Create new shallow clones of each property so that it is possible to react to any of crqs, crq, cros, or cro.
      const new_crqs = [...crqs];
      const new_crq = {...new_crqs[crq_idx]};
      if (!new_crq.cr_options) throw new Error(`CROs in CRQ[${crq_idx}] are missing`);
      const new_cros = [...new_crq.cr_options];
      if (!new_cros[cro_idx]) throw new Error(`CRO[${cro_idx}] in CRQ[${crq_idx}] is missing`);
      if (!partial_cro) {
        new_cros.splice(cro_idx, 1);
      } else {
        new_cros[cro_idx] = {...new_cros[cro_idx], ...partial_cro};
      }
      new_crq.cr_options = new_cros;
      new_crqs[crq_idx] = new_crq;
      return new_crqs;
    });
  }

  function handleDeleteOption() {
    updateCro(null);
    // if removeMessageFromValidations is run before setCrqs, handleDeleteOption is run twice
    removeValidationMessage({
      err_message: 'Option [0-9]+',
      setValidationMessages,
      use_regex: true,
    });
  }

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    const {name, checked} = event.currentTarget;
    // Should not allow toggling "Disable Option" if option is marked to be deleted
    if (name == disabled_checkbox_id && is_option_removed) return;

    if (name == disabled_checkbox_id) {
      updateCro({is_disabled: checked, disabled_note: checked ? '' : null});
    } else if (name == delete_id) {
      if (is_option_removed) {
        updateCro({is_removed: checked});
      } else {
        setDeleteCRModal({
          is_open: true,
          crq_idx: crq_idx,
          cro_idx: cro_idx,
        });
      }
    }
  }

  const handleDisabledNoteChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value: disabled_note} = event.target;
      updateCro({disabled_note: cro.is_disabled ? disabled_note : null});
    },
    debounce_delay_ms
  );

  return (
    <SummaryBox
      className={`margin-bottom-1 margin-top-0 padding-1 ${
        is_option_disabled || is_option_removed
          ? 'bg-base-lightest border-base-light'
          : 'bg-accent-warm-even-lighter border-accent-warm-light'
      }`}
    >
      <SummaryBoxContent className={styles.summary_content}>
        {is_option_removed && (
          <div className="margin-bottom-05">
            <InlineAlert>
              This option will be deleted when the &quot;Save changes&quot; button is clicked and
              the meeting is saved. Un-check the &quot;Delete option&quot; button to retain this
              option.
            </InlineAlert>
          </div>
        )}
        <section className="display-block flex-justify tablet:display-flex">
          <p
            className={`margin-y-0 ${
              is_option_disabled || is_option_removed ? 'text-base-dark' : ''
            }`}
          >
            <span>Option {cro_idx + 1}:</span>{' '}
            <span className="text-bold word-break-word">
              {is_option_disabled && cro.disabled_note && `(${cro.disabled_note}) `}
              {cro.title}
            </span>
            {/* Only differentiate new vs queried options for queried questions */}
            {!is_queried_cro && crq.pk && (
              <Tag className="bg-violet margin-left-1 text-bold">New</Tag>
            )}
          </p>
          {/* eslint-disable-next-line max-len */}
          <div className="display-flex flex-align-start flex-wrap flex-shrink-0 margin-left-0 tablet:margin-left-2 margin-top-1 tablet:margin-top-0">
            {is_queried_cro ? (
              <Checkbox
                id={delete_id}
                name={delete_id}
                label="Delete option"
                checked={cro.is_removed}
                // eslint-disable-next-line max-len
                className="display-block usa-checkbox--secondary usa-checkbox--secondary-unchecked radius-md width-full mobile-lg:width-auto"
                onChange={handleCheckbox}
                tile
              />
            ) : (
              <button
                type="button"
                className={`usa-button usa-button--outline usa-button--outline-secondary ${styles.btn}`}
                onClick={handleDeleteOption}
              >
                Delete option
              </button>
            )}
            <Checkbox
              id={disabled_checkbox_id}
              name={disabled_checkbox_id}
              label="Disable option"
              checked={is_option_disabled}
              // eslint-disable-next-line max-len
              className="display-block width-full mobile-lg:width-auto margin-left-0 mobile-lg:margin-left-1 margin-top-1 mobile-lg:margin-top-0"
              onChange={handleCheckbox}
              disabled={is_option_removed}
              tile
            />
          </div>
        </section>
        {is_option_disabled && !is_option_removed && (
          <>
            <div className="margin-top-05">
              <InlineAlert>
                Registrants who have not yet selected this option will be prevented from doing so.
                However this selection will remain in place for any registrant who has already
                selected this option.
              </InlineAlert>
            </div>
            {/* eslint-disable-next-line max-len */}
            <section className="margin-top-1 padding-x-1 padding-bottom-1 padding-top-05 border-2px border-primary radius-md">
              <Label htmlFor={disabled_note_id} className="margin-0">
                <SectionTitle>Disabled note</SectionTitle>
              </Label>
              <SectionDescription>
                This optional note will appear next to the disabled radio button option on the
                registration form to explain why this option has been disabled and can no longer be
                selected.
              </SectionDescription>
              <TextInput
                id={disabled_note_id}
                name={disabled_note_id}
                type="text"
                defaultValue={cro.disabled_note ?? ''}
                onChange={handleDisabledNoteChange}
                validationStatus={is_disabled_note_too_long ? 'error' : undefined}
              />
            </section>
          </>
        )}
      </SummaryBoxContent>
    </SummaryBox>
  );
}
