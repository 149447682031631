import {usePrivilegesContext} from '../../lib/PrivilegesContext';
import PermissionsError from '../../lib/permissions-error';

interface Props {
  children: React.ReactElement;
  can_view_users?: true;
  can_edit_users?: true;
  can_create_users?: true;
  can_create_mtg?: true;
  can_edit_mtg?: true;
  can_view_registrants?: true;
  can_view_piv_data?: true;
  can_view_email_queue?: true;
  can_manage_sessions?: true;
}

export default function ProtectedRoute({
  children,
  can_view_users,
  can_edit_users,
  can_create_users,
  can_create_mtg,
  can_edit_mtg,
  can_view_registrants,
  can_view_piv_data,
  can_view_email_queue,
  can_manage_sessions,
}: Props) {
  const data = usePrivilegesContext();

  // https://github.com/CSNW/primis-mtg2/pull/190#discussion_r1199500620
  if (
    (can_view_users && !data.can_view_users) ||
    (can_edit_users && !data.can_edit_users) ||
    (can_create_users && !data.can_create_users) ||
    (can_create_mtg && !data.can_create_mtg) ||
    (can_edit_mtg && !data.can_edit_mtg) ||
    (can_view_registrants && !data.can_view_registrants) ||
    (can_view_piv_data && !data.can_view_piv_data) ||
    (can_view_email_queue && !data.can_view_email_queue) ||
    (can_manage_sessions && !data.can_manage_sessions)
  )
    throw new PermissionsError();

  return children;
}
